import { registerQuery } from '@adornis/baseql/metadata/register.js';
import { getCollection } from '@adornis/baseql/server/collections.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { LASUser } from '@adornis/digitale-helden-shared/db/las-user.js';

export const getUserByZohoID = registerQuery({
  type: () => LASUser,
  operationName: 'getUserByZohoID',
  resolve: (zohoId: string) => {
    return async (gqlFIelds: BaseQLSelectionSet<LASUser>) => {
      const collection = await getCollection<LASUser>(LASUser._class);
      const result = await collection.findOne<LASUser>({ zohoID: zohoId });
      return result;
    };
  },
  params: [{ name: 'zohoId', type: () => String }],
});
