import { XLanguagePicker } from '@adornis/internationalization/client/language-picker/x-language-picker.js';
import { customElement } from 'lit/decorators.js';

/**
 * @element d-language-picker
 */
@customElement('d-language-picker')
export class DubniumLanguagePicker extends XLanguagePicker {
  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          color: this.colors.white,
          borderRadius: this.sizes.borderRadiusSecondary,
          textAlign: 'center',
        },
      },
    ];
  }
}
