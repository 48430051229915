import { injectAttributesOf } from '@adornis/chemistry/directives/inject-attributes.js';
import { inputToken } from '@adornis/chemistry/elements/theming/input-token.js';
import { XPlainInput } from '@adornis/forms/x-plain-input.js';
import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import { ref } from 'lit/directives/ref.js';
import './d-formfield-wrapper.js';

/**
 * @element d-input
 */
@customElement('d-input')
// @ts-expect-error private
export class DubniumInput extends XPlainInput {
  // need to extend XPlainInput because we need different formfield-wrapper behavior

  get inputToken() {
    return inputToken(this);
  }

  @property({ attribute: true }) icon = '';
  @property({ type: Boolean, attribute: true }) clearable = false;
  @property({ attribute: true }) postfix = '';
  @property({ type: Boolean, attribute: true }) bold = false;
  @property({ type: Boolean, reflect: true }) 'no-password-toggle' = false;
  @property() 'placeholder-mode' = this.defaults.placeholderMode;

  @state() isPasswordInput = false;

  protected get _showLabel() {
    return (
      (this.value.value !== undefined && this.value.value !== null && this.value.value !== '') ||
      !!this.error ||
      // if type=number and someone typed "0." we want to show the label inspite of internal logic setting the value null
      this._renderRaw ||
      ['time', 'date', 'datetime-local', 'week', 'month'].includes(this.type)
    );
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          height: 'unset',
          fontSize: this.baseFontSize,
          cursor: 'text',
        },
      },
      {
        ':host': {
          '--placeholder-color': '#807f80',
        },
        ':host([type=text])': {
          'd-flex > d-flex': {
            paddingTop: '1.1rem',
            paddingBottom: '1.1rem',
            paddingLeft: '.625rem',
            paddingRight: '.625rem',
            border: this.focused ? '1px solid rgba(128,127,128,.6)' : '1px solid rgba(128,127,128,.3)',
            boxShadow: this.focused ? '0 0 4px rgb(128 127 128 / 60%)' : 'inset 0 0 3px rgb(128 127 128 / 30%)',
            borderRadius: this.sizes.borderRadiusSecondary,
            color: this.focused ? '#1d1d1b' : '#807f80',
            height: 'unset',
            transition: 'box-shadow .5s,border-color .25s ease-in-out,-webkit-box-shadow .5s',
            backgroundColor: this.focused ? this.colors.white : this.colors.tone.whiteInput,
          },
        },
      },
    ];
  }

  override render() {
    return html`
      <d-formfield-wrapper
        one-line
        ?show-label=${this._showLabel}
        ${injectAttributesOf(this, ['icon', 'error'])}
        error=${ifDefined(this.error)}
        icon=${this.icon ||
        (this.type === 'password' && !this['no-password-toggle'] ? 'eye-slash' : this.isPasswordInput ? 'eye' : '')}
        @cleared=${() => {
          this.value.next('');
          this.dispatchEvent(new CustomEvent('value-picked', { detail: { value: this.value.value } }));
          this.dispatchEvent(new CustomEvent('cleared'));
        }}
        @icon-click=${() => {
          if (!this.icon && (this.type === 'password' || this.isPasswordInput)) {
            if (this.type === 'text' && this.isPasswordInput) this.type = 'password';
            else if (this.type === 'password') {
              this.isPasswordInput = true;
              this.type = 'text';
            }
          }
          this.dispatchEvent(new CustomEvent('icon-click'));
        }}
      >
        <!-- <d-text flex ?bold=${this.bold}> ${super.render()} </d-text>  -->
        ${this.input()}
      </d-formfield-wrapper>
    `;
  }

  private override input() {
    return html`
      <input
        placeholder=""
        @focus=${() => (this.focused = true)}
        @input=${(e: Event) => {
          // @ts-expect-error private
          this.processNewValue();
        }}
        @search=${(e: Event) => {
          // @ts-expect-error private
          this.processNewValue();
        }}
        @keydown=${(e: KeyboardEvent) => {
          if (this.type === 'number') {
            // @ts-expect-error private
            const step = this._getNumericAttributeSafe('step') ?? 1;
            if (e.key.length === 1 && !/[0-9.,\-]/.test(e.key)) e.preventDefault();
            if (e.key === 'ArrowUp') {
              e.preventDefault();
              // @ts-expect-error private
              this.processNewValue(+(this.value.value ?? 0) + step + '', 'arrowKey');
            }
            if (e.key === 'ArrowDown') {
              e.preventDefault();
              // @ts-expect-error private
              this.processNewValue(+(this.value.value ?? 0) - step + '', 'arrowKey');
            }
          }
        }}
        @keyup=${(e: KeyboardEvent) => {
          if (e.key === 'Enter')
            return this.dispatchEvent(
              new CustomEvent('submit', {
                // @ts-expect-error private
                detail: { value: this.processNewValue() },
                composed: true,
                bubbles: true,
              }),
            );
          if (e.key === 'Escape') return this.dispatchEvent(new CustomEvent('escape'));
        }}
        @blur=${(e: Event) => {
          this.focused = false;
          // @ts-expect-error private
          this.processNewValue(undefined, 'blur');
          this.dispatchEvent(new CustomEvent('value-picked', { detail: { value: this.value.value } }));
        }}
        @click=${(e: Event) => e.stopPropagation()}
        .value=${live(
          // @ts-expect-error private
          this._displayValue(),
        )}
        ?disabled=${this.disabled}
        type=${this.type === 'number' ? 'text' : this.type}
        inputmode=${ifDefined(this.type === 'number' ? 'decimal' : undefined)}
        ${injectAttributesOf(this, [
          'type',
          'disabled',
          // @ts-expect-error type overlap
          ...(!this.error || this['placeholder-mode'] === 'static-floating' ? ['placeholder'] : []),
        ])}
        ${ref(input => (this._input = input as HTMLInputElement | undefined))}
      />
    `;
  }
}
