import { registerQuery } from '@adornis/baseql/metadata/register.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { LEAD_BIMAP, LEAD_ZOHO_FIELDS, Lead } from '@adornis/digitale-helden-shared/db/Lead.js';
import { makeZohoCOQLRequest } from '@adornis/digitale-helden-shared/server/zoho/api.js';

export const getLeadEmailStatusByEmail = registerQuery({
  type: () => String,
  operationName: 'getLeadEmailStatusByEmail',
  resolve: (email: string) => {
    return async () => {
      const result = await makeZohoCOQLRequest({
        filter: `(${LEAD_ZOHO_FIELDS.EMAIL} = '${email}')`,
        gqlFields: { id: 1, email: 1, emailStatus: 1 } as BaseQLSelectionSet<Lead>,
        moduleName: Lead.ZOHO_MODULE,
        moduleBiMap: LEAD_BIMAP,
      });

      if (!result?.data?.[0]) return null;
      const resultData = result.data[0];
      return resultData[LEAD_ZOHO_FIELDS.EMAIL_STATUS];
    };
  },
  params: [{ name: 'email', type: () => String }],
});
