import { A } from '@adornis/base/env-info.js';
import type { Maybe } from '@adornis/base/utilTypes.js';
import { WindowSizeController } from '@adornis/chemistry/controllers/window-size-controller.js';
import { css } from '@adornis/chemistry/directives/css.js';
import '@adornis/chemistry/elements/components/x-link.js';
import BackgroundBottomLeft from '@adornis/digitale-helden-shared/client/assets/dhg-hexa-green-bottom-left-card.svg';
import { DubniumCard } from '@adornis/digitale-helden-shared/client/theme/d-card.js';
import '@adornis/file-utils/client/x-image.js';
import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { isMobile } from '../globals.js';
import '../theme/components/las-icon-button.js';

/**
 * @element las-card
 */
@customElement('las-card')
export class LASCard extends DubniumCard {
  @property({ type: Boolean, attribute: 'bottom-left' }) bottomLeft?: boolean;
  @property({ attribute: 'top-right' }) topRight?: 'neutral' | 'accent' | 'accent_transparent';
  @property() icon: Maybe<string>;
  @property() href: Maybe<string>;
  @property() image: Maybe<string>;
  @property({ type: Boolean }) small?: boolean;
  @property({ reflect: true, attribute: 'small-height' }) smallHeight = '100px';
  @property({ reflect: true, attribute: 'margin-right', type: Boolean }) hasMarginRight = false;
  @property() mode: Maybe<string>;

  action?: (e: MouseEvent) => any;

  private get _backgroundDefinitions() {
    return [
      ...(this.bottomLeft
        ? [
            {
              backgroundImage: `url(${A.absoluteUrl(BackgroundBottomLeft)})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '25px',
              backgroundPosition: 'bottom left',
            },
          ]
        : []),
    ];
  }

  private get _backgrounds() {
    return this._backgroundDefinitions.map(def => def.backgroundImage).join(', ');
  }

  private get _backgroundSizes() {
    return this._backgroundDefinitions.map(def => def.backgroundSize).join(', ');
  }

  private get _backgroundPositions() {
    return this._backgroundDefinitions.map(def => def.backgroundPosition).join(', ');
  }

  private __topCorner() {
    if (!this.topRight) return nothing;

    let color = () => {
      switch (this.topRight) {
        case 'accent':
          return this.colors.accent;
        case 'accent_transparent':
          return this.colors.accent.shade(100);
        case 'neutral':
          return '#d8d8d6';
        default:
          return this.colors.accent;
      }
    };

    return html`
      <d-flex
        horizontal
        space="md"
        ${css({
          position: 'absolute',
          top: '24px',
          right: '24px',
        })}
      >
        <slot name="buttons"></slot>
        <las-icon-button
          @click=${e => {
            if (!this.action) return;
            this.action(e);
          }}
          ${css({ cursor: this.action ? 'pointer' : '' })}
          icon=${this.icon ?? 'plus'}
          background=${color()}
          color="white"
          medium
        >
        </las-icon-button>
      </d-flex>
    `;
  }

  private _size = new WindowSizeController(this);
  override render() {
    const padding = () => {
      if (this.mode === 'info') return 0;
      if (isMobile(this._size)) return '16px';
      return this.spacing.lg;
    };
    const content = html`
      ${this.__topCorner()}
      <d-flex horizontal w100 ${css({ marginRight: this.hasMarginRight ? '80px' : '' })}>
        ${this.small && this.image !== undefined && this.image !== null
          ? html`
              <x-image
                disabled
                .value=${this.image}
                is-url
                mode="cover"
                ${css({ height: this.smallHeight, aspectRatio: '1 / 1' })}
              ></x-image>
            `
          : nothing}
        <d-flex
          flex
          class="card"
          ${css({
            boxSizing: 'border-box',
            maxWidth: '100%',
            padding: padding(),
            gap: '1rem',
            ...(this.small && this.topRight ? { paddingRight: this.spacing.xxl } : {}),
          })}
        >
          <slot></slot>
        </d-flex>
      </d-flex>
    `;
    return html`
      ${this.href
        ? html` <x-link href=${this.href} ${css({ height: '100%', width: '100%' })}> ${content} </x-link> `
        : content}
    `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          display: 'flex',
          backgroundColor: this.colors.white,
          position: 'relative',
          transition: 'background-color .2s',
          padding: '0',
          ...(this.small ? { height: '100px' } : {}),
          overflow: 'hidden',

          backgroundImage: this._backgrounds,
          backgroundRepeat: 'no-repeat',
          backgroundSize: this._backgroundSizes,
          backgroundPosition: this._backgroundPositions,
        },
        ':host([w100])': {
          width: '100%',
        },
      },
      ...(this.href
        ? [
            {
              ':host(:hover)': {
                cursor: 'pointer',
                boxShadow: `2px 2px 11px 0 ${this.colors.tone.secondaryText}`,
              },
            },
          ]
        : []),
      {
        ':host([mode=info])': {
          background: this.colors.tone.infoBackground,
          color: this.colors.tone.infoText,
          padding: `${this.spacing.md} ${this.spacing.lg}`,
        },
      },
    ];
  }
}
