import { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element.js';
import { css } from '@adornis/chemistry/directives/css.js';
import '@adornis/digitale-helden-shared/client/theme/d-icon.js';
import '@adornis/digitale-helden-shared/client/theme/fonts';
import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';

/**
 * @element las-icon-button
 *
 * @property size - this determines the size of the icon
 * @property neutral-color - set this to change the icon color when not hovered
 * @propery color - set this to change the icon color when hovered
 */
@customElement('las-icon-button')
export class LASIconButton extends ChemistryLitElement {
  override styles() {
    return [
      {
        ':host([background]) d-icon': { borderRadius: this.sizes.borderRadius, padding: '10px' },
        ':host([background="alabster"]) d-icon': { background: this.colors.tone.neutralAlabster },
        ':host([background="accent"]) d-icon': { background: this.colors.accent },
        ':host([background="emergency"]) d-icon': { background: this.colors.tone.emergency },
        ':host([background="secondary"]) d-icon': { background: this.colors.tone.secondaryText },
        ':host([background="lightblack"]) d-icon': { background: this.colors.tone.lightblack },
        ':host([background="darkblack"]) d-icon': { background: this.colors.tone.darkblack },
        ':host([background="white"]) d-icon': { background: '#fff' },
        ':host([background="black"]) d-icon': { background: this.colors.tone.black },
        ':host([color="secondary"]) d-icon': { color: this.colors.tone.secondaryText },
        ':host([color="white"]) d-icon': { color: '#fff' },
        ':host([color="black"]) d-icon': { color: this.colors.tone.black },

        ':host([hover]) d-icon:hover': {
          cursor: 'pointer',
          userSelect: 'none',
          color: `${
            (this.attributes.getNamedItem('hover')?.value
              ? this.colors.tone[this.attributes.getNamedItem('hover')?.value ?? '']
              : '') as string
          } !important`,
        },
      },
    ];
  }

  @property({ attribute: 'icon', type: String }) icon?: string;
  @property({ attribute: 'solid', type: Boolean }) solid = false;
  @property({ attribute: 'text', type: String }) text = '';

  override render() {
    const backgroundAttribute = this.getAttribute('background') ?? '';
    const colorAttribute = this.getAttribute('color') ?? '';
    return html`
      <d-flex horizontal space="sm" crossaxis-center>
        ${this.icon
          ? html`
              <d-icon
                ?solid=${this.solid}
                ?huge=${this.hasAttribute('huge')}
                ?medium=${this.hasAttribute('medium')}
                ${css({
                  background: backgroundAttribute.startsWith('#') ? backgroundAttribute : '',
                  color: colorAttribute.startsWith('#') ? colorAttribute : '',
                })}
              >
                ${this.icon}
              </d-icon>
            `
          : nothing}
        ${this.text ? html` <d-text> ${this.text} </d-text> ` : nothing}
      </d-flex>
    `;
  }
}
