import type { Maybe } from '@adornis/base/utilTypes.js';
import { Int } from '@adornis/baseql/baseqlTypes';
import { Entity, Field } from '@adornis/baseql/decorators.js';
import { BiMap } from '@adornis/digitale-helden-shared/db/zoho/BiMap.js';
import { ZohoEntity } from '@adornis/digitale-helden-shared/db/zoho/zoho-entity.js';
import { ZohoModule } from '@adornis/digitale-helden-shared/db/zoho/zoho.js';
import { genericSerializeZoho } from '@adornis/digitale-helden-shared/server/zoho/interface-zoho-adornis';

export enum CONTACT_PRODUCT_RELATION_ZOHO_FIELDS {
  ID = 'id',
  CONTACT = 'Kontakt',
  PRODUCT = 'Produkt',
  NOTATIONS = 'Bezeichnungen',
  SORT_PRIO = 'Sort_Prio',
}

export const CONTACT_PRODUCT_RELATION_BIMAP = new BiMap<string, CONTACT_PRODUCT_RELATION_ZOHO_FIELDS>([
  ['id', CONTACT_PRODUCT_RELATION_ZOHO_FIELDS.ID],
  ['productID', CONTACT_PRODUCT_RELATION_ZOHO_FIELDS.PRODUCT],
  ['contactID', CONTACT_PRODUCT_RELATION_ZOHO_FIELDS.CONTACT],
  ['notations', CONTACT_PRODUCT_RELATION_ZOHO_FIELDS.NOTATIONS],
  ['sortPrio', CONTACT_PRODUCT_RELATION_ZOHO_FIELDS.SORT_PRIO],
]);

export const CONTACT_PRODUCT_RELATION_FOREIGN_KEYS = [
  CONTACT_PRODUCT_RELATION_ZOHO_FIELDS.CONTACT,
  CONTACT_PRODUCT_RELATION_ZOHO_FIELDS.PRODUCT,
];

/**
 * @entity ContactProductRelation
 */
@Entity()
export class ContactProductRelation extends ZohoEntity {
  static override _class = 'ContactProductRelation';
  static override ZOHO_MODULE = ZohoModule.CONTACT_PRODUCT_RELATIONS;
  static override ZOHO_FIELDS = Array.from(CONTACT_PRODUCT_RELATION_BIMAP.values).join(',');

  static override resolveID(entity: any): string {
    return entity.id;
  }

  @Field(type => String) id!: string;
  @Field(type => String) productID!: string;
  @Field(type => String) contactID!: string;
  @Field(type => [String]) notations: Maybe<string[]>;
  @Field(type => Int) sortPrio: Maybe<number>;

  override serializeZoho = (isNew: boolean = false) => {
    const serialized = genericSerializeZoho({
      bimap: CONTACT_PRODUCT_RELATION_BIMAP,
      foreignKeys: CONTACT_PRODUCT_RELATION_FOREIGN_KEYS,
      instance: this,
      typeDefs: undefined,
    });
    return serialized;
  };

  static override deserializeZoho = (rawData: any) => {
    const fields = {};
    const keys = Array.from(CONTACT_PRODUCT_RELATION_BIMAP.reverseKeys);
    keys.forEach(key => {
      const keyLAS = CONTACT_PRODUCT_RELATION_BIMAP.reverseGet(key);
      if (!keyLAS) return;
      if (CONTACT_PRODUCT_RELATION_FOREIGN_KEYS.includes(key)) {
        fields[keyLAS] = rawData[key]?.id ?? null;
      } else {
        fields[keyLAS] = rawData[key] ?? null;
      }
    });
    return new ContactProductRelation({
      ...fields,
    });
  };
}
