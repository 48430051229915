import type { Contact } from '@adornis/digitale-helden-shared/db/Contact.js';
import { emailTemplate } from '@adornis/digitale-helden-shared/mailing/e-mail-templates.js';
import { html } from 'lit';

export const singleOptInTemplate = (options: { contact: Contact; accessLink: string; createAccount: boolean }) =>
  emailTemplate(
    {
      content: html`
        <p style="line-height: 18px; padding-top: 10px;">
          Hallo <strong>${options.contact.firstName}&nbsp;${options.contact.lastName}</strong>,<br />
          das hat geklappt!<br />
        </p>
        ${options.createAccount
          ? html`
              <p style="line-height: 18px; padding-top: 10px;">
                <strong>Herzlich willkommen</strong> im Campus der Digitalen Helden. Um deinen Account
                <b>zu aktivieren</b> und dein Passwort festzulegen,
                <a href="${options.accessLink}" target="_blank"><b style="color: #afca0b;">klicke hier</b></a> oder rufe
                den folgenden Button-Link auf.
              </p>
            `
          : html`
              <p style="line-height: 18px; padding-top: 10px;">
                im Rahmen der Anmeldung zu einem der Digitale Helden Online-Kurse wurden Ihre Daten verarbeitet, um dies
                zu bestätigen und um Ihnen Informationen oder Rechnungen sollten Sie Ansprechpartner:in einer
                Finanzierungs-Institution sein senden zu dürfen, bestätigen Sie dies bitte über den unten aufgeführten
                Link.
              </p>
            `}

        <p style="line-height: 18px; padding-top: 8px;">
          <span class="button"
            ><a href="${options.accessLink}" target="_blank" style="color:#FFFFFF;">Account aktivieren</a></span
          >
        </p>
        <p style="line-height: 18px; padding-top: 10px;">
          Wir wünschen dir viel Spaß!
          <br />
          Herzliche Grüße<br />
          Ihr Team Digitale Helden
        </p>
      `,
    },
    {
      title: html` <p style="line-height: 18px; padding-top: 10px;">Technischer Hinweis</p> `,
      content: html`
        <p style="line-height: 18px; padding-top: 10px;">
          Solltest du Probleme beim Aufrufen des Buttons oder des Links haben, kannst du diesen auch hier kopieren und
          manuell in deinen Browser einfügen:
        </p>
        <div style="padding: 10px; margin-top: 10px; border-radius: 5px; background-color: #f9f9f9;">
          <p style="line-height: 18px">${options.accessLink}</p>
        </div>
      `,
    },
  );
