import '@adornis/chemistry/elements/components/x-icon.js';
import { XTinyMCE } from '@adornis/writer/x-tinymce.js';
import { customElement, property } from 'lit/decorators.js';
import type { EditorOptions } from 'tinymce';

/**
 * @element d-chip
 */
@customElement('d-tinymce')
export class DubniumTinymce extends XTinyMCE {
  @property({ attribute: false }) override editorOptions?: Partial<EditorOptions> = {
    toolbar:
      'undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify forecolor link customheadingbutton customheading codesample',
    formats: {
      d_h4: { inline: 'h1', classes: 'd-h1' }, // Du kannst 'h1' durch den gewünschten Überschriften-Tag ersetzen
    },
    setup: editor => {
      // only fire when leaving the text field
      // when using "change" cursor jumps to start of textfled on enter
      editor.on('blur', () => {
        this.value.next(editor.getContent());
        this.dispatchEvent(new CustomEvent('value-picked', { detail: { value: this.value.value } }));
      });
    },
  };
}
