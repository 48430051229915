import type { Styles } from '@adornis/ass/style.js';
import { logger } from '@adornis/base/logging.js';
import type { Maybe } from '@adornis/base/utilTypes.js';
import { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element.js';
import { css } from '@adornis/chemistry/directives/css.js';
import '@adornis/chemistry/elements/components/x-flex.js';
import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { Product } from '../../db/product/Product.js';
import { ProductType, productTypeIcon } from '../../db/product/enums.js';
import './d-chip.js';
import './d-icon.js';

/**
 * @element d-product-type-chip
 */
@customElement('d-product-type-chip')
export class DProductTypeChip extends ChemistryLitElement {
  @property({ attribute: false }) product: Maybe<Product>;

  override render() {
    if (!this.product) {
      logger.error('Es wurde kein Produkt angegeben. (product-type-chip)');
      return nothing;
    }

    return html`
      <d-chip ${css(this._getStyle(this.product.type))}>
        <d-flex horizontal space="sm" center crossaxis-center>
          <d-icon> ${productTypeIcon(this.product.type)} </d-icon>
          <d-text> ${this.product.type} </d-text>
        </d-flex>
      </d-chip>
    `;
  }

  protected _getStyle(productType: ProductType): Styles {
    switch (productType) {
      case ProductType.ONLINE_COURSE:
        return {
          color: this.colors.tone.primaryShade110,
          background: this.colors.tone.primaryShade30,
        };
      default:
        return {};
    }
  }

  override styles(): Styles[] {
    return [
      ...super.styles(),
      {
        'd-chip': {
          height: '24px',
          padding: `0 ${this.spacing.xs}`,
          userSelect: 'none',
        },
        'd-text': {
          fontSize: '14px',
        },
        'd-icon': {
          fontSize: '14px',
        },
      },
    ];
  }
}
