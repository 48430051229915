import { registerQuery } from '@adornis/baseql/metadata/register.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { Product } from '@adornis/digitale-helden-shared/db/product/Product.js';
import { getAllProducts } from './getAllProducts.js';

//* Resolver
const getProductsByIDsResolver = (ids: string[], onlyActive?: boolean) => {
  return async (gqlFields: BaseQLSelectionSet<Product>) => {
    if (!ids || ids.length === 0) return [];
    const allProducts = await getAllProducts()(Product.allFields);
    const filteredProducts = allProducts.filter(p => {
      if (onlyActive && !p.isActive()) return false;
      return ids.includes(p.id);
    });
    return filteredProducts;
  };
};

//* Query
export const getProductsByIDs = registerQuery({
  type: () => [Product],
  operationName: 'getProductsByIDs',
  resolve: getProductsByIDsResolver,
  params: [
    { type: () => [String], name: 'ids' },
    { type: () => Boolean, name: 'onlyActive' },
  ],
});
