import { registerQuery } from '@adornis/baseql/metadata/register.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { PRODUCT_ZOHO_FIELDS, Product } from '@adornis/digitale-helden-shared/db/product/Product.js';
import { ProductType } from '@adornis/digitale-helden-shared/db/product/enums.js';
import { makeZohoAPIRequest } from '@adornis/digitale-helden-shared/server/zoho/api.js';
import { ZohoModule } from 'db/zoho/zoho';
import { activeProductCriteria } from '../helpers/activeProductCriteria.js';

//* Resolver
const getAllFreeOnlineCoursesResolver = () => {
  return async (gqlFields: BaseQLSelectionSet<Product>) => {
    const endpoint = `${Product.ZOHO_MODULE}/search`;
    const isOnlineCourseCheck = `(${PRODUCT_ZOHO_FIELDS.CATEGORY}:equals:${ProductType.ONLINE_COURSE})`;
    const isFreeCheck = `((${PRODUCT_ZOHO_FIELDS.PRICE}:equals:0)OR(${PRODUCT_ZOHO_FIELDS.PRICE}:equals:null))`;
    const freeCourseCheck = `(${isOnlineCourseCheck}AND${isFreeCheck})`;
    // build criteria
    const criteria = `${freeCourseCheck}AND${activeProductCriteria()}`;
    const result = await makeZohoAPIRequest({
      method: 'get',
      endpoint,
      data: { fields: Product.ZOHO_FIELDS, criteria },
      zohoModule: ZohoModule.PRODUCTS,
    });
    if (!result?.data?.[0]) return null;
    const products = result.data.map(data => Product.deserializeZoho(data)) as Product[];
    return products;
  };
};

//* Query
export const getAllFreeOnlineCourses = registerQuery({
  type: () => [Product],
  operationName: 'getAllFreeOnlineCourses',
  resolve: getAllFreeOnlineCoursesResolver,
  params: [],
});
