import type { Maybe } from '@adornis/base/utilTypes.js';
import { RXController } from '@adornis/chemistry/controllers/RXController.js';
import { css } from '@adornis/chemistry/directives/css.js';
import { XAccordeon } from '@adornis/chemistry/elements/components/x-accordeon.js';
import '@adornis/chemistry/elements/components/x-icon.js';
import { XToggleContent } from '@adornis/chemistry/elements/components/x-toggle-content.js';
import { xComponents } from '@adornis/chemistry/elements/x-components.js';
import type { Renderable } from '@adornis/chemistry/renderable.js';
import { html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import './d-icon.js';

export enum ActiveStateType {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  ACTIVE_PASSIVE = 'active-passive',
}

/**
 * @element d-accordeon
 */
@customElement('d-accordeon-content')
export class DubniumAccordeonContent extends XAccordeon {
  @property({ attribute: 'hide-icon', type: Boolean }) hideIcon = false;
  @property({ attribute: 'active-state-type', type: String }) activeStateType = new RXController<ActiveStateType>(
    this,
    ActiveStateType.INACTIVE,
  );

  @state() override items = new RXController<
    {
      title: Renderable;
      text: Maybe<Renderable>;
      [ActiveStateType.INACTIVE]: Renderable;
      [ActiveStateType.ACTIVE]: Renderable;
      [ActiveStateType.ACTIVE_PASSIVE]: Renderable;
    }[]
  >(this, []);

  override render() {
    return html`
      <d-flex space="md">
        ${repeat(
          this.items.value || [],
          item => item.title,
          (item, index) => html`
            <d-toggle-content-content
              ${css(this.selected === index || this.isPrinting.value ? { marginBottom: '0' } : {})}
              .label=${item[this.activeStateType.value]}
              @open-changed=${(e: CustomEvent) => {
                if (e.detail.value !== undefined && e.detail.value !== null) {
                  if (e.detail.value && this.selected !== index) this.selected = index;
                  else if (!e.detail.value && this.selected === index) this.selected = -1;
                }
              }}
              no-icon=${this.hasAttribute('no-icon')}
              ?open=${this.selected === index ||
              this.isPrinting.value ||
              [ActiveStateType.ACTIVE, ActiveStateType.ACTIVE_PASSIVE].includes(this.activeStateType.value)}
              ?hide-icon=${this.hideIcon || !item.text}
            >
              ${typeof item.text === 'string' ? html` <d-text> ${item.text} </d-text> ` : item.text}
            </d-toggle-content-content>
            ${this.spacer(index)}
          `,
        )}
      </d-flex>
    `;
  }
}

/**
 * @element d-toggle-content-content
 */
@customElement('d-toggle-content-content')
export class DubniumToggleContentContent extends XToggleContent {
  @property({ attribute: 'hide-icon', type: Boolean }) hideIcon = false;

  override render() {
    return xComponents(html`
      <d-flex
        horizontal
        crossaxis-center
        space="md"
        space-between
        ${css({ cursor: 'pointer', userSelect: 'none', padding: '16px 24px' })}
        @click=${() => {
          this.open = !this.open;
          if (!this.open) this.blur();
        }}
      >
        <d-flex> ${typeof this.label === 'string' ? html` <d-h4> ${this.label} </d-h4> ` : this.label} </d-flex>
        ${this.icon()}
      </d-flex>
      <d-hr></d-hr>
      <d-flex
        id="content"
        ${css({
          ...(this.open
            ? { height: 'unset' }
            : { height: '0', opacity: '0', pointerEvents: 'none', overflow: 'hidden' }),
        })}
      >
        ${this._content()}
      </d-flex>
    `);
  }

  protected override icon() {
    if (this.hideIcon) return nothing;
    return html`
      <d-icon secondary ${css({ fontSize: '16px' })}> ${this.open ? 'chevron-up' : 'chevron-down'} </d-icon>
    `;
  }
}
