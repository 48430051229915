import type { Maybe, ValueEvent } from '@adornis/base/utilTypes.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration';
import { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element.js';
import { RXController } from '@adornis/chemistry/controllers/RXController.js';
import { acss } from '@adornis/chemistry/directives/acss.js';
import { css } from '@adornis/chemistry/directives/css.js';
import { whenSeen } from '@adornis/chemistry/directives/when-seen.js';
import { XSnackbar } from '@adornis/chemistry/elements/components/x-snackbar.js';
import { DubniumDialog } from '@adornis/digitale-helden-shared/client/theme/d-dialog.js';
import '@adornis/digitale-helden-shared/client/theme/d-empty-placeholder.js';
import { askForFile } from '@adornis/file-utils/client/helper-methods.js';
import { FormController } from '@adornis/forms/x-form-controller.js';
import { QueryPagination } from '@adornis/pagination/paginatable.js';
import { goTo } from '@adornis/router/client/open-href.js';
import { html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { DateTime } from 'luxon';
import { debounceTime, filter, takeUntil } from 'rxjs';
import '../../../client/theme/fonts';
import { FileFilter } from '../../../db/files/FileFilter.js';
import { LASFile } from '../../../db/files/LASFile.js';

export enum SupportedFileType {
  PNG = 'png',
  PDF = 'pdf',
  JPG = 'jpg',
  JPNG = 'jpng',
  MP3 = 'mp3',
  MP4 = 'mp4',
  GIF = 'gif',
  WEBP = 'webp',
}

@customElement('las-file-explorer')
export class LASFileExplorer extends ChemistryLitElement {
  @property({ type: Boolean }) selectable = false;
  @property({ type: String }) selectedFileID: Maybe<string>;
  @property({ attribute: false }) allowedFileTypes = new RXController<Array<SupportedFileType>>(this, []);

  @state() private readonly _fileFilter = new FileFilter({});
  @state() private readonly _fileFilterController = new FormController(this, this._fileFilter);

  private readonly _files = new QueryPagination(
    LASFile.subscribeFilesPaginatedFiltered.bind(LASFile),
    [this._fileFilter],
    { _id: 1, name: 1, meta: { extension: 1, fileName: 1, size: 1 }, createdAt: 1 } as BaseQLSelectionSet<LASFile>,
    20,
    10,
  );

  @state() private _fileData = new RXController(
    this,
    this._files.data.pipe(filter(Boolean), takeUntil(this.disconnected)),
  );

  override connectedCallback(): void {
    super.connectedCallback();

    this.allowedFileTypes.observable.pipe(filter(Boolean)).subscribe(allowedTypes => {
      this._fileFilter.fileExtensions = allowedTypes;
    });

    this._fileFilter.whenChanging
      .pipe(filter(Boolean), debounceTime(200), takeUntil(this.disconnected))
      .subscribe(() => {
        console.log('update params');
        this._files.updateParameters(this._fileFilter);
      });
  }

  override render() {
    return html`
      <d-flex space="md">
        <d-flex
          ${acss({
            border: `1px dashed ${this.colors.tone.secondaryText}`,
            padding: this.spacing.lg,
            borderRadius: this.sizes.borderRadius,
            '&:hover': {
              borderStyle: 'solid',
              cursor: 'pointer',
              borderColor: this.colors.tone.black,
              'd-text': {
                color: this.colors.tone.black,
              },
            },
          })}
          center
          crossaxis-center
          @click=${async () => {
            const files = await askForFile({
              acceptFileTypes:
                this.allowedFileTypes.value.length > 0
                  ? `${this.allowedFileTypes.value.map(type => `.${type}`).join(',')}`
                  : '',
              acceptMultipleFiles: true,
            });
            if (!files) return;

            const upload = async () => {
              for (const file of files) {
                const fileParts = file.name.split('.');
                const extension = fileParts[fileParts.length - 1] ?? '';

                await LASFile.upload(file);
              }
            };

            await DubniumDialog.waitFor(upload(), 'Datei(en) werden hochgeladen...');

            return XSnackbar.show('Datei(en) erfolgreich hochgeladen');
          }}
        >
          <d-text grey bold><d-icon> upload </d-icon> Klicken, um Datei hochzuladen. </d-text>
        </d-flex>
        <d-flex horizontal crossaxis-end space="md">
          <d-input
            placeholder="Suche.."
            flex
            clearable
            @value-changed=${(e: ValueEvent<string>) => {
              this._fileFilter.fileName = e.detail.value ?? null;
            }}
          ></d-input>
          ${this.allowedFileTypes.value.length > 0
            ? nothing
            : html`
                <d-dropdown-multiselection
                  ${css({ minWidth: '200px' })}
                  .selectables=${Object.values(SupportedFileType)}
                  ${this._fileFilterController.field('fileExtensions')}
                  clearable
                ></d-dropdown-multiselection>
              `}
        </d-flex>
        <d-text
          ${css({ alignSelf: 'end', cursor: 'pointer', color: this.colors.tone.secondaryText })}
          @click=${() => {
            if ((this.allowedFileTypes.value.length ?? 0) === 0) this._fileFilter.fileExtensions = null;
            this._fileFilter.fileName = null;
          }}
        >
          Filter zurücksetzen
        </d-text>
        ${this._renderFiles()}
      </d-flex>
    `;
  }

  private _renderFiles() {
    const fileData = this._fileData.value;
    if (!fileData || fileData.length === 0)
      return html`
        <d-flex space="md" crossaxis-center>
          <d-empty-placeholder
            no-wrapper
            .text=${'Keine Dateien gefunden'}
            .img=${'do-not-enter'}
          ></d-empty-placeholder>
        </d-flex>
      `;
    return html`
      <d-flex space="sm">
        ${repeat(
          fileData,
          file => file._id,
          (file, index) => this._renderFile(file, index, fileData.length - 1),
        )}
      </d-flex>
    `;
  }

  private _renderFile(file: LASFile, index: number, maxIndex: number) {
    return html`
      <d-flex padding="xs" horizontal crossaxis-center space="md" ${css({ minHeight: '50px' })}>
        ${whenSeen(
          () => {
            console.log('seen', index, maxIndex);
            if (index !== maxIndex) return;
            this._files.next();
          },
          () => {},
        )}
        ${this.selectable
          ? html`
              <d-checkbox
                .value=${this.selectedFileID === file._id}
                @value-picked=${(e: ValueEvent<boolean>) => {
                  if (e.detail.value) {
                    this.selectedFileID = file._id;
                  } else {
                    this.selectedFileID = null;
                  }
                  this.dispatchEvent(new CustomEvent('file-changed', { detail: { value: this.selectedFileID } }));
                  this.requestUpdate();
                }}
              ></d-checkbox>
            `
          : nothing}
        <las-icon-button
          icon=${this._iconByType(file.meta?.extension ?? '')}
          background="secondary"
          color="black"
        ></las-icon-button>
        <d-text
          bold
          ${acss({
            '&:hover': {
              textDecoration: 'underline',
              cursor: 'pointer',
            },
          })}
          @click=${() => {
            goTo(LASFile.getServeLink(file._id), { target: '_blank' });
          }}
        >
          ${file.meta?.fileName}.${file.meta?.extension}
        </d-text>
        <d-flex flex horizontal crossaxis-center space="md" end>
          <d-text> ${this._formatFileSize(file.meta?.size ?? 0)} </d-text>
          <d-text> ${DateTime.fromJSDate(file.createdAt).toLocaleString(DateTime.DATE_MED)} </d-text>
          <d-icon
            solid
            medium
            @click=${async () => {
              LASFile.removeFile(file._id);
            }}
          >
            trash
          </d-icon>
        </d-flex>
      </d-flex>
      ${index + 1 < (this._fileData.value?.length ?? 0)
        ? html` <d-flex ${css({ height: '1px', background: this.colors.tone.secondaryText })}></d-flex> `
        : nothing}
    `;
  }

  private _formatFileSize(sizeInByte: number) {
    let sizeUnit = 'Byte';

    if (sizeInByte > 1000) {
      sizeInByte = sizeInByte / 1000;
      sizeUnit = 'KB';
    }
    if (sizeInByte > 1000) {
      sizeInByte = sizeInByte / 1000;
      sizeUnit = 'MB';
    }

    return html`${sizeInByte.toFixed(2)} ${sizeUnit}`;
  }

  private _iconByType(extension: string) {
    switch (extension as SupportedFileType) {
      case SupportedFileType.WEBP:
        return 'spider-web';
      case SupportedFileType.GIF:
        return 'gif';
      case SupportedFileType.PDF:
        return 'file-pdf';
      case SupportedFileType.JPG:
      case SupportedFileType.JPNG:
      case SupportedFileType.PNG:
        return 'file-image';
      case SupportedFileType.MP3:
        return 'music';
      case SupportedFileType.MP4:
        return 'video';
    }
  }
}
