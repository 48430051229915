import { Entity, Field } from '@adornis/baseql/decorators.js';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity.js';
import { emailRegexCheck } from '@adornis/users/db/validators.js';
import { validate } from '@adornis/validation/decorators.js';
import { ValidationError } from '@adornis/validation/errors/ValidationError.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';

@Entity()
export class SignupAlternateBillData extends AdornisEntity {
  static override _class = 'SignupAlternateBillData';

  @validate(nonOptional())
  @Field(type => String)
  salutation!: string;

  @validate(nonOptional())
  @Field(type => String)
  firstName!: string;

  @validate(nonOptional())
  @Field(type => String)
  lastName!: string;

  @validate(options => {
    if (!emailRegexCheck(options.value))
      throw new ValidationError('Bitte gib eine gültige E-Mail an.', {
        key: options.key,
        translationKey: 'validation_email',
      });
  })
  @Field(type => String)
  email!: string;

  @validate(options => {
    if (!options.value)
      throw new ValidationError('Dies ist ein Pflichtfeld.', {
        key: options.key,
        translationKey: 'validation_non_optional',
      });
    if (options.target.email !== options.value) {
      throw new ValidationError('Die E-Mail-Adressen müssen übereinstimmen.', {
        key: options.key,
        translationKey: 'email_repeat_error',
      });
    }
  })
  @Field(type => String)
  emailRepeat!: string;

  @validate(nonOptional())
  @Field(type => String)
  companyName!: string;

  @validate(nonOptional())
  @Field(type => String)
  street!: string;

  @validate(nonOptional())
  @Field(type => String)
  zip!: string;

  @validate(nonOptional())
  @Field(type => String)
  city!: string;

  @validate(nonOptional())
  @Field(type => String)
  state!: string;

  @validate(nonOptional())
  @Field(type => String, { default: v => 'DE' })
  country!: string;
}
