import { registerQuery } from '@adornis/baseql/metadata/register.js';
import { context } from '@adornis/baseql/server/context.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import type { LASUser } from '@adornis/digitale-helden-shared/db/las-user.js';
import { Product } from '@adornis/digitale-helden-shared/db/product/Product.js';
import { getProductsByIDs } from '@adornis/digitale-helden-shared/db/product/queries/getProductsByIDs.js';
import { CurrentUserInfo } from '@adornis/users/db/currentUserInfo.js';
import { Order } from '../../../db/Order.js';

//* Resolver
const getProductsByContextIDResolver = () => {
  return async (gqlFields: BaseQLSelectionSet<Product>) => {
    if (context.serverContext) return [];
    const user = await CurrentUserInfo.getMyself<LASUser>()({ _id: 1, zohoID: 1 });
    if (!user) return [];

    const orders = await Order.getAllOrdersByContactId(user.zohoID)({
      id: 1,
      productId: 1,
      productConfiguration: gqlFields,
    });

    if (!orders.length) return [];

    let productIDs = Array.from(new Set(orders.map(order => order.productId)));
    productIDs = productIDs.filter(id => !!id);

    const products = await getProductsByIDs(productIDs, true)(gqlFields);
    return products;
  };
};

//* Query
export const getProductsByContextID = registerQuery({
  type: () => [Product],
  operationName: 'getProductsByContextID',
  resolve: getProductsByContextIDResolver,
  params: [],
});
