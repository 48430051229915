import type { Maybe } from '@adornis/base/utilTypes.js';
import type { Renderable } from '@adornis/chemistry/renderable.js';
import { html, nothing } from 'lit';

const defaultTitle = html` Campus Benachrichtigung `;
// eslint-disable-next-line max-lines-per-function
export const emailTemplate = (
  firstBox: {
    title?: Maybe<Renderable>;
    content: Renderable;
  } = {
    title: defaultTitle,
    content: html``,
  },
  secondBox?: Maybe<{
    title: Renderable;
    content: Renderable;
  }>,
  thirdBox?: Maybe<{
    title: Renderable;
    content: Renderable;
  }>,
) => {
  return html`
<head>
  <title>Digitale Helden E-Mail</title>
  <style>
    
      * {
          margin: 0;
          padding: 0;
          font-family: sans-serif;
          color: #555;
      }
      
      .upper-half {
          background-color: #f9f9f9 !important;
          width: 100%;
      }
      
      body {
          background-color: #fff !important;
          width: 100%;
      }
      
      .max-readable-width {
          max-width: 750px;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
      }
      
      .background-image {
          background-size: auto;
          background-position: top center;
          background-repeat: no-repeat;
      }
      
      .section {
          width: min(650px, 100%);
          position: relative;
          left: 50%;
          transform: translateX(-50%);
      }

      .section-socials-container {
          text-align:center;
          width: 100%;
          margin-top: 15px;
          margin-bottom: 30px;
          padding-bottom: 60px;
      }

      .table-socials-centerwrapper {
          width: 200px;
          margin: auto;
      }

      .square {
          float: left;
          margin:5px;
          width:30px;
      }

      .facebook, .twitter, .YouTube, .Linkedin, .Xing {
          width: 30px;
      }

      .button {
          background-color: #afca0b; /* Green */
          border: none;
          border-radius: 5px;
          color: white;
          padding: 15px 15px;
          text-align: center;
          text-decoration: none;
          font-weight: bold;
          display: inline-block;
          font-size: 14px;
          margin-top: 14px;
          margin-bottom: 14px;
      }

      .button a {
          color:#fff;
          text-decoration: none;
      }
      
      .h100 {
          height: 100%;
      }

      .dh-hex {
              padding-left: 30px;
          }
      
      .background-company-info {
          background-image: url("https://stratus.campaign-image.eu/images/1686144569829_6_zc_v13_128624000000387358.png");
          background-size: cover;
          background-position: top center;
          background-repeat: no-repeat;
      }

      .center {
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: min(650px, 100%);
          margin-bottom: 30px;
          padding-bottom: 30px;
      }

      .company-info-table {
          width: 100%;
      }
      
      .company-info-table>div:nth-child(2) {
          padding-left: 20px;
          padding-top: 15px;
      }

      /* CSS specific to iOS devices */ 
      @supports (-webkit-touch-callout: none) {
          .company-info-table {
              display: grid;
              width: 100% !important;
          }
          .company-info-table-td {
              display: grid;
              width: 100% !important;
          }
          .table-responsive {
              display: grid;
              width: 100% !important;
          }
          table.table-responsive tbody, table.table-responsive thead, table.table-responsive tbody, table.table-responsive th, table.table-responsive td, table.table-responsive tr {
              display: grid; 
              width: 100%;
          }
          table, thead, tbody, th, td, tr {
              display: grid; 
              width: 100%;
          }
          .dh-hex {
              padding-left: 30px;
          }
      }
      
      @media (max-width: 750px) {
          .company-info-table {
              display: grid;
              grid-template-columns: 1fr;
              column-gap: 20px;
          }
          .company-info-table {
              display: block;
              width: 100% !important;
          }
          .company-info-table-td {
              display: block;
              width: 100% !important;
          }
          .dh-hex {
              padding-left: 30px;
          }
          .company-info-table>div:nth-child(2) {
              padding-left: 0;
              padding-top: 0;
          }
      }

  </style>

  <style class="zccustomstyles" type="text/css">.ze_body{font-family:Verdana,arial,Helvetica,sans-serif;font-size:14px;} table{font-size:100%}</style>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="format-detection" content="telephone=no">
</head>

<body class="ze_body">
  <div class="upper-half main-container">
      <div class="max-readable-width">
          <!-- first banner -->
          <div class="background-image" style="height: 33px;">
          </div>

          <!-- second banner -->
          <div class="background-image" style="height: 45px;">
              <div class="section">
                  <img title="Second Banner" class="dh-hex" style="height: 45px; width: auto;" src="https://stratus.campaign-image.eu/images/221019-hexa-hash-dh-100_zc_v13_1_128624000000387358.png" class="imghvr" alt="Bitte Bilder laden">
              </div>
          </div>

          <!-- third banner -->
          <div class="background-image" style="height: 16px;">
          </div>

          <!-- fourth banner -->
          <div class="background-image" style="height: 25px;">
              <div class="section h100">
                  <div style="height: 100%; background: #fff; border-radius: 10px 10px 0 0;width: 100%;">
                  </div>
              </div>
          </div>

          <!-- fifth banner -->
          ${box(firstBox.title ?? defaultTitle, firstBox.content)}

          ${
            secondBox
              ? html`<div class="section">
                  <div style="height: 30px; background: #f9f9f9; width: 100%;"></div>
                </div>`
              : nothing
          }
          

          ${secondBox ? box(secondBox.title, secondBox.content) : nothing}

            ${
              thirdBox
                ? html`<div class="section">
                    <div style="height: 30px; background: #f9f9f9; width: 100%;"></div>
                  </div>`
                : nothing
            }

          ${thirdBox ? box(thirdBox.title, thirdBox.content) : nothing}

          <!-- card company info part one -->
          <div>
              <div class="section">
                  <div class="background-company-info" style="margin-top: 30px; width: 100%; border-radius: 10px 10px 0 0; padding: 0 30px 10px 30px; box-sizing: border-box;">
                      <table class="company-info-table table-responsive">
                          <div class="table-responsive">
                              <tbody width="100%" class="table-responsive">
                                  <tr class="table-responsive">
                                      <td class="company-info-table-td table-responsive">
                                          <img title="Image" style="display:block;height:auto;border:0;width:118px;max-width:100%;margin-top:-1px;" src="https://stratus.campaign-image.eu/images/221020_logo_p_300_zc_v2_128624000000028624.png" class="imghvr" alt="Digitale Helden" width="118">
                                      </td>
                                      <td class="company-info-table-td table-responsive">
                                          <div>
                                              <div style="font-size:14px;color:#555;line-height:18px;font-family:Helvetica Neue,Helvetica,Arial,sans-serif; padding: 10px; padding-left: 0;">
                                                  <p style="line-height:18px;margin:0;"><strong>Digitale Helden gGmbH</strong></p>
                                                  <p style="line-height:18px;margin:0;">Arnsburger Straße 58&thinsp;&thinsp;d</p>
                                                  <p style="line-height:18px;margin:0;">(Hinterhaus)</p>
                                                  <p style="line-height:18px;margin:0;">60385 Frankfurt am Main</p>
                                              </div>
                                          </div>
                                      </td>
                                      <td class="company-info-table-td table-responsive">
                                          <div style="padding-top: 15px;">
                                              <div style="font-size:14px;color:#555;line-height:18px;font-family:Helvetica Neue,Helvetica,Arial,sans-serif;padding: 10px; padding-left: 0;">
                                                  <p style="line-height:18px;margin:0;"></p>
                                                  <p style="line-height:18px;margin:0;">Telefon <a href="tel:00496987403610" style="color:#555; text-decoration:none;">+49 69 8740361·0</a></p>
                                                  <p style="line-height:18px;margin:0;">Telefax <a href="tel:00496987403610" style="color:#555; text-decoration:none;">+49 69 8740361·69</a></p>
                                                  <p style="line-height:18px;margin:0;"><a target="_blank" style="text-decoration: underline;text-decoration-color: #afca0b;" rel="noopener" href="http://www.digitale-helden.de"><strong style="color: #afca0b; ">digitale-helden.de</strong></a></p>
                                              </div>
                                          </div>
                                      </td>
                                  </tr>
                              </tbody>
                          </div>
                      </table>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <!-- card company info part two -->
  <div class="section">
      <div class="max-readable-width" style="width: 100%; border-radius: 0 0 10px 10px; padding: 20px 25px 35px 30px; background: #f7faec; box-sizing: border-box; font-size:14px; text-align:left; line-height:18px;">
          <p style="line-height: 1.2; margin:0; font-size:14px; text-align:left;">
              Wir gehen
              <strong>sorgsam</strong> und
              <strong>verantwortungsvoll</strong> mit deinen Daten um. Hier findest du weiterführende Links und
              <strong>Informationen</strong> zum <strong><a target="_blank" style="text-decoration: underline; color: #afca0b;" rel="noopener" href="https://digitale-helden.de/startseite/datenschutz">Datenschutz</a></strong> und unser <strong><a target="_blank" style="color: #afca0b;" rel="noopener" href="https://digitale-helden.de/startseite/impressum">Impressum.</a></strong>
          </p>
      </div>
  </div>

  <!-- social media -->
  <div class="section-socials-container">
      <div class="max-readable-width">
          <div class="table-socials-centerwrapper">
              <div class="facebook square">
                  <a target="_self" style="text-decoration: none;" href="https://www.facebook.com/DigitaleHelden">
                      <img width="29" style="display:block;height:auto;margin:5px;border:0;" src="https://stratus.campaign-image.eu/images/fb-w-120_zc_v13_3_128624000000387358.png" height="32" class="icon" alt="facebook">
                  </a>    
              </div>
              <div class="YouTube square">
                  <a target="_self" style="text-decoration: none;" href="https://www.youtube.com/user/DigitaleHelden">
                      <img width="29" style="display:block;height:auto;margin:5px;border:0;" src="https://stratus.campaign-image.eu/images/yt-w-120_zc_v13_5_128624000000387358.png" height="32" class="icon" alt="YouTube">
                  </a>
              </div>
              <div class="Linkedin square">
                  <a target="_self" style="text-decoration: none;" href="https://de.linkedin.com/company/digitale-helden-ggmbh">
                      <img width="29" style="display:block;height:auto;margin:5px;border:0;" src="https://stratus.campaign-image.eu/images/in-w-120_zc_v13_6_128624000000387358.png" height="32" class="icon" alt="Linkedin">
                  </a>
              </div>
              <div class="Xing square">
                  <a target="_self" style="text-decoration: none;" href="https://www.xing.com/pages/digitaleheldenggmbh">
                      <img width="29" style="display:block;height:auto;margin:5px;border:0;" src="https://stratus.campaign-image.eu/images/x-w-120_zc_v13_7_128624000000387358.png" height="32" class="icon" alt="Xing Dein Job-Netzwerk">
                      </a>
              </div>
          </div>
      </div>    
  </div>
  <!-- social media end -->
</div>
</body>


</html>
`;
};

function box(title: Renderable, content: Renderable) {
  return html`
    <div class="background-image" style="height: 55px;">
        <div class="section h100">
            <div style="height: auto; background: #fff;width: 100%; padding: 0 30px 10px 30px; box-sizing: border-box;">
                <p style="margin:0;font-size:14px;text-align:left;line-height:18px;">
                    ${title}
                    <div style="font-size:0px;line-height:0px;border-top:2px solid #afca0b; border-bottom: #afca0b 2px solid; border-radius: 4px; width:58px;margin-top: 12px; margin-bottom: 20px;"> <span> </span> </div>
                </p>
            </div>
        </div>
    </div>

    <div class="section">
        <div style="height: auto; background: #fff;width: 100%;padding: 0px 30px 10px 30px; box-sizing: border-box; font-size:14px; text-align:left; line-height:18px;">
        ${content}
        </div>
    </div>

    <div class="section">
    <div style="height: 15px; background: #fff; width: 100%; border-radius: 0px 0px 10px 10px;"></div>
    </div>
    `;
}
