import { Beleg } from '@adornis/accounting/api/beleg.js';
import { Kostenstellenzuweisung } from '@adornis/accounting/api/buchung.js';
import { Zusammenhangskomponente } from '@adornis/accounting/api/zusammenhangskomponente.js';
import { accountingConfig } from '@adornis/accounting/config.js';
import { A } from '@adornis/base/env-info.js';
import { logger } from '@adornis/base/logging.js';
import { Arg, Entity, Mutation, Query } from '@adornis/baseql/decorators.js';
import type { PartialEntityData } from '@adornis/baseql/entities/adornisEntity.js';
import { constructValue } from '@adornis/baseql/entities/construct.js';
import { baseqlMetaData } from '@adornis/baseql/metadata/metaDataStore.js';
import { getRawCollection } from '@adornis/baseql/server/collections.js';
import { context } from '@adornis/baseql/server/context.js';
import { loadMongoData } from '@adornis/baseql/server/data-loader.js';
import { Counter } from '@adornis/counter/counter.js';
import { Permissions } from '@adornis/digitale-helden-shared/db/permissions.js';
import { createPDFBufferFromComponent } from '@adornis/print/server/print-internals.js';
import { DateTime } from 'luxon';
import { checkRole } from '../../db/helpers.js';
import { LASAccountingTransaction } from './LASAccountingTransaction.js';

/**
 * Ensure that the first counter in 2024 starts at 13 for the invoice numbers
 */
export const ensureFirstCounter = async () => {
  const first = 'INVOICE_NUMBER-0-2024';
  const start = 13;

  const counter = await loadMongoData(Counter, { key: first });
  if (counter.length === 0) {
    logger.info('Counter is missing');
    const col = await getRawCollection<any>(Counter._collectionName);
    await col.insertOne({ _id: A.getGloballyUniqueID(), key: first, counter: start - 1 });
  } else if ((counter[0]?.counter ?? 0) < start - 1) {
    logger.info('Counter is too low');
    const col = await getRawCollection<any>(Counter._collectionName);
    await col.updateOne({ key: first }, { $set: { counter: start - 1 } });
  } else {
    logger.info(counter, 'Counter is ok');
  }
};

export const getInvoiceNumberCounter = async (kostenstelle: string, year: string) => {
  const key = `INVOICE_NUMBER-${kostenstelle}-${year}`;
  const collection = await getRawCollection(Counter._collectionName);
  const result = await collection.findOneAndUpdate(
    { key },
    { $inc: { counter: 1 }, $setOnInsert: { key } },
    { returnDocument: 'after', upsert: true },
  );
  return result?.counter ?? result?.value?.counter;
};

export const generateRechnungsNummer = async (kostenstelle: string) => {
  const yearString = DateTime.now().year.toString();
  const invoiceNumber = await getInvoiceNumberCounter(kostenstelle, yearString);
  const number = ('0000' + invoiceNumber.toFixed(0)).slice(-4);
  return `DH-R-${yearString}-${number}`;
};

/**
 * Creates a new invoice information with a new belegNummer
 * @param kostenstelle
 * @returns
 */
export const createInvoiceInformation = async (kostenstelle: string) => {
  return {
    belegNummer: await generateRechnungsNummer(kostenstelle),
  } satisfies PartialEntityData<Beleg>;
};

@Entity()
export class LASReceipt extends Beleg {
  static override _class = 'LASRechnung';

  constructor(doc: PartialEntityData<LASReceipt>) {
    super(doc);
  }

  static override get printComponentName() {
    return 'las-print-invoice';
  }

  static override createBelegForBuchung(
    buchungClassName: string,
    buchungsID: string,
    belegData: Beleg,
    zusammenhang?: Zusammenhangskomponente,
    ignoreAlreadyExists?: boolean,
  ) {
    return async gqlFields => {
      const buchungClass = baseqlMetaData.entities.resolveNode(buchungClassName).type();
      // @ts-expect-error
      const buchung = await buchungClass.getByID(buchungsID)({
        zusammenhaenge: Zusammenhangskomponente.allFields,
        committed: 1,
        kostenstellen: Kostenstellenzuweisung.allFields,
        belegID: 1,
      });
      if (!buchung) throw new Error('couldnt find transaction with ID ' + buchungsID);
      if (!buchung.committed) throw new Error('cant generate receipt for uncommitted buchung');
      if (!ignoreAlreadyExists && buchung.belegID) throw new Error('buchung has already a receipt');
      const zusammenhaenge = await loadMongoData(Zusammenhangskomponente, {
        buchungsIDs: buchungsID,
        belegIDs: { $exists: true, $not: { $ne: [], $size: 0 } },
        ...(zusammenhang ? { _id: { $ne: zusammenhang._id } } : {}),
      });
      if (zusammenhaenge.length) throw new Error('cant create beleg, already exists for buchung ' + buchungsID);
      const beleg = belegData;
      if (!beleg._id) beleg._id = A.getGloballyUniqueID();
      beleg.belegDatum ??= DateTime.now();
      const fileName = `Beleg-${beleg.belegNummer ?? beleg._id}.pdf`;
      buchung.belegID = belegData._id;
      await buchung.save();
      zusammenhang ??= new Zusammenhangskomponente({ comment: 'manuell erstellter Beleg' });
      zusammenhang.buchungsIDs ??= [];
      if (!zusammenhang.buchungsIDs.includes(buchung._id)) zusammenhang.buchungsIDs.push(buchung._id);
      zusammenhang.belegIDs ??= [];
      zusammenhang.belegIDs.push(beleg._id);
      await beleg.validate();
      zusammenhang = constructValue(zusammenhang)!;
      await zusammenhang.validate();
      beleg._id = await beleg.create();
      if (zusammenhang._id) await zusammenhang.save();
      else await zusammenhang.create();
      if (!accountingConfig.get('DISABLE_PRINT'))
        await beleg.replaceByBuffer(
          await createPDFBufferFromComponent({
            component: belegData.constructor.printComponentName,
            queryParams: new URLSearchParams({ 'beleg-id': beleg._id }).toString(),
            timeout: 120000,
          }),
          fileName,
        );
      else logger.debug('skipping print because of DISABLE_PRINT env var');
      return beleg as LASReceipt;
    };
  }

  @Mutation(type => String)
  public static regenerateReceipt(@Arg('belegID', type => String) belegID: string) {
    return async () => {
      if (context.serverContext) return;
      await checkRole({ context, role: Permissions.UserRoles.SUPER_ADMIN });

      const rawBelegCollection = await getRawCollection<LASReceipt>(LASReceipt._collectionName);
      let beleg = await rawBelegCollection.findOne<LASReceipt>({ _id: belegID });
      beleg = constructValue(beleg);
      if (!beleg) return;

      const belegDatum = beleg.belegDatum;
      const belegNummer = beleg.belegNummer;

      if (!belegDatum || !belegNummer) return;

      const rawZusammenhangsCollection = await getRawCollection<Zusammenhangskomponente>(
        Zusammenhangskomponente._collectionName,
      );
      const zusammenhang = await rawZusammenhangsCollection.findOne<Zusammenhangskomponente>({ belegIDs: belegID });
      if (!zusammenhang) return;

      const rawTransactionCollection = await getRawCollection<LASAccountingTransaction>(
        LASAccountingTransaction._collectionName,
      );
      const transaction = await rawTransactionCollection.findOne<LASAccountingTransaction>({
        _id: { $in: zusammenhang.buchungsIDs ?? [] },
      });
      if (!transaction) return;

      await rawBelegCollection.deleteOne({ _id: belegID });

      await this.createBelegForBuchung(
        LASAccountingTransaction._class,
        transaction._id,
        new LASReceipt({
          _id: belegID,
          belegDatum: belegDatum.toJSDate(),
          belegNummer: belegNummer,
        }),
        zusammenhang,
        true,
      )({});
    };
  }

  @Query(type => String)
  public static getLASReceiptFileIDByInvoiceNumber(@Arg('invoiceNumber', type => String) invoiceNumber: string) {
    return async () => {
      const col = await getRawCollection<LASReceipt>(LASReceipt._collectionName);
      const receipt = await col.findOne({ belegNummer: invoiceNumber });
      return receipt?._id;
    };
  }
}

@Entity()
export class LASCancellationReceipt extends Beleg {
  static override _class = 'LASCancellationReceipt';

  constructor(doc: PartialEntityData<LASCancellationReceipt>) {
    super(doc);
  }

  static override get printComponentName() {
    return 'las-print-invoice';
  }
}
