import type { Maybe } from '@adornis/base/utilTypes.js';
import { Int } from '@adornis/baseql/baseqlTypes.js';
import { Entity, Field } from '@adornis/baseql/decorators.js';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity.js';
import { validate } from '@adornis/validation/decorators.js';
import { ValidationError } from '@adornis/validation/errors/ValidationError.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';
import { outsideGermany } from '../../db/enums.js';
import { emailValidationFunction } from './SignupContactData.js';

@Entity()
export class SignupCompanyData extends AdornisEntity {
  static override _class = 'SignupCompanyData';

  @validate(({ key, value, target }) => {
    if (target.state === outsideGermany || target.isUnknownSchool) return;
    if (!value) {
      throw new ValidationError('Die ID ist ein Pflichtfeld.', {
        key,
      });
    }
  })
  @Field(type => String)
  id?: Maybe<string>;

  @validate(nonOptional())
  @Field(type => String)
  name!: string;

  @validate(nonOptional())
  @Field(type => String)
  street!: string;

  @validate(nonOptional())
  @Field(type => String)
  zip!: string;

  @validate(nonOptional())
  @Field(type => String)
  city!: string;

  @validate(nonOptional())
  @Field(type => String)
  state!: string;

  @validate(nonOptional())
  @Field(type => String)
  country!: string;

  @validate(nonOptional())
  @Field(type => String)
  schoolType!: string;

  @validate(emailValidationFunction)
  @Field(type => String)
  resetMail!: string;

  @validate(({ key, value, target }) => {
    if (target.state === outsideGermany) return;

    if (!value)
      throw new ValidationError(`Key ${key} is required!`, { key, translationKey: 'validation_non_optional' });

    if (value < 0 || Math.floor(value) !== value)
      throw new ValidationError('Dienststellennummer muss eine Ganzzahl sein', {
        key,
        translationKey: 'department_needs_positive_integer',
      });
    if (value >= 1000000000)
      throw new ValidationError('Dienststellennummer darf nur maximal 9 Stellen beinhalten', {
        key,
        translationKey: 'department_max_digits',
      });
  })
  @Field(type => Int)
  department!: number;

  @Field(type => String)
  website?: string;

  @Field(type => Boolean)
  isUnknownSchool?: boolean;

  // those fields check if the fields above are even needed
  // ------------------------------------------------------------------
  @validate(nonOptional({ allowFalsy: true }))
  @Field(type => Boolean, { default: v => v ?? false })
  isGroupTypeRequired!: boolean;

  // those fields check if the fields above are even needed
  // ------------------------------------------------------------------
  @validate(nonOptional({ allowFalsy: true }))
  @Field(type => Boolean, { default: v => v ?? false })
  applyCompanyFromProfile!: boolean;
}
