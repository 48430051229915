import { Entity, Field } from '@adornis/baseql/decorators.js';
import { BaseList } from '@adornis/buildify/db/extensions/BaseList.js';
import { DubniumBaseIcon } from './DubniumBaseIcon.js';

@Entity()
export class DubniumBaseList extends BaseList {
  static override _class = 'DubniumBaseList';

  @Field(type => DubniumBaseIcon, { default: v => v ?? new DubniumBaseIcon({}) })
  override icon!: DubniumBaseIcon;
}
