import { DateTime } from 'luxon';

export function serializeDateTimeZoho(value: DateTime) {
  if (!value) return null;

  const date = value instanceof Date ? DateTime.fromJSDate(value) : value.toUTC();
  const year = date.year;
  const month = date.month.toString().padStart(2, '0');
  const day = date.day.toString().padStart(2, '0');
  const hours = date.hour.toString().padStart(2, '0');
  const minutes = date.minute.toString().padStart(2, '0');
  const seconds = date.second.toString().padStart(2, '0');

  const utcDatetimeValue = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  return utcDatetimeValue;
}

export function serializeDateTimeZohoSQL(value: DateTime) {
  const dateString = value.toISO({
    suppressMilliseconds: true,
  });
  if (!dateString) return '';
  return dateString.replace(/\.\d{3}/, '');
}

export function serializeDateZoho(value: Date) {
  return DateTime.fromJSDate(value).toFormat('yyyy-MM-dd');
}

export function serializeDefault(value: any) {
  if (value) return value;
  return null;
}
