import { registerQuery } from '@adornis/baseql/metadata/register.js';
import { getRawCollection } from '@adornis/baseql/server/collections.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { LASUser } from '@adornis/digitale-helden-shared/db/las-user.js';

export const getLASUserByEmail = registerQuery({
  type: () => LASUser,
  operationName: 'getLASUserByEmail',
  resolve: (email: string) => {
    return async (gqlFIelds: BaseQLSelectionSet<LASUser>) => {
      const collection = await getRawCollection<LASUser>(LASUser._collectionName);
      const existingUser = await collection.findOne<LASUser>({
        $or: [
          { email: { $regex: new RegExp(`^${email.replace(/([^\w]{1})/g, '\\$1')}$`, 'i') } },
          { username: { $regex: new RegExp(`^${email.replace(/([^\w]{1})/g, '\\$1')}$`, 'i') } },
        ],
      });
      return existingUser;
    };
  },
  params: [{ name: 'email', type: () => String }],
});
