import { Entity, Field } from '@adornis/baseql/decorators.js';
import { validate } from '@adornis/validation/decorators.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';
import type Stripe from 'stripe';
import { LASPayment } from '../LASPayment.js';

@Entity()
export class LASPaymentDirectPay extends LASPayment {
  static override _class = 'LASPaymentDirectPay';
  static override _collectionName = 'payments';

  @validate(nonOptional())
  @Field(type => String, { default: v => 'paypal' })
  override paymentType!: Stripe.Checkout.SessionCreateParams.PaymentMethodType;
}
