import { A } from '@adornis/base/env-info.js';
import type { Maybe } from '@adornis/base/utilTypes.js';
import { Entity, Field } from '@adornis/baseql/decorators.js';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity.js';
import { Base } from '@adornis/buildify/db/Base.js';
import { BaseText } from '@adornis/buildify/db/extensions/text/BaseText.js';
import { validate } from '@adornis/validation/decorators.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';

@Entity()
export class DubniumBaseQuiz extends Base {
  static override _class = 'DubniumBaseQuiz';

  @Field(type => Boolean) isDigitalEmergency: Maybe<boolean>;

  @Field(type => [DubniumBaseQuizItem], {
    default: v => v ?? [new DubniumBaseQuizItem({}), new DubniumBaseQuizItem({})],
  })
  items!: DubniumBaseQuizItem[];

  @Field(type => String)
  questionText: Maybe<string>;

  @Field(type => String)
  answerText!: Maybe<string>;

  @Field(type => String)
  rowGap!: Maybe<string>;
}

@Entity()
export class DubniumBaseQuizItem extends AdornisEntity {
  static override _class = 'DubniumBaseQuizItem';

  @validate(nonOptional())
  @Field(type => String, { default: v => v ?? A.getGloballyUniqueID() })
  _id!: string;

  @validate(nonOptional())
  @Field(type => BaseText, { default: v => v ?? new BaseText({}) })
  title!: BaseText;

  @validate(nonOptional())
  @Field(type => BaseText, { default: v => v ?? new BaseText({}) })
  question!: BaseText;

  @validate(nonOptional())
  @Field(type => [String], { default: v => v ?? ['Option 1', 'Option 2', 'Option 3'] })
  options!: string[];

  @validate(nonOptional())
  @Field(type => BaseText, { default: v => v ?? new BaseText({}) })
  answer!: BaseText;
}
