import { Entity, Field } from '@adornis/baseql/decorators.js';
import { AdornisFilter } from '@adornis/filter/AdornisFilter.js';
import type { BuildifyUnionType } from './BuildifyUnion.js';

@Entity()
export class BuildifyUnionFilter extends AdornisFilter {
  static override _class = 'BuildifyUnionFilter';

  @Field(type => [String], { default: v => v ?? [] }) types!: BuildifyUnionType[];
}
