import { css } from '@adornis/chemistry/directives/css.js';
import type { Renderable } from '@adornis/chemistry/renderable.js';
import { XDropdownMultiSelection } from '@adornis/popover/x-dropdown-multiselection.js';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import './d-dropdown.js';
import './d-formfield-wrapper.js';
import './d-input.js';

/**
 * @element d-dropdown-multiselection
 */
@customElement('d-dropdown-multiselection')
export class DubniumDropdownMultiSelection<T> extends XDropdownMultiSelection<T> {
  protected override _icon() {
    return 'caret-down';
  }

  override renderItem: (x: T, index: number) => Renderable = (x: T, index: number) => html`
    <x-flex
      ${css({
        padding: `${this.spacing.sm} ${this.spacing.md}`,
        background: this.value.value?.includes(x) ? this.colors.accent.shade(200) : '#fff',
        cursor: 'pointer',
      })}
    >
      <d-text ${css({})}> ${this.renderString(x, index)} </d-text>
    </x-flex>
  `;
}
