import { PRODUCT_ZOHO_FIELDS } from '@adornis/digitale-helden-shared/db/product/Product.js';
import { DateTime } from 'luxon';
import { serializeDateTimeZoho } from '../../zoho/serialize-helper.js';

export const activeProductCriteria = (): string => {
  const activeCheck = () => `(${PRODUCT_ZOHO_FIELDS.INACTIVE}:equals:false)`;
  const hasNoSalesDateCheck = () => `(${PRODUCT_ZOHO_FIELDS.HAS_NO_SALES_DATE}:equals:true)`;
  const salesStartDateCheck = () =>
    `(${PRODUCT_ZOHO_FIELDS.SALES_START_DATE}:less_equal:${serializeDateTimeZoho(DateTime.now())})`;
  const salesEndDateCheck = () =>
    `(${PRODUCT_ZOHO_FIELDS.SALES_END_DATE}:greater_equal:${serializeDateTimeZoho(DateTime.now())})`;

  return `(${activeCheck()}AND(${hasNoSalesDateCheck()}OR(${salesStartDateCheck()}AND${salesEndDateCheck()})))`;
};
