import type { BuildifyExtension } from '@adornis/buildify/client/globals/types.js';
import { DesignSystem } from '@adornis/chemistry/elements/theming/design.js';
import 'client/theme/d-checkbox';
import 'client/theme/d-input';
import { DubniumBaseLeadSignupFormular } from 'db/buildify/_formulars/DubniumBaseLeadSignupFormular';
import { html } from 'lit';
import './campus-lead-signup-formular-extension.js';

export const BuildifyCampusLeadSignupFormularExtension: BuildifyExtension<DubniumBaseLeadSignupFormular> = {
  class: DubniumBaseLeadSignupFormular,
  toolbar: { icon: 'newspaper', text: { default: 'Newsletter - Webinar - Kampagnen' } },
  render(instance, { defaultValues, isSubelement, dropzoneBefore, dropzoneAfter }) {
    return html`
      <campus-lead-signup-formular-extension
        .content=${instance}
        .defaultValues=${defaultValues}
        ?sub=${isSubelement}
        .hasDropzoneBefore=${dropzoneBefore}
        .hasDropzoneAfter=${dropzoneAfter}
      ></campus-lead-signup-formular-extension>
    `;
  },
  editor({ content, contentController, host, controllerBaseKeyPath }) {
    return html`
      <d-flex space="lg">
        <d-flex space="sm">
          <d-checkbox ${contentController.field('isNewsletter')} .label=${'Newsletter-Verteiler'}></d-checkbox>
          <d-checkbox ${contentController.field('isWebinar')} .label=${'Webinar-Verteiler'}></d-checkbox>
          <d-icon-button
            text=${'Kommunikationskampagne hinzufügen'}
            icon="plus"
            @click=${() => {
              if (!content.communicationCampaigns) content.communicationCampaigns = [];
              content.communicationCampaigns.push('');
              host.requestUpdate();
            }}
          ></d-icon-button>
          ${(content.communicationCampaigns ?? []).map(
            (item, index) => html`
              <d-flex crossaxis-center space-between horizontal space="sm" wrap>
                <d-icon-button
                  icon="trash"
                  background=${DesignSystem.currentTheme.getValue().colors.tone.warning?.string}
                  color=${'#000'}
                  @click=${() => {
                    content.communicationCampaigns!.splice(index, 1);
                    host.requestUpdate();
                  }}
                >
                </d-icon-button>
                <d-input
                  flex
                  placeholder="Kampagnen-Name"
                  ${contentController.field('communicationCampaigns', index)}
                ></d-input>
              </d-flex>
            `,
          )}
        </d-flex>
      </d-flex>
    `;
  },
  group: 'Formulare',
};
