import { ContainerSectionExtension } from '@adornis/buildify/client/extensions/ContainerSectionExtension.js';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types.js';
import type { BaseContainerSection } from '@adornis/buildify/db/extensions/BaseSection.js';

export const BuildifyCampusSectionExtension: BuildifyExtension<BaseContainerSection> = {
  ...ContainerSectionExtension,
  toolbar: {
    icon: 'pipe-section',
    text: ContainerSectionExtension.toolbar.text,
  },
};
