import { A } from '@adornis/base/env-info.js';
import type { Maybe } from '@adornis/base/utilTypes.js';
import { Entity, Field } from '@adornis/baseql/decorators.js';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity.js';
import { Base } from '@adornis/buildify/db/Base.js';
import { BaseText } from '@adornis/buildify/db/extensions/text/BaseText.js';
import { validate } from '@adornis/validation/decorators.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';
import { DubniumBaseIcon } from './DubniumBaseIcon.js';

@Entity()
export class DubniumBaseTag extends Base {
  static override _class = 'DubniumBaseTag';

  @Field(type => [DubniumTagDefinition], { default: v => v ?? [] })
  items!: DubniumTagDefinition[];
}

@Entity()
export class DubniumTagDefinition extends AdornisEntity {
  static override _class = 'DubniumTagDefinition';

  @validate(nonOptional())
  @Field(type => String, { default: v => v ?? A.getGloballyUniqueID() })
  _id!: string;

  @validate(nonOptional())
  @Field(type => BaseText, { default: v => v ?? new BaseText({}) })
  text!: BaseText;

  @Field(type => DubniumBaseIcon)
  icon?: Maybe<DubniumBaseIcon>;

  @Field(type => String)
  borderRadius?: Maybe<string>;

  @Field(type => String)
  border?: Maybe<string>;

  @Field(type => String)
  backgroundColor?: Maybe<string>;
}
