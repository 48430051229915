import { DesignSystem } from '@adornis/chemistry/elements/theming/design.js';
import { setGlobalStyle } from '@adornis/chemistry/elements/theming/global-style.js';
import { addFontData } from '@adornis/fonts/font-resets.js';
import './components/assets/fonts';
import { colors } from './theme';

setGlobalStyle(() => ({
  '::selection': { color: 'white', background: DesignSystem.getTheme('dubnium')?.colors.accent },
  '.tippy-arrow': {
    color: `${colors.darkGrey} !important`,
  },
  'body, :host': { fontFamily: 'Netto W01 Regular', fontSize: `${1.0625 * 16}px`, lineHeight: '1.4' }, // default font for whole page
  '@media print, screen and (min-width: 21.5625em)': {
    body: {
      fontSize: '1.0625rem',
    },
  },
  body: {
    fontSize: '1.0625rem',
  },
  '@media print, screen and (min-width: 40em)': {
    body: {
      fontSize: '1.25rem',
    },
  },
  '@media print, screen and (min-width: 64em)': {
    body: {
      fontSize: '1.25rem',
    },
  },
  '@media screen and (min-width: 75em)': {
    body: {
      fontSize: '1.25rem',
    },
  },
  '@media screen and (min-width: 90em)': {
    body: {
      fontSize: '1.375rem',
    },
  },
}));

addFontData('Netto W01 Regular', {
  googleFont: false,
  capHeightRatio: 0.68,
  descenderHeightRatio: 0.125,
});

addFontData('NettoOT-Black', {
  googleFont: false,
  capHeightRatio: 0.73,
  descenderHeightRatio: 0.085,
});

// @customElement('d-text')
// export class DText extends XText {
//   override styles() {
//     return [
//       fontReset('Netto W01 Regular', 1.0625, 400, 1.4, 16),
//       {
//         fontFamily: 'Netto W01 Regular',
//         fontSize: '16px',

//         '&[paragraph]': { marginBlock: '1em' },

//         '&[inline]': { display: 'inline-block' },
//         '&[grey]': { color: this.colors.tone.secondaryText },
//         '&[neutral]': { color: this.colors.neutral },
//         '&[black]': { color: this.colors.tone.tooltipBackground },
//         '&[white]': { color: '#fff' },
//         '&[italic]': { fontStyle: 'italic' },
//         '&[tone=subtle]': { color: this.colors.tone.subtle },
//         '&[tone=success]': { color: this.colors.tone.success },
//         '&[tone=warning]': { color: this.colors.tone.warning },
//         '&[tone=error]': { color: this.colors.tone.error },
//         '&[tone=placeholder]': { color: this.colors.tone.placeholder },
//         '&[center]': { textAlign: 'center' },
//         '&[tiny]': {
//           fontSize: '10px',
//           lineHeight: '13px',
//         },
//         '&[small]': {
//           fontSize: '14px',
//           lineHeight: '22px',
//         },
//         '&[large]': { fontSize: '20px' },
//         '&[bold]': {
//           fontFamily: 'NettoOT-Black !important',
//         },
//         '&[highlight]': { color: this.colors.accent },
//       },
//       {
//         b: {
//           fontFamily: 'NettoOT-Black !important',
//         },
//       },
//     ];
//   }
// }

// @customElement('d-h1')
// export class DH1 extends XH1 {
//   override styles() {
//     return [
//       fontReset('NettoOT-Black', 1.5, 400, 1.16, 16),
//       {
//         fontSize: '16px',
//         lineHeight: '24px',

//         '@media screen and (min-width: 21.5625em)': {
//           ...fontReset('NettoOT-Black', 2.25, 400, 1.05, 16),
//           fontSize: '2.25rem',
//         },

//         '@media screen and (min-width: 40em)': {
//           ...fontReset('NettoOT-Black', 2.25, 400, 1.05, 16),
//           fontSize: '2.5rem',
//         },
//         '@media screen and (min-width: 64em)': {
//           ...fontReset('NettoOT-Black', 2.25, 400, 1.05, 16),
//           fontSize: '2.5rem',
//         },
//         '@media screen and (min-width: 75em)': {
//           ...fontReset('NettoOT-Black', 3.5, 400, 1.1, 16),
//           fontSize: '3.5rem',
//         },
//         '&[inline]': { display: 'inline-block' },
//       },
//     ];
//   }
// }

// @customElement('d-h2')
// export class DH2 extends XH2 {
//   override styles() {
//     return [
//       fontReset('NettoOT-Black', 1.5, 400, 1.16, 16),
//       {
//         fontSize: '24px',
//         lineHeight: '32px',
//         '&[inline]': { display: 'inline-block' },
//         '&[highlight]': { color: this.colors.accent },
//         color: this.colors.tone.black,
//       },
//     ];
//   }
// }

// @customElement('d-h3')
// export class DH3 extends XH3 {
//   override styles() {
//     return [
//       fontReset('NettoOT-Black', 1.5, 400, 1.16, 16),
//       {
//         fontSize: '20px',
//         '&[inline]': { display: 'inline-block' },
//       },
//     ];
//   }
// }

// @customElement('d-h4')
// export class DH4 extends XH4 {
//   override styles() {
//     return [
//       ...super.styles(),
//       {
//         fontFamily: 'NettoOT-Black',
//         fontSize: '16px',
//         fontWeight: '400',
//       },
//       {
//         '&[inline]': { display: 'inline-block' },
//         '&[center]': { textAlign: 'center' },
//         '&[white]': { color: '#fff' },
//         '&[highlight]': { color: this.colors.accent },
//         '&[small]': {
//           fontSize: '16px',
//           lineHeight: '24px',
//         },
//       },
//       {
//         '&[bold]': {
//           fontFamily: 'NettoOT-Black',
//         },
//       },
//     ] as Styles[];
//   }
// }
