import type { Styles } from '@adornis/ass/style.js';
import { XBuildifyGlobalSettings } from '@adornis/buildify/client/components/x-buildify-global-settings.js';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import './d-buildify-global-settings-tag-templates.js';

@customElement('d-buildify-global-settings')
export class DBuildifyGlobalSettings extends XBuildifyGlobalSettings {
  override customGlobalSettings() {
    return html`
      <stack-item name="Tag Templates">
        <d-buildify-global-settings-tag-templates
          ${this._form.field('tags')}
        ></d-buildify-global-settings-tag-templates>
      </stack-item>
    `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {},
      },
    ] as Styles[];
  }
}
