import { css } from '@adornis/chemistry/directives/css.js';
import { XFormFieldWrapper } from '@adornis/chemistry/elements/components/x-formfield-wrapper.js';
import { inputToken } from '@adornis/chemistry/elements/theming/input-token.js';
import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './d-icon.js';
import './fonts';

/**
 * @element d-formfield-wrapper
 */
@customElement('d-formfield-wrapper')
export class DubniumFormfieldWrapper extends XFormFieldWrapper {
  // @ts-expect-error different placeholder mode declaration
  @property({ reflect: true, attribute: 'placeholder-mode' }) override placeholderMode:
    | 'inline-floating'
    | 'floating'
    | 'prefix'
    | 'hide'
    | 'static-floating' = 'static-floating';

  override get inputToken() {
    const token = inputToken(this);
    token.borderRadius = this.sizes.borderRadiusSecondary;
    // token.border = '1px solid rgba(128,127,128,.3)';
    token.border = 'none';
    token.backgroundColor = this.colors.neutral.shade(100);
    token.padding = '.9375rem .625rem !important';
    // token.boxShadow = 'inset 0 0 2px rgb(128 127 128 / 30%)';
    return token;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': { height: '48px' },
        ':host(:not([disabled])) [background]:hover, :host [background]:focus-within': {
          // boxShadow: '0 0 2px rgb(128 127 128) !important',
          background: !!this.transparent ? 'transparent' : '#F7F7F7',
        },
        ':host [background]': {
          height: this.multiLine
            ? 'auto'
            : `calc(${this.baseFontSize}px + ${this.inputToken.paddingTop!} + ${this.inputToken.paddingBottom!} + 8px)`,
          border: 'none',
        },
        ':host([disabled])': {
          opacity: '0.5',
          pointerEvents: 'none',
        },
        ':host([error]) [background]': {
          border: `1px solid ${this.colors.tone.darkGreen}`,
          background: this.colors.tone.neutralAlabster,
        },
      },
    ];
  }

  protected _icon(): string {
    return 'caret-down';
  }

  protected override clearableIcon() {
    return this.clearable && this.showLabel && !this.disabled
      ? html`
          <d-icon
            ${css({ cursor: 'pointer' })}
            @click=${(e: MouseEvent) => {
              e.stopPropagation();
              this.dispatchEvent(new CustomEvent('cleared'));
            }}
          >
            xmark
          </d-icon>
        `
      : nothing;
  }

  protected override iconDisplay() {
    return this.icon
      ? html`
          <d-icon
            solid
            ${css({ cursor: 'pointer' })}
            @click=${() => {
              this.dispatchEvent(new CustomEvent('icon-click'));
            }}
          >
            ${this.icon}
          </d-icon>
        `
      : nothing;
  }

  protected override _label() {
    if (!this.error && !this.placeholder) return nothing;

    if (this.placeholderMode === 'static-floating') {
      return html`
        <d-flex
          horizontal
          space-between
          space="md"
          ${css({
            marginLeft: '0',
            marginBottom: this.spacing.xs,
            opacity: '1',
            transform: 'translateY(0)',
            transition: 'opacity .12s ease, transform .12s ease, max-height .12s ease, margin .12s ease',
            color: this.error ? this.colors.tone.error : undefined,
            pointerEvents: 'none',
          })}
        >
          <d-text tone="subtle">
            <d-text>
              ${this.error && this.error.length > 25
                ? html` <d-text ${css({ color: this.colors.tone.darkGreen, display: 'inline-block' })}>
                    ${this.error}
                  </d-text>`
                : html` <d-text ${css({ color: this.colors.tone.secondaryText, display: 'inline-block' })}>
                      ${this.placeholder}
                    </d-text>
                    &nbsp;
                    <d-text ${css({ color: this.colors.tone.darkGreen, display: 'inline-block' })}>
                      ${this.error}
                    </d-text>`}
            </d-text>
          </d-text>
        </d-flex>
      `;
    }

    return super._label();
  }
}
