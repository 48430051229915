import { A } from '@adornis/base/env-info.js';
import { registerMutation } from '@adornis/baseql/metadata/register.js';
import { ActionTokenData, TokenAction } from '@adornis/digitale-helden-shared/db/ActionTokenData.js';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact.js';
import { mailer } from '@adornis/digitale-helden-shared/server/communication.js';
import { Recipient } from '@adornis/mails/db/recipients.js';
import { Message } from '@adornis/mails/server/communication.js';
import { CampusRoute, PathToCampusRoute } from '../../../_routing/db/enums.js';
import { singleOptInTemplate } from '../../../db/mail-templates/single-opt-in.js';

export const sendContactSOIMail = registerMutation({
  type: () => String,
  operationName: 'sendContactSOIMail',
  resolve: (contact: Contact, createAccount: boolean = false) => {
    return async () => {
      const token = new ActionTokenData({ action: TokenAction.SOI, contact, createAccount });
      const tokenId = await token.create();
      const accessLink = A.absoluteUrl(PathToCampusRoute(CampusRoute.ACTION), new URLSearchParams({ tokenId }));

      await mailer.sendMail(
        await Message.compose({
          subject: 'Digitale Helden-Account aktivieren',
          html: singleOptInTemplate({ contact, accessLink, createAccount }),
        }),
        new Recipient([contact.email]),
      );
    };
  },
  params: [
    { name: 'contact', type: () => Contact },
    { name: 'createAccount', type: () => Boolean },
  ],
});
