import { logger } from '@adornis/base/logging.js';
import { registerMutation } from '@adornis/baseql/metadata/register.js';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact.js';
import { Lead } from '@adornis/digitale-helden-shared/db/Lead.js';
import { UpsertContactOptions } from '@adornis/digitale-helden-shared/db/UpsertContactOptions.js';
import { upsertContactAndCheckDOI } from '../../../campus/src/_api/contact/mutations/upsertContactAndCheckDOI.js';
import { upsertLeadAndCheckDOI } from './upsertLeadAndCheckDOI.js';

export const upsertLeadOrContactAndCheckDOI = registerMutation({
  type: () => String,
  operationName: 'upsertLeadOrContactAndCheckDOI',
  resolve: (instance: Lead, createAccount: boolean, options?: UpsertContactOptions) => {
    return async () => {
      try {
        await upsertLeadAndCheckDOI(instance, createAccount, options)(Lead.allFields);
      } catch (err) {
        logger.error(err, 'Fehler in upsertLeadOrContactAndCheckDOI catch block');
        const contact = new Contact({ ...instance.toJSON() });
        await upsertContactAndCheckDOI(contact, createAccount, options)(Contact.allFields);
      }
    };
  },
  params: [
    { name: 'instance', type: () => Lead },
    { name: 'createLASUser', type: () => Boolean },
    { name: 'options', type: () => UpsertContactOptions },
  ],
});
