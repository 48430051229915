import { constructValue } from '@adornis/baseql/entities/construct.js';
import { registerQuery } from '@adornis/baseql/metadata/register.js';
import { getRawCollection } from '@adornis/baseql/server/collections.js';
import { context } from '@adornis/baseql/server/context.js';
import { runInServerContext } from '@adornis/baseql/server/server.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { LASUser } from '@adornis/digitale-helden-shared/db/las-user.js';
import { Permissions } from '@adornis/digitale-helden-shared/db/permissions.js';
import { GLOBAL_CONTEXT } from '@adornis/users/db/a-roles.js';
import { CurrentUserInfo } from '@adornis/users/db/currentUserInfo.js';
import { AdornisRoleToContextsWrapper } from '@adornis/users/db/roleToContextsWrapper.js';
import { DateTime } from 'luxon';
import { RedeemCode } from './RedeemCode.js';

export const redeemCodeWithCode = registerQuery({
  type: () => RedeemCode,
  operationName: 'redeemCodeWithCode',
  params: [{ name: 'code', type: () => String }],
  resolve: (code: string) => {
    return async (gqlFields: BaseQLSelectionSet<RedeemCode>) => {
      if (context.serverContext) throw new Error('code usage not ment for server');
      const user = await CurrentUserInfo.getMyself<LASUser>()({
        _id: 1,
        email: 1,
        roles: AdornisRoleToContextsWrapper.allFields,
      });
      if (!user) throw new Error('no user found for this context');
      const rawCodeCollection = await getRawCollection<RedeemCode>(RedeemCode._collectionName);
      let redeemCode = await rawCodeCollection.findOne<RedeemCode>({ code });
      redeemCode = constructValue(redeemCode);
      if (!redeemCode) throw new Error('no redeem-code for this input found');
      if (redeemCode.redeemedBy || redeemCode.redeemedAt) throw new Error('code already used');
      if (redeemCode.expirationDate < DateTime.now()) throw new Error('code is expired');

      // redeem code and add role
      await runInServerContext(async () => {
        redeemCode.redeemedAt = DateTime.now();
        redeemCode.redeemedBy = user._id;
        await redeemCode.save();
        if (user.hasRole(Permissions.UserRoles.INVESTOR)) return;
        user.roles.push(
          new AdornisRoleToContextsWrapper({ name: Permissions.UserRoles.INVESTOR, contexts: [GLOBAL_CONTEXT] }),
        );
        await user.save();
      });
    };
  },
});
