import { registerMutation } from '@adornis/baseql/metadata/register.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact.js';
import { ContactEmailStatus } from '@adornis/digitale-helden-shared/db/enums.js';
import { mailer } from '@adornis/digitale-helden-shared/server/communication.js';
import { Recipient } from '@adornis/mails/db/recipients.js';
import { Message } from '@adornis/mails/server/communication.js';
import { GroupContactRelation } from '../../../db/Relations/GroupContactRelation.js';
import { OrderAttendenceStatus } from '../../../db/enums.js';
import { inviteToGroupTemplate } from '../../../db/mail-templates/invite-to-group.js';
import { GroupContactRelationData } from '../../../db/use-case-entities/GroupContactRelationData.js';
import { upsertContactAndCheckSOI } from './upsertContactAndCheckSOI.js';

export const createContactPersonForGroup = registerMutation({
  type: () => GroupContactRelationData,
  operationName: 'createContactPersonForGroup',
  resolve: (contact: Contact, groupContactRelation: GroupContactRelation) => {
    return async (gqlFields: BaseQLSelectionSet<GroupContactRelationData>) => {
      const upsertedContact = await upsertContactAndCheckSOI(
        contact,
        true,
      )(gqlFields.contact as BaseQLSelectionSet<Contact>);

      if (!upsertedContact.id) throw new Error('id not given');

      groupContactRelation.contactId = upsertedContact.id;

      let upsertedRelation: GroupContactRelation;
      try {
        upsertedRelation = await GroupContactRelation.upsertGroupContactRelation(groupContactRelation)(
          gqlFields.relation as any as BaseQLSelectionSet<GroupContactRelation>,
        );
      } catch (err) {
        // hier landet man, wenn bereits eine relation für den Kontakt vorhanden ist.
        upsertedRelation = await GroupContactRelation.upsertGroupContactRelation(
          new GroupContactRelation({
            ...groupContactRelation.toFilteredJSON,
            status: OrderAttendenceStatus.ANGEMELDET,
          }),
          true,
        )(gqlFields.relation as any as BaseQLSelectionSet<GroupContactRelation>);
      }

      const data = new GroupContactRelationData({ contact: upsertedContact, relation: upsertedRelation });

      if (upsertedContact.emailStatus !== ContactEmailStatus.OPT_IN_PENDING) {
        // send mail
        await mailer.sendMail(
          await Message.compose({
            html: inviteToGroupTemplate(),
            subject: 'Du wurdest zur Mentorenprogramm-Gruppe hinzugefügt',
          }),
          new Recipient(contact.email),
        );
      }

      return data;
    };
  },
  params: [
    { name: 'contact', type: () => Contact },
    { name: 'groupContactRelation', type: () => GroupContactRelation },
  ],
});
