import { VimeoExtension } from '@adornis/buildify/client/extensions/VimeoExtension.js';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types.js';

export const BuildifyCampusVimeoExtension: BuildifyExtension = {
  ...VimeoExtension,
  toolbar: {
    icon: 'video',
    text: VimeoExtension.toolbar.text,
  },
};
