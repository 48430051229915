import type { Maybe } from '@adornis/base/utilTypes.js';
import { BaseText } from '@adornis/buildify/db/extensions/text/BaseText.js';
import { getDefaultDubniumBuildifyExtensions } from '@adornis/digitale-helden-shared/client/theme/buildify/helpers.js';
import { CampusPageType } from '../db/enums.js';
import { DubniumDocumentContentExtension } from './theme-components/document/content/DubniumDocumentContentExtension.js';
import { DubniumDocumentExtension } from './theme-components/document/plain/DubniumDocumentExtension.js';
import { BuildifyCampusImageFlipcardExtension } from './theme-components/extensions/flipcard/BuildifyCampusImageFlipcardExtension.js';
import { BuildifyCampusImageExtension } from './theme-components/extensions/image/BuildifyCampusImageExtension.js';
import { BuildifyCampusLinkListExtension } from './theme-components/extensions/link-list/BuildifyCampusLinkListExtension.js';
import { BuildifyCampusQuizExtension } from './theme-components/extensions/quiz/BuildifyCampusQuizExtension.js';

export const getDocumentExtensionByType = (type: Maybe<CampusPageType>) => {
  if (!type) type = CampusPageType.PLAIN;
  return {
    [CampusPageType.PLAIN]: DubniumDocumentExtension,
    [CampusPageType.CONTENT]: DubniumDocumentContentExtension,
  }[type];
};

export const getCampusBuildifyExtensions = async () => {
  const imageExtension = BuildifyCampusImageExtension;
  const defaultExtensions = await getDefaultDubniumBuildifyExtensions({ imageExtension });
  const textExtension = defaultExtensions.find(ext => ext.class._class === BaseText._class);
  if (!textExtension) throw new Error('text extension not found');

  defaultExtensions.push(BuildifyCampusQuizExtension(textExtension));
  defaultExtensions.push(BuildifyCampusLinkListExtension);
  // @ts-ignore
  defaultExtensions.push(BuildifyCampusImageFlipcardExtension({ textExtension, imageExtension }));

  return defaultExtensions;
};
