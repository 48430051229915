export class BiMap<K, V> {
  private dictionary: Map<K, V>;

  constructor(iterable?: Iterable<readonly [K, V]> | null | undefined) {
    this.dictionary = new Map<K, V>(iterable);
  }

  public set(key: K, value: V): void {
    this.dictionary.set(key, value);
  }

  public get(key: K): V | undefined {
    return this.dictionary.get(key);
  }

  public reverseGet(value: V): K | undefined {
    let returnValue: K | undefined = undefined;
    Array.from(this.dictionary.keys()).map(key => {
      if (this.get(key as K) === value) returnValue = key as K;
    });
    return returnValue;
  }

  public get dict() {
    return this.dictionary;
  }

  public get reverseKeys() {
    return this.values;
  }

  public get reverseValues() {
    return this.keys;
  }

  public get keys() {
    return this.dictionary.keys();
  }

  public get values() {
    return this.dictionary.values();
  }
}
