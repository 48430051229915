/* eslint-disable no-irregular-whitespace */
import { baseConfig } from '@adornis/config/baseConfig.js';
import { emailTemplate } from '@adornis/digitale-helden-shared/mailing/e-mail-templates.js';
import { html } from 'lit';
import { DateTime } from 'luxon';
import { FormatPrice } from '../../_helpers/accounting-helpers.js';
import { ROOT_URL_SHORTENED } from '../../_helpers/meta-helpers.js';

export const orderConfirmationTemplate = (options: {
  paymentType: string;
  firstName: string;
  lastName: string;
  productName: string;
  imageLink: string;
  price: number;
  productLink: string;
  settingsInvoiceLink: string;
  isFunding?: boolean;
}) =>
  emailTemplate({
    content: html`
      <p style="line-height: 18px; padding-top: 10px;">
        Hallo <strong>${options.firstName}</strong>,<br />
        wir freuen uns, dass du in unserem Campus fündig geworden bist. Über den Menüpunkt<br />
        <a
          style=${`color: #AFCA0B; text-decoration: underline; cursor: pointer;`}
          href=${`${baseConfig.get('ROOT_URL')}campus/orders`}
          >Meine Lerninhalte</a
        >
        kannst du deine bestellten und abonnierten Produkte jederzeit aufrufen.
        <br />
      </p>

      <p style="font-size: 20px; margin-top: 30px;"><b> Bestellübersicht </b></p>
      <table style="width: 100%; border-spacing: 0px; margin-top: 20px; padding: 0; border: none;">
        <colgroup>
          <col span="1" style="width: 50%;" />
          <col span="1" style="width: 25%;" />
          <col span="1" style="width: 25%;" />
        </colgroup>

        <!-- Put <thead>, <tbody>, and <tr>'s here! -->
        <tbody>
          <tr>
            <td>
              <span style="text-align: start">
                Bestellt am ${DateTime.now().setLocale('de-DE').toLocaleString(DateTime.DATE_MED)}
              </span>
            </td>
            <td>
              <span style="text-align: start;"> Format </span>
            </td>
            <td>
              <span style="text-align: start;"> Preis </span>
            </td>
          </tr>
          <!-- spacing (kinda dirty) -->
          <tr>
            <td colspan="3"><div style="height: 20px"></div></td>
          </tr>
          <tr style="border-radius: 5px; background: #F7F7F7;">
            <td style="padding: 5px;">
              <table style="margin: 0; padding: 0; border: none;">
                <tr>
                  <td>
                    <img src=${options.imageLink} style="width: 80px; height: 80px; border-radius: 5px;" />
                  </td>
                  <td>
                    <p style="margin-left: 20px;"><b> ${options.productName} </b></p>
                  </td>
                </tr>
              </table>
            </td>
            <td>
              <p>Online-Kurs</p>
            </td>
            <td>
              <p><b> ${FormatPrice(options.price)} </b></p>
            </td>
          </tr>
          <!-- dividing line -->
          <tr>
            <td colspan="3">
              <div style="height: 1px; background: #bbbbb4; width: 100%; margin-top: 20px; margin-bottom: 20px;"></div>
            </td>
          </tr>

          <!-- pricing -->

          <tr>
            <td><div></div></td>
            <td><p>Gesamtbetrag</p></td>
            <td>
              <p><b> ${FormatPrice(options.price)} </b></p>
            </td>
          </tr>
          <tr>
            <td><div></div></td>
            <td><p>Zahlungsart</p></td>
            <td><p>${options.paymentType}</p></td>
          </tr>
        </tbody>
      </table>

      <div style="width: max-content; margin: 35px 0 0 auto;">
        <a
          href="${`${ROOT_URL_SHORTENED}${options.settingsInvoiceLink}`}"
          target="_blank"
          class="button"
          style="background: #fff !important; border: 2px solid #AFCA0B; margin: 0 24px 0 0 !important; padding: 12px 48px !important; cursor: pointer; font-size: 16px; color:#AFCA0B; font-weight: bold;"
        >
          ${options.isFunding ? 'Infoblatt' : 'Rechnung'}
        </a>
        <a
          href="${`${ROOT_URL_SHORTENED}${options.productLink}`}"
          target="_blank"
          class="button"
          style="margin: 0 !important; border: 2px solid #AFCA0B; padding: 12px 48px !important; cursor: pointer; font-size: 16px; color: #fff; font-weight: bold;"
        >
          Zum Produkt
        </a>
      </div>
    `,
  });
