import type { BuildifyExtension } from '@adornis/buildify/client/globals/types.js';
import type { AdornisTiptapExtension } from '@adornis/tiptap/client/extensions/ExtensionBase.js';
import { BuildifyCampusAccordeonExtension } from './extensions/BuildifyCampusAccordeonExtension.js';
import { BuildifyCampusSectionExtension } from './extensions/BuildifyCampusSectionExtension.js';
import { BuildifyCampusVimeoExtension } from './extensions/BuildifyCampusVimeoExtension.js';
import { BuildifyCampusYoutubeExtension } from './extensions/BuildifyCampusYoutubeExtension.js';
import { BuildifyCampusButtonExtension } from './extensions/button/BuildifyCampusButtonExtension.js';
import { BuildifyCampusIconTextExtension } from './extensions/icon-text/BuildifyCampusIconTextExtension.js';
import { BuildifyCampusIconExtension } from './extensions/icon/BuildifyCampusIconExtension.js';
import { BuildifyCampusListExtension } from './extensions/list/BuildifyCampusListExtension.js';
import { BuildifyCampusSpacingExtension } from './extensions/spacing/BuildifyCampusSpacingExtension.js';
import { BuildifyCampusTagExtension } from './extensions/tag/BuildifyCampusTagExtension.js';
// default extensions
import { TextExtension } from '@adornis/buildify/client/extensions/TextExtension.js';
import { Spacer } from '@adornis/tiptap/client/extensions/ActionbarSpacer.js';
import { BulletList } from '@adornis/tiptap/client/extensions/BulletList.js';
import { Color } from '@adornis/tiptap/client/extensions/Color.js';
import { Document } from '@adornis/tiptap/client/extensions/Document.js';
import { Dropcursor } from '@adornis/tiptap/client/extensions/Dropcursor.js';
import { HardBreak } from '@adornis/tiptap/client/extensions/HardBreak.js';
import { Link } from '@adornis/tiptap/client/extensions/Link/Link.js';
import { ListItem } from '@adornis/tiptap/client/extensions/ListItem.js';
import { LiteralTab } from '@adornis/tiptap/client/extensions/LiteralTab.js';
import { Paragraph } from '@adornis/tiptap/client/extensions/Paragraph.js';
import { Text } from '@adornis/tiptap/client/extensions/Text.js';
import { TextStyle } from '@adornis/tiptap/client/extensions/TextStyle.js';
import { TransformerHTML } from '@adornis/tiptap/client/extensions/TransformerHTML.js';
import { TransformerJSONObject } from '@adornis/tiptap/client/extensions/TransformerJSONObject.js';
import { TransformerJSONString } from '@adornis/tiptap/client/extensions/TransformerJSONString.js';
import { TransformerMarkdown } from '@adornis/tiptap/client/extensions/TransformerMarkdown.js';
import { BuildifyCampusContainerFlexBackgroundExtension } from './extensions/container/flex/BuildifyCampusContainerFlexBackgroundExtension.js';
import { BuildifyCampusContainerFlexExtension } from './extensions/container/flex/BuildifyCampusContainerFlexExtension.js';
import { BuildifyCampusContainerFlexLineExtension } from './extensions/container/flex/BuildifyCampusContainerFlexLineExtension.js';
import { BuildifyCampusContainerGridExtension } from './extensions/container/grid/BuildifyCampusContainerGridExtension.js';
import { BuildifyCampusContainerGridFourExtension } from './extensions/container/grid/BuildifyCampusContainerGridFourExtension.js';
import { BuildifyCampusContainerGridThreeExtension } from './extensions/container/grid/BuildifyCampusContainerGridThreeExtension.js';
import { BuildifyCampusContainerGridTwoExtension } from './extensions/container/grid/BuildifyCampusContainerGridTwoExtension.js';

// theme
import { type Maybe } from '@adornis/base/utilTypes.js';
import { BuildifyGlobalSettings, subscribeBuildifyGlobalSettings } from '@adornis/buildify/db/GlobalSettings.js';
import { Highlight } from '@adornis/tiptap/client/extensions/Highlight.js';
import { DigitaleHeldenBuildifyGlobalSettings } from '../../../db/buildify/DigitaleHeldenBuildifyGlobalSettings.js';
import { FontFamilyExtension } from '../../tiptap/FontFamilyExtension.js';
import { IconNodeExtension } from '../../tiptap/IconNode.js';
import { InsertHTMLExtension } from '../../tiptap/InsertHTMLExtension.js';
import { SuperscriptExtension } from '../../tiptap/SuperscriptExtension.js';
import { TagMarkExtension } from '../../tiptap/TagMark.js';
import '../index.js';
import {
  DigitaleHeldenTiptapBold,
  DigitaleHeldenTiptapHistory,
  DigitaleHeldenTiptapItalic,
  DigitaleHeldenTiptapStrike,
  DigitaleHeldenTiptapTextAlign,
  DigitaleHeldenTiptapUnderline,
} from '../tiptap/d-tiptap.js';
import { BuildifyCampusH1Extension } from './extensions/BuildifyCampusH1Extension.js';
import { BuildifyCampusH2Extension } from './extensions/BuildifyCampusH2Extension.js';
import { BuildifyCampusH3Extension } from './extensions/BuildifyCampusH3Extension.js';
import { BuildifyCampusH4Extension } from './extensions/BuildifyCampusH4Extension.js';
import { BuildifyCampusLeadSignupFormularExtension } from './extensions/_formulars/lead-signup-formular/BuildifyCampusLeadSignupFormularExtension.js';
import { BuildifyCampusSupportFormularExtension } from './extensions/_formulars/support-formular/BuildifyCampusSupportFormularExtension.js';

export const customParagraph = (
  tagName: string = 'text',
  options?: {
    fontSize: string;
  },
): AdornisTiptapExtension => {
  const defaultExtension = Paragraph(tagName);
  return {
    ...defaultExtension,
    styles: ({ host }) => [
      ...(defaultExtension.styles?.({ host }) ?? []),
      {
        ...(options?.fontSize ? { '[paragraph]': { fontSize: options.fontSize } } : {}),
        '[paragraph]:first-of-type': { marginTop: '0' },
        '[paragraph]:last-of-type': { marginBottom: '0' },
      },
    ],
  };
};

export const buildifyDefaultTiptapExtensions = (
  paragraph: AdornisTiptapExtension,
  globalSettings: Maybe<BuildifyGlobalSettings>,
): AdornisTiptapExtension[][] => {
  return [
    [FontFamilyExtension],
    [Document, Text, TextStyle, HardBreak, Dropcursor, Color, LiteralTab, ListItem, paragraph, BulletList],
    [TransformerHTML, TransformerJSONObject, TransformerJSONString, TransformerMarkdown],
    [DigitaleHeldenTiptapBold, DigitaleHeldenTiptapItalic, DigitaleHeldenTiptapUnderline, DigitaleHeldenTiptapStrike],
    [DigitaleHeldenTiptapTextAlign],
    [Link],
    [Spacer],
    [DigitaleHeldenTiptapHistory],
    [
      {
        ...Highlight,
        buttons: ({ editor }) => {
          const newColorButtons = (globalSettings?.colors ?? []).map(color => {
            return {
              name: `toggleHighlight${color}`,
              icon: 'droplet',
              color: '#fff',
              background: color,
              action: () => editor.chain().focus().toggleHighlight({ color }).run(),
            };
          });

          return newColorButtons;
        },
      },
    ],
    // mein custom shit
    [TagMarkExtension, IconNodeExtension, InsertHTMLExtension, SuperscriptExtension],
  ];
};

export const getDefaultDubniumBuildifyExtensions = async ({
  imageExtension,
}: {
  imageExtension?: BuildifyExtension<any>;
}): Promise<BuildifyExtension<any>[]> => {
  const globalSettings = await new Promise<Maybe<BuildifyGlobalSettings>>((res, rej) => {
    subscribeBuildifyGlobalSettings(DigitaleHeldenBuildifyGlobalSettings._class)({
      colors: 1,
    }).subscribe(settings => {
      if (!settings) return rej('settings not found');
      return res(settings as BuildifyGlobalSettings);
    });
  });

  const textExtension = TextExtension(buildifyDefaultTiptapExtensions(customParagraph('text'), globalSettings));
  const iconExtension = BuildifyCampusIconExtension;

  return [
    // - Layout
    // Flex
    BuildifyCampusContainerFlexBackgroundExtension,
    BuildifyCampusContainerFlexExtension,
    BuildifyCampusContainerFlexLineExtension,
    // Grid
    BuildifyCampusContainerGridExtension,
    BuildifyCampusContainerGridFourExtension,
    BuildifyCampusContainerGridThreeExtension,
    BuildifyCampusContainerGridTwoExtension,
    // Section
    BuildifyCampusSectionExtension,

    // - Schriften
    textExtension,
    BuildifyCampusH1Extension(buildifyDefaultTiptapExtensions(customParagraph('h1'), globalSettings)),
    BuildifyCampusH2Extension(buildifyDefaultTiptapExtensions(customParagraph('h2'), globalSettings)),
    BuildifyCampusH3Extension(buildifyDefaultTiptapExtensions(customParagraph('h3'), globalSettings)),
    BuildifyCampusH4Extension(buildifyDefaultTiptapExtensions(customParagraph('h4'), globalSettings)),

    BuildifyCampusSpacingExtension,
    iconExtension,
    BuildifyCampusIconTextExtension({ textExtension, iconExtension }),
    BuildifyCampusListExtension({ textExtension, iconExtension }),
    BuildifyCampusAccordeonExtension(textExtension),
    BuildifyCampusTagExtension({
      textExtension: TextExtension(
        buildifyDefaultTiptapExtensions(customParagraph('text', { fontSize: '14px' }), globalSettings),
      ),
      iconExtension,
    }),
    BuildifyCampusButtonExtension,

    // - Medien
    BuildifyCampusVimeoExtension,
    BuildifyCampusYoutubeExtension,
    ...(imageExtension ? [imageExtension] : []),

    // - Formulare
    BuildifyCampusLeadSignupFormularExtension,
    BuildifyCampusSupportFormularExtension,
  ];
};
