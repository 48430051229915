import { constructValue } from '@adornis/baseql/entities/construct.js';
import { registerMutation } from '@adornis/baseql/metadata/register.js';
import { getRawCollection } from '@adornis/baseql/server/collections.js';
import { getContactByEmailCOQL } from '@adornis/digitale-helden-shared/api/Contact/getContactByEmail.js';
import { ActionTokenData } from '@adornis/digitale-helden-shared/db/ActionTokenData.js';
import { LASUser } from '@adornis/digitale-helden-shared/db/las-user.js';
import { setUserOnContext } from '@adornis/users/server/baseqlContextHelper.js';
import { hashPassword } from '@adornis/users/server/password.js';
import { DateTime } from 'luxon';
import { upsertContact } from '../../contact/mutations/upsertContact.js';

export const setPasswordWithActionToken = registerMutation({
  type: () => String,
  operationName: 'setPasswordWithActionToken',
  resolve: (tokenID: string, password: string) => {
    return async () => {
      const token = await ActionTokenData.getByID<ActionTokenData>(tokenID)(ActionTokenData.allFields);

      if (!token) throw new Error('Token ist invalide');
      if (token.used) throw new Error('token already used');
      if (token.accountCreated) throw new Error('Token wurde bereits verwendet!');

      LASUser.passwordValidator({ value: password, key: 'password', target: null as any });

      let userId = '';

      const contact = await getContactByEmailCOQL(token.contact?.email ?? token.email)({
        id: 1,
        email: 1,
        hasAcademyAccount: 1,
      });
      if (!contact) throw new Error('Token invalide');

      contact.hasAcademyAccount = true;
      await upsertContact(contact)({ id: 1 });

      const collection = await getRawCollection<LASUser>(LASUser._collectionName);

      let existingUser = await collection.findOne<LASUser>({
        $or: [
          { email: { $regex: new RegExp(`^${contact.email.replace(/([^\w]{1})/g, '\\$1')}$`, 'i') } },
          { username: { $regex: new RegExp(`^${contact.email.replace(/([^\w]{1})/g, '\\$1')}$`, 'i') } },
        ],
      });

      if (existingUser) existingUser = constructValue(existingUser);

      if (!existingUser && !token.createAccount) throw new Error('Fehler');

      // create Account if needed
      if (!existingUser) {
        userId = await LASUser.registerUser({
          username: contact.email,
          email: contact.email,
          password,
          additionalFields: { zohoID: contact.id },
        });
        token.accountCreated = true;
      } else {
        await collection.updateOne({ _id: existingUser._id }, { $set: { password: hashPassword(password) } });

        existingUser.zohoID = contact.id!;
        userId = existingUser._id;

        try {
          await existingUser.save();
        } catch (err) {
          throw new Error('nasdjahsd');
        }
      }

      token.used = true;
      token.usedAt = DateTime.now();
      await token.save();
      await setUserOnContext({ _id: userId, _class: LASUser._class });
    };
  },
  params: [
    { name: 'tokenID', type: () => String },
    { name: 'password', type: () => String },
  ],
});
