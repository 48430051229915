import type { Styles } from '@adornis/ass/style.js';
import { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element.js';
import { css } from '@adornis/chemistry/directives/css.js';
import '@adornis/chemistry/elements/components/x-icon.js';
import PatternCardGreen from 'client/assets/Pattern-Card-Green.svg';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

/**
 * @element d-card
 *
 * @attribute hover - set this to get mouse hover effects
 */
@customElement('d-card')
export class DubniumCard extends ChemistryLitElement {
  @property({ attribute: true, reflect: true }) type: 'primary' | 'secondary' | 'image' = 'primary';

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          display: 'block',
          padding: '25px',
          backgroundColor: this.type === 'primary' ? this.colors.tone.neutralAlabster : this.colors.tone.whitePrimary,
          borderRadius: this.sizes.borderRadiusSecondary,
          width: 'fit-content',
        },
        ':host([type="image"])': {
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url('${PatternCardGreen as string}')`,
        },
        ':host([hover]:hover)': {
          backgroundColor: this.colors.accent.shade(300),
          cursor: 'pointer',
          userSelect: 'none',
        },
      },
    ] as Styles[];
  }

  override render() {
    return html`
      <d-flex class="card" ${css({ gap: '1rem' })}>
        <slot></slot>
      </d-flex>
    `;
  }
}
