import type { Maybe } from '@adornis/base/utilTypes.js';
import { Entity, Field } from '@adornis/baseql/decorators.js';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity.js';
import { AcademyRoles } from '@adornis/digitale-helden-shared/db/enums.js';
import { validate } from '@adornis/validation/decorators.js';
import { ValidationError } from '@adornis/validation/errors/ValidationError.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';
import { validateEmail, validateEmailRepeat, validateNonOptional } from '../_forms/db/helpers.js';

@Entity()
export class RegistrationData extends AdornisEntity {
  static override _class = 'RegistrationData';

  @validate(nonOptional())
  @Field(type => String)
  salutation!: string;

  @validate(nonOptional())
  @Field(type => String)
  firstName!: string;

  @validate(nonOptional())
  @Field(type => String)
  lastName!: string;

  @validate(options => {
    validateNonOptional({ key: options.key, value: options.value });
    validateEmail({ key: options.key, value: options.value });
  })
  @Field(type => String)
  email!: string;

  @validate(options => {
    validateNonOptional({ key: options.key, value: options.value });
    validateEmailRepeat({ key: options.key, email: options.target.email, emailRepeat: options.value });
  })
  @Field(type => String)
  emailRepeat!: string;

  @validate(nonOptional())
  @Field(type => Boolean)
  hasAcceptedPrivacy!: boolean;

  @validate(nonOptional())
  @Field(type => String)
  academyRole!: string;

  @validate(options => {
    if (options.target.academyRole === AcademyRoles.SONSTIGES && !options.value)
      throw new ValidationError('Geben Sie eine Akademie-Rolle an..', {
        key: options.key,
        translationKey: 'validation_non_optional',
      });
  })
  @Field(type => String)
  otherAcademyRole?: Maybe<string>;

  @validate(nonOptional())
  @Field(type => String)
  state!: string;

  @validate(nonOptional())
  @Field(type => String)
  country!: string;
}
