import '@adornis/chemistry/elements/components/x-empty-placeholder.js';
import '@adornis/chemistry/elements/components/x-flex.js';
import '@adornis/chemistry/elements/components/x-grid.js';
import '@adornis/chemistry/elements/components/x-icon.js';
import '@adornis/chemistry/elements/components/x-loader.js';
import '@adornis/chemistry/elements/components/x-loading-state.js';
import '@adornis/chemistry/elements/components/x-section.js';
import '@adornis/chemistry/elements/components/x-stack.js';
import '@adornis/chemistry/elements/components/x-tabs.js';
import '@adornis/chemistry/elements/components/x-vr.js';
import { createComponentsFromChemistryBase } from '@adornis/chemistry/elements/x-components.js';
import '@adornis/data-view/client/x-data-view-table.js';
import '@adornis/datetime/x-basic-date-picker.js';
import '@adornis/datetime/x-date-picker-dropdown.js';
import '@adornis/dragdrop/x-drag-drop.js';
import '@adornis/file-utils/client/x-image.js';
import '@adornis/forms/x-input.js';
import '@adornis/forms/x-radiogroup.js';
import '@adornis/internationalization/client/language-picker/x-country-picker.js';
import '@adornis/popover/x-dropdown-multiselection.js';
import '@adornis/popover/x-dropdown-selection.js';
import './animations';
import './components';
import './fonts';
import './global-styles.js';
import './theme';

import '@adornis/data-view/client/x-data-view-card-list.js';
import '@adornis/data-view/client/x-data-view-table.js';
import '@adornis/data-view/client/x-data-view.js';

import '@adornis/chemistry/elements/components/x-card.js';
import '@adornis/datetime/x-basic-date-picker.js';
import '@adornis/datetime/x-date-picker.js';

// tiptap
import '@adornis/tiptap/client/components/x-tiptap-action-bar';
import '@adornis/tiptap/client/components/x-tiptap-action-bar-dropdown';
import '@adornis/tiptap/client/x-tiptap.js';

// buildify
import '@adornis/buildify/client/components/x-buildify-border-picker.js';
import '@adornis/buildify/client/components/x-buildify-border-radius-picker.js';
import '@adornis/buildify/client/components/x-icon-button.js';
import '@adornis/buildify/client/editors/x-buildify-inline-editor.js';
import '@adornis/buildify/client/extensions/CustomContentExtension.js';

// drawer
import '@adornis/dialog/x-drawer-panel.js';

// shared theme
import '@adornis/digitale-helden-shared/client/theme';
import '@adornis/digitale-helden-shared/client/theme/headings_text';

createComponentsFromChemistryBase(
  'x-flex',
  'x-loader',
  'x-tabs',
  'x-tab',
  'x-image',
  'x-grid',
  'x-dropdown-selection-content',
  'x-date-picker-dropdown-content',
  'x-country-picker',
  'x-radiobutton',
  'x-radiogroup',
  'x-loading-state',
  'x-date-picker-dropdown',
  'x-drag-drop',
  'x-tiptap',
  'x-tiptap-action-bar',
  'x-tiptap-action-bar-dropdown',

  // dataview
  'x-data-view',
  'x-data-view-card-list',
  'x-data-view-table',

  // date
  'x-basic-card',
  'x-basic-date-picker',

  // buildify
  'x-buildify-inline-editor',
  'x-icon-button',
  'x-custom-content-toolbar-items',

  // dialog
  'x-drawer-panel',

  // stack
  'x-stack',
  'x-stack-breadcrumb',
);
