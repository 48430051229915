import { registerQuery } from '@adornis/baseql/metadata/register.js';
import { context } from '@adornis/baseql/server/context.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { Product } from '@adornis/digitale-helden-shared/db/product/Product.js';
import { CurrentUserInfo } from '@adornis/users/db/currentUserInfo.js';
import { AdornisRoleToContextsWrapper } from '@adornis/users/db/roleToContextsWrapper.js';
import { DateTime } from 'luxon';
import { LASUser } from '../../las-user.js';
import { Permissions } from '../../permissions.js';
import { getAllProducts } from './getAllProducts.js';

//* Resolver
const getAllExpiredProductsForInvestorResolver = () => {
  return async (gqlFields: BaseQLSelectionSet<Product>) => {
    if (context.serverContext) throw new Error('only client context allowed here');
    const user = await CurrentUserInfo.getMyself<LASUser>()({ _id: 1, roles: AdornisRoleToContextsWrapper.allFields });
    if (!user) return [];
    if (!user.hasRole(Permissions.UserRoles.INVESTOR)) return [];

    const filterProduct = (product: Product): boolean => {
      // check if the product is expired, but not disabled
      const isExpired =
        product.isHiddenProduct !== true &&
        product.isInactive !== true &&
        product.dontVisualize !== true &&
        (product.hasNoSalesDate || (product.salesStartDate < DateTime.now() && product.salesEndDate < DateTime.now()));
      return isExpired;
    };
    const allProducts = await getAllProducts()(gqlFields);
    const expiredProducts = allProducts.filter(product => filterProduct(product));
    return expiredProducts;
  };
};

//* Query
export const getAllExpiredProductsForInvestor = registerQuery({
  type: () => [Product],
  operationName: 'getAllExpiredProductsForInvestor',
  resolve: getAllExpiredProductsForInvestorResolver,
});
