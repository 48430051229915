import { Entity, Field } from '@adornis/baseql/decorators.js';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity.js';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact.js';
import { GroupContactRelation } from '../Relations/GroupContactRelation.js';

@Entity()
export class GroupContactRelationData extends AdornisEntity {
  static override _class = 'GroupContactRelationData';

  @Field(type => Contact) contact!: Contact;
  @Field(type => GroupContactRelation) relation!: GroupContactRelation;
}
