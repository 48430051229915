import { registerQuery } from '@adornis/baseql/metadata/register.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { LEAD_BIMAP, LEAD_ZOHO_FIELDS, Lead } from '@adornis/digitale-helden-shared/db/Lead.js';
import { makeZohoCOQLRequest } from '@adornis/digitale-helden-shared/server/zoho/api.js';

export const getLeadByEmail = registerQuery({
  type: () => Lead,
  operationName: 'getLeadByEmail',
  resolve: (email: string) => {
    return async (gqlFields: BaseQLSelectionSet<Lead>) => {
      const result = await makeZohoCOQLRequest({
        filter: `(${LEAD_ZOHO_FIELDS.EMAIL} = '${email}')`,
        gqlFields,
        moduleBiMap: LEAD_BIMAP,
        moduleName: Lead.ZOHO_MODULE,
      });

      if (!result?.data?.[0]) return null;
      const resultData = result.data[0];
      const deserializedLead = Lead.deserializeZoho(resultData);
      return deserializedLead;
    };
  },
  params: [{ name: 'email', type: () => String }],
});
