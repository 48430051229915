import { A } from '@adornis/base/env-info.js';
import { Entity, Field } from '@adornis/baseql/decorators.js';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity.js';
import { MongoEntity } from '@adornis/baseql/entities/mongoEntity.js';

@Entity()
export class Settings extends MongoEntity {
  static override _class = 'Settings';
  static override _collectionName = 'settings';

  @Field(type => [SettingsField]) registerCoursesFields!: SettingsField[];
}

@Entity()
export class SettingsField extends AdornisEntity {
  static override _class = 'SettingsField';

  @Field(type => String, { default: v => v ?? A.getGloballyUniqueID() }) _id!: string;

  @Field(type => String) name!: string;
  @Field(type => String) id!: string;
}
