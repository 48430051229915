import { XChip } from '@adornis/chemistry/elements/components/x-chip.js';
import '@adornis/chemistry/elements/components/x-icon.js';
import { customElement } from 'lit/decorators.js';

/**
 * @element d-chip
 */
@customElement('d-chip')
export class DubniumChip extends XChip {
  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          color: this.colors.white,
          borderRadius: this.sizes.borderRadiusSecondary,
          alignContent: 'center',
        },
        ':host([tone=subtle])': {
          color: this.colors.white,
        },
        ':host([tone=success])': {
          color: this.colors.white,
        },
        ':host([tone=error])': {
          color: this.colors.white,
        },
        ':host([info])': {
          borderRadius: '50px',
          background: this.colors.tone.infoBackground,
          color: this.colors.tone.infoText,
        },
      },
    ];
  }
}
