import { XButton } from '@adornis/chemistry/elements/components/x-button.js';
import { customElement } from 'lit/decorators.js';

/**
 * @element d-nav-button
 */
@customElement('d-nav-button')
export class DubniumNavButton extends XButton {
  constructor() {
    super();
    this.mode = 'text';
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          borderRadius: this.sizes.borderRadiusSecondary,
        },
        ':host d-link': {
          textDecoration: 'none',
        },
        ':host([mode=text])': {
          borderBottomLeftRadius: '0',
          borderBottomRightRadius: '0',
          padding: '0.7rem 1rem',
          transition: 'background-color 250ms',
        },
        ':host([mode=text]:hover)': {
          backgroundColor: this.colors.primary,
          color: this.colors.white,
        },
        ':host d-text': {
          fontSize: '1rem',
        },
      },
    ];
  }

  protected override stylesModeActionAndOutline = () => ({});

  protected override visualStyles = () => ({
    '#innerwrapper': { padding: `${this.spacing.md} ${this.spacing.md}` },
  });

  protected override get ctaColor() {
    return this.colors.accent;
  }
}
