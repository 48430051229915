import type { Maybe } from '@adornis/base/utilTypes.js';
import { Arg, Entity, Field, Mutation } from '@adornis/baseql/decorators.js';
import type { PartialEntityData } from '@adornis/baseql/entities/types.js';
import { Payment } from '@adornis/payments/db/payments.js';
import { validate } from '@adornis/validation/decorators.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';
import { DateTime } from 'luxon';
import { type UpdateResult } from 'mongodb';
import { createOrderByPayment } from '../../../_api/order/mutations/createOrderByPayment.js';
import { sendOrderConfirmationMail } from '../../../_api/order/mutations/sendOrderConfirmationMail.js';
import { upsertOrder } from '../../../_api/order/mutations/upsertOrder.js';
import { Order } from '../../../db/Order.js';
import { ContactCompanyRelation } from '../../../db/Relations/ContactCompanyRelation.js';
import { assertExisting } from '../../../db/assert/utils.js';
import { ContactCompanyPermission, ContactPosition } from '../../../db/enums.js';
import { LASAccountingTransaction } from '../LASAccountingTransaction.js';
import { LASPaymentFormData } from './LASPaymentFormData.js';
import { LASPaymentRecipient } from './LASPaymentRecipient.js';

@Entity()
export class LASPayment extends Payment {
  static override _class = 'LASPayment';
  static override _collectionName = 'payments';

  @validate(nonOptional())
  @Field(type => String)
  geschaeftsvorfallID!: string;

  @validate(nonOptional())
  @Field(type => LASPaymentRecipient, { default: v => v ?? new LASPaymentRecipient({}) })
  recipient!: LASPaymentRecipient;

  @Field(type => LASPaymentFormData)
  formData?: LASPaymentFormData;

  @Field(type => Order)
  orderBuffer?: Maybe<Order>;

  static override async afterUpdate(
    oldEntity: PartialEntityData<LASPayment>,
    newEntity: LASPayment,
    result: UpdateResult,
  ) {
    await super.afterUpdate(oldEntity, newEntity, result);

    // Sync status with accounting
    if (oldEntity.status !== newEntity.status) {
      switch (newEntity.status) {
        case 'created':
          // solved in create
          break;
        case 'paid':
          try {
            const formData = newEntity.formData;

            const { SignupNewMentoringForm } = await import(
              '../../../_forms/_new-mentoring-form/db/SignupNewMentoringForm'
            );

            // mentoring
            if (formData instanceof SignupNewMentoringForm) {
              return this.handleAfterUpdateMentoringForm(newEntity)();
            }
          } catch {}

          return this.handleAfterUpdateAutomaticForm(newEntity)();
          break;
        case 'expired':
          // wo is ma geld?
          break;
        case 'canceled':
        case 'failed':
          // storno?
          break;
      }
    }
  }

  @Mutation(type => String)
  static handleAfterUpdateAutomaticForm(@Arg('payment', type => LASPayment) payment: LASPayment) {
    return async () => {
      // create order in zoho
      const order = await createOrderByPayment(payment._id)({
        id: 1,
      });

      const userID = assertExisting(payment.userReference.userID, 'userID is missing on payment');
      const beleg = await LASAccountingTransaction.createAccountingTransactionFromPayment(payment._id, userID);

      // send order confirmation mail
      await sendOrderConfirmationMail(order.id, beleg._id)();
    };
  }

  @Mutation(type => String)
  static handleAfterUpdateMentoringForm(@Arg('payment', type => LASPayment) payment: LASPayment) {
    return async () => {
      const { LASPaymentDirectPay } = await import('./DirectPay/LASPaymentDirectPay');

      if (payment instanceof LASPaymentDirectPay) {
        if (!payment.orderBuffer) throw new Error('no order buffer on payment found');

        payment.orderBuffer.paymentDate = DateTime.now();
        const order = await upsertOrder(payment.orderBuffer)({ id: 1, buyerContactId: 1, buyerCompanyId: 1 });

        // Firmen Permission setzen
        const contactCompanyRelation = new ContactCompanyRelation({
          contactId: order.buyerContactId,
          companyId: order.buyerCompanyId,
          permissions: [ContactCompanyPermission.ADMIN],
          positions: [ContactPosition.ANSPRECHPARTNERIN],
        });
        await ContactCompanyRelation.upsertContactCompanyRelation(contactCompanyRelation)({ id: 1 });

        const userID = assertExisting(payment.userReference.userID, 'userID is missing on payment');
        const beleg = await LASAccountingTransaction.createAccountingTransactionFromPayment(payment._id, userID);

        // send order confirmation mail
        await sendOrderConfirmationMail(order.id, beleg._id)();
      }
    };
  }
}
