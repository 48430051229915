import { Entity, Field } from '@adornis/baseql/decorators.js';
import { BaseIconText } from '@adornis/buildify/db/extensions/BaseIconText.js';
import { validate } from '@adornis/validation/decorators.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';
import { DubniumBaseIcon } from './DubniumBaseIcon.js';

@Entity()
export class DubniumBaseIconText extends BaseIconText {
  static override _class = 'DubniumBaseIconText';

  @validate(nonOptional())
  @Field(type => DubniumBaseIcon, { default: v => v ?? new DubniumBaseIcon({}) })
  override icon!: DubniumBaseIcon;
}
