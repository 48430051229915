import type { Styles } from '@adornis/ass/style.js';
import { fontReset } from '@adornis/fonts/font-resets.js';
import { XH1 } from '@adornis/fonts/fonts';
import { customElement } from 'lit/decorators.js';

@customElement('d-h1')
export class DH1 extends XH1 {
  override styles() {
    return [
      fontReset('NettoOT-Black', 1.5, 400, 1.16, 16),
      {
        fontSize: '16px',
        lineHeight: '24px',

        '@media screen and (min-width: 21.5625em)': {
          ...fontReset('NettoOT-Black', 2.25, 400, 1.05, 16),
          fontSize: '2.25rem',
        },

        '@media screen and (min-width: 40em)': {
          ...fontReset('NettoOT-Black', 2.25, 400, 1.05, 16),
          fontSize: '2.5rem',
        },
        '@media screen and (min-width: 64em)': {
          ...fontReset('NettoOT-Black', 2.25, 400, 1.05, 16),
          fontSize: '2.5rem',
        },
        '@media screen and (min-width: 75em)': {
          ...fontReset('NettoOT-Black', 3.5, 400, 1.1, 16),
          fontSize: '3.5rem',
        },
        '&[inline]': { display: 'inline-block' },
      },
    ] as Styles[];
  }
}
