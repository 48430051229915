import { Entity, Field } from '@adornis/baseql/decorators.js';
import { validate } from '@adornis/validation/decorators.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';
import type Stripe from 'stripe';
import { LASPaymentDirectPay } from './LASPaymentDirectPay.js';

@Entity()
export class LASPaymentKlarna extends LASPaymentDirectPay {
  static override _class = 'LASPaymentKlarna';
  static override _collectionName = 'payments';

  @validate(nonOptional())
  @Field(type => String, { default: v => 'klarna' })
  override paymentType!: Stripe.Checkout.SessionCreateParams.PaymentMethodType;
}
