import type { Styles } from '@adornis/ass/style.js';
import { fontReset } from '@adornis/fonts/font-resets.js';
import { XH2 } from '@adornis/fonts/fonts';
import { customElement } from 'lit/decorators.js';

@customElement('d-h2')
export class DH2 extends XH2 {
  override styles() {
    return [
      fontReset('NettoOT-Black', 1.5, 400, 1.16, 16),
      {
        fontSize: '24px',
        lineHeight: '32px',
        '&[inline]': { display: 'inline-block' },
        '&[highlight]': { color: this.colors.accent },
        color: this.colors.tone.black,
      },
    ] as Styles[];
  }
}
