import { SupportTopic } from './enums.js';

export const supportFormularTranslations = {
  topic: {
    [SupportTopic.GENERAL_QUESTION]: ['Allgemeine Anfrage', '#english'],
    [SupportTopic.INTEREST_IN_PRODUCT]: ['Interesse am Produkt', '#english'],
    [SupportTopic.TECHNICAL_ISSUE]: ['Technisches Problem', '#english'],
  },
  fieldValidationMessage: {
    topic: ['Thema', '#english'],
    message: ['Nachricht', '#english'],
    hasAcceptedPrivacy: ['Datenschutz akzeptieren', '#english'],
    productID: ['Produkt', '#english'],
    salutation: ['Anrede', '#english'],
    firstName: ['Vorname', '#english'],
    lastName: ['Nachname', '#english'],
    email: ['E-Mail', '#english'],
    emailRepeat: ['E-Mail wiederholen', '#english'],
  },
};
