import { Geschaeftsvorfall } from '@adornis/accounting/api/geschaeftsvorfall.js';
import type { ChosenProduct } from '@adornis/payments/db/chosenProduct.js';
import type { ProcessorInfo } from '@adornis/payments/db/processorInfo.js';
import { UserReference } from '@adornis/payments/db/userReference.js';
import { DateTime } from 'luxon';
import type Stripe from 'stripe';
import type { PaymentMethod } from '../../../_forms/_new-mentoring-form/db/SignupNewMentoringForm.js';
import type { Order } from '../../../db/Order.js';
import { LASPayment } from './LASPayment.js';
import type { LASPaymentFormData } from './LASPaymentFormData.js';
import type { LASPaymentRecipient } from './LASPaymentRecipient.js';

export abstract class LASPaymentFactory {
  public static async createPayInstance({
    tax,
    formData,
    recipient,
  }: {
    tax: number;
    recipient: LASPaymentRecipient;
    formData: LASPaymentFormData;
  }) {
    const geschaefsvorfall = new Geschaeftsvorfall({});
    const geschaeftsvorfallID = await geschaefsvorfall.create();

    return new LASPayment({
      tax,
      formData,
      recipient,
      geschaeftsvorfallID,
    });
  }

  public static async createAndPersist({
    paymentType,
    products,
    amount,
    formData,
    recipient,
    paymentClass,
    order,
    geschaeftsvorfallID,
    processorInfo,
    userReference,
  }: {
    paymentType: Stripe.Checkout.SessionCreateParams.PaymentMethodType | PaymentMethod.ON_ACCOUNT;
    products: ChosenProduct[];
    amount: number;
    recipient: LASPaymentRecipient;
    formData?: LASPaymentFormData;
    paymentClass?: typeof LASPayment;
    order?: Order;
    geschaeftsvorfallID?: string;
    processorInfo?: ProcessorInfo;
    userReference?: UserReference;
  }) {
    if (!geschaeftsvorfallID) {
      const geschaefsvorfall = new Geschaeftsvorfall({});
      const geschaeftsvorfallIDCreated = await geschaefsvorfall.create();
      geschaeftsvorfallID = geschaeftsvorfallIDCreated;
    }

    if (!paymentClass) paymentClass = LASPayment;

    const payment = new paymentClass({
      // @ts-expect-error
      paymentType,
      products,
      amount,
      geschaeftsvorfallID,
      formData,
      recipient,
      order,
      // @ts-expect-error
      createdAt: DateTime.now(),
      mode: 'payment',
      status: 'created',
      processorInfo,
      userReference,
    });
    console.log('payment to create', payment);
    const paymentID = await payment.create();
    payment._id = paymentID;
    return payment;
  }
}
