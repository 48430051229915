import { A } from '@adornis/base/env-info.js';
import { registerMutation } from '@adornis/baseql/metadata/register.js';
import { ActionTokenData, TokenAction } from '@adornis/digitale-helden-shared/db/ActionTokenData.js';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact.js';
import { doubleOptInTempate } from '@adornis/digitale-helden-shared/mailing/double-opt-in.js';
import { mailer } from '@adornis/digitale-helden-shared/server/communication.js';
import { Recipient } from '@adornis/mails/db/recipients.js';
import { Message } from '@adornis/mails/server/communication.js';
import { CampusRoute, PathToCampusRoute } from '../../../_routing/db/enums.js';

export const sendContactDOIMail = registerMutation({
  type: () => Boolean,
  operationName: 'sendContactDOIMail',
  resolve: (contact: Contact, createAccount: boolean, redirectURL?: string) => {
    return async () => {
      const token = new ActionTokenData({ action: TokenAction.DOI, contact, createAccount });
      const tokenId = await token.create();
      const accessLink = A.absoluteUrl(
        PathToCampusRoute(CampusRoute.ACTION),
        new URLSearchParams({ tokenId: tokenId }),
      );

      await mailer.sendMail(
        await Message.compose({
          subject: 'E-Mail bestätigen.',
          html: doubleOptInTempate({
            accessLink,
            firstName: contact.firstName ?? '',
            lastName: contact.lastName ?? '',
            createAccount,
            redirectURL,
          }),
        }),
        new Recipient([contact.email]),
      );
    };
  },
  params: [
    { name: 'contact', type: () => Contact },
    { name: 'createAccount', type: () => Boolean },
    { name: 'redirectURL', type: () => String },
  ],
});
