import type { Maybe } from '@adornis/base/utilTypes.js';
import { Entity, Field } from '@adornis/baseql/decorators.js';
import { AdornisFilter } from '@adornis/filter/AdornisFilter.js';
import type { SupportedFileType } from '../../_buildify/client/components/las-file-explorer.js';

@Entity()
export class FileFilter extends AdornisFilter {
  static override _class = 'FileFilter';

  @Field(type => String) fileName: Maybe<string>;
  @Field(type => [String]) fileExtensions: Maybe<Array<SupportedFileType>>;
}
