import { registerQuery } from '@adornis/baseql/metadata/register.js';
import { getContactByEmailCOQL } from '@adornis/digitale-helden-shared/api/Contact/getContactByEmail.js';

export const getContactEmailStatusByEmail = registerQuery({
  type: () => String,
  operationName: 'getContactEmailStatusByEmail',
  resolve: (email: string) => {
    return async () => {
      const contact = await getContactByEmailCOQL(email)({ id: 1, email: 1, emailStatus: 1 });
      return contact?.emailStatus;
    };
  },
  params: [{ name: 'email', type: () => String }],
});
