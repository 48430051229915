import { injectAttributesOf } from '@adornis/chemistry/directives/inject-attributes.js';
import { XDropdownSearchSelection } from '@adornis/popover/x-dropdown-search-selection.js';
import { TranslationController } from '@adornis/translation-core/client/translation-controller.js';
import { html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { live } from 'lit/directives/live.js';
import { ref } from 'lit/directives/ref.js';
import type { LASTranslationDictionary } from '../../../db/translation.js';
import './d-input.js';

/**
 * @element d-dropdown-search-selection
 */
@customElement('d-dropdown-search-selection')
export class DubniumDropdownSearchSelection<T> extends XDropdownSearchSelection<T> {
  @state() private readonly _translation = new TranslationController<LASTranslationDictionary>(this);

  // @ts-expect-error intentional "wrong" override
  override get emptyState() {
    return this._searchString.value
      ? (this._translation.translate('dropdown_search_selection', 'empty_results') as unknown as string)
      : (this._translation.translate('dropdown_search_selection', 'no_search') as unknown as string);
  }

  protected override _trigger() {
    return html`
      <d-input
        ${injectAttributesOf(this, ['tabindex'])}
        ${ref(this._inputReference)}
        .value=${live(this._getInputValue())}
        @value-changed=${(e: CustomEvent) => {
          if (this._searchString.value !== e.detail.value) {
            this._searchString.next(e.detail.value);
          }
          if (this.stringSuggestionOnly && this.value.value !== e.detail.value) {
            this.value.next(e.detail.value);
          }
        }}
        @icon-click=${() => this.dispatchEvent(new CustomEvent('icon-click'))}
        @cleared=${() => this._clear()}
        @focus=${(e: Event) => this._onFocus(e)}
        @blur=${(e: Event) => this._onBlur(e)}
        icon=${this._icon()}
      ></d-input>
    `;
  }

  override set emptyState(value: string) {}

  constructor() {
    super();
    this['placeholder-mode'] = 'hide';
  }

  protected override _icon() {
    return 'magnifying-glass';
  }
}
