import { type Maybe } from '@adornis/base/utilTypes.js';
import { RXController } from '@adornis/chemistry/controllers/RXController.js';
import '@adornis/chemistry/elements/components/x-flex.js';
import '@adornis/chemistry/elements/components/x-icon.js';
import { DesignSystem } from '@adornis/chemistry/elements/theming/design.js';
import { FormField } from '@adornis/formfield/form-field.js';
import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './d-icon.js';
import './fonts';

/**
 * @element d-toggle-button
 */
@customElement('d-toggle-button')
export class DubniumToggleButton extends FormField<boolean> {
  @property() label = '';
  @property({ type: Boolean }) reverse = false;

  override value = new RXController<Maybe<boolean>>(this, false);

  override render() {
    return html`
      <x-flex
        tabindex="0"
        horizontal
        crossaxis-center
        space="sm"
        ?reverse=${this.reverse}
        @keyup=${(e: KeyboardEvent) => {
          if (e.key === 'Enter') this._toggle();
        }}
        @click=${() => this._toggle()}
      >
        <div togglewrapper flex-none ?toggled=${this.value.value}>
          <x-flex toggle center crossaxis-center ?toggled=${this.value.value}>
            ${this.disabled ? html` <d-icon> close </d-icon>` : nothing}
          </x-flex>
        </div>
        ${this.label ? html` <d-text> ${this.label} </d-text>` : nothing}
      </x-flex>
    `;
  }

  private _toggle() {
    if (!this.disabled) {
      this.value.next(!this.value.value);
      this.dispatchEvent(new CustomEvent('value-picked', { detail: { value: this.value.value } }));
    }
  }

  override styles() {
    return [
      {
        ':host': { cursor: 'pointer', userSelect: 'none' },
        'div[togglewrapper]': {
          boxSizing: 'border-box',
          display: 'block',
          padding: '3px',
          borderRadius: `calc(${this.spacing.sm} / 2)`,
          background: this.colors.neutral.shade(200),
          width: `calc(2.5 * (${this.spacing.md} + 4px) + 3px + 3px)`,
          transition: 'background .12s',
          '&[toggled]': {
            background: this.colors.accent,
          },
        },
        '[toggle]': {
          background: this.colors.white,
          height: `calc(${this.spacing.md} + 4px)`,
          width: `calc(${this.spacing.md} + 4px)`,
          borderRadius: '50%',
          transition: 'transform .12s, backgroundColor .12s',
          '&[toggled]': {
            background: this.colors.white,
            transform: 'translateX(150%)',
            [`${DesignSystem.prefix}-icon`]: {
              color: this.colors.on.accent,
            },
          },
          [`${DesignSystem.prefix}-icon`]: {
            color: this.colors.on.black,
            fontSize: this.baseFontSize,
          },
        },
        ':host([disabled])': { '[toggle]': { opacity: '.5' } },
      },
    ];
  }
}
