import { Size } from '@adornis/buildify/client/globals/enums.js';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types.js';
import { DubniumBaseContainerFlexWithBackground } from 'db/buildify/container/DubniumBaseContainerFlexWithBackground';
import { BuildifyCampusContainerFlexExtension } from './BuildifyCampusContainerFlexExtension.js';

export const BuildifyCampusContainerFlexBackgroundExtension: BuildifyExtension<DubniumBaseContainerFlexWithBackground> =
  {
    class: DubniumBaseContainerFlexWithBackground,
    toolbar: { icon: 'diagram-cells', text: { default: 'Flex mit Hintergrund' } },
    render(instance, { defaultValues, isSubelement, dropzoneBefore, dropzoneAfter }) {
      return BuildifyCampusContainerFlexExtension.render(instance, {
        defaultValues: {
          ...(defaultValues ? defaultValues : {}),
          padding: {
            [Size.DESKTOP]: '32px',
            [Size.TABLET]: '24px',
            [Size.MOBILE]: '20px',
          },
          backgroundColor: {
            global: '#f7f7f7',
          },
        },
        isSubelement,
        dropzoneBefore,
        dropzoneAfter,
      });
    },
    editor: BuildifyCampusContainerFlexExtension.editor,
    group: 'Layout',
  };
