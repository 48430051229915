import '@adornis/buildify/db';
import '@adornis/file-utils/db';
import '@adornis/filter/AdornisFilter.js';
import '@adornis/users/db/currentUserInfo.js';

import '@adornis/digitale-helden-shared/db/Contact.js';
import '@adornis/digitale-helden-shared/db/Lead.js';
import '@adornis/digitale-helden-shared/db/errors/ProductNotActiveError.js';
import '@adornis/digitale-helden-shared/db/las-user.js';
import '@adornis/digitale-helden-shared/db/product/Product.js';
import '../_forms/db/ContactFormData.js';
import './Certificate.js';
import './Company.js';
import './Digimember.js';
import './Funding.js';
import './Group.js';
import './Meeting.js';
import './Order.js';
import './OrderManagementFilter.js';
import './Relations/ContactCompanyRelation.js';
import './Relations/GroupContactRelation.js';
import './Settings.js';
import './UptimeCheck.js';
import './enums.js';
import './files/FileFilter.js';
import './files/LASFile.js';

// buildify
import '@adornis/digitale-helden-shared/db';
import '../_buildify/db';

// forms
import '@adornis/digitale-helden-shared/formulars/_lead-signup-form/db/FormularLeadSignup.js';
import '@adornis/digitale-helden-shared/formulars/_support-form/db/FormularSupport.js';
import '@campus/forms/_new-mentoring-form/db/SignupNewMentoringForm';
import '@campus/forms/db/FormularDefinition';
import '@campus/forms/db/SignupCompanyData';
import '@campus/forms/db/SignupContactData';
import './RegistrationData.js';

// print
import '@adornis/print/db/print.js';

// accounting & payments
import '../_accounting/db/AccountingListDataFilter.js';
import '../_accounting/db/DATEVDebitorenExportJob.js';
import '../_accounting/db/LASAccountingDeposit.js';
import '../_accounting/db/LASAccountingListData.js';
import '../_accounting/db/LASAccountingTransaction.js';
import '../_accounting/db/LASProduct.js';
import '../_accounting/db/LASReceipts.js';
import '../_accounting/db/Payments/LASPayment.js';
import '../_accounting/db/XRechnung.js';

import '@adornis/accounting/api/beleg.js';
import '@adornis/accounting/api/buchung-filter.js';
import '@adornis/accounting/api/buchung.js';
import '@adornis/accounting/api/datev/buchungen.js';
import '@adornis/accounting/api/zusammenhangskomponente.js';

import '@adornis/counter/counter.js';

// standalone queries
import '../_api';

// redeem codes
import '../redeem-codes/db/index.js';
