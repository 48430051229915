import { YoutubeExtension } from '@adornis/buildify/client/extensions/YoutubeExtenions.js';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types.js';

export const BuildifyCampusYoutubeExtension: BuildifyExtension = {
  ...YoutubeExtension,
  toolbar: {
    icon: 'video',
    text: YoutubeExtension.toolbar.text,
  },
};
