import { registerQuery } from '@adornis/baseql/metadata/register.js';
import { getCollection } from '@adornis/baseql/server/collections.js';
import { LASUser } from '@adornis/digitale-helden-shared/db/las-user.js';
import { hashPassword } from '@adornis/users/server/password.js';

export const checkUserPassword = registerQuery({
  type: () => Boolean,
  operationName: 'checkUserPassword',
  resolve: (userID: string, password: string) => {
    return async () => {
      const passwordHash = hashPassword(password);
      const collection = await getCollection<LASUser>(LASUser._class);
      const result = await collection.findOne({ _id: userID, password: passwordHash });

      if (result) return true;
      else return false;
    };
  },
  params: [
    { name: 'userID', type: () => String },
    { name: 'password', type: () => String },
  ],
});
