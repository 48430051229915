import type { Maybe } from '@adornis/base/utilTypes.js';
import { Entity, Field } from '@adornis/baseql/decorators.js';
import { BaseButton } from '@adornis/buildify/db/extensions/BaseButton.js';
import { validate } from '@adornis/validation/decorators.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';
import { Link } from './Link.js';

@Entity()
export class DubniumBaseButton extends BaseButton {
  static override _class = 'DubniumBaseButton';

  @Field(type => Boolean) isDigitalEmergency: Maybe<boolean>;

  @Field(type => Boolean) invert: Maybe<boolean>;

  @validate(nonOptional())
  @Field(type => Link, { default: v => v ?? new Link({}) })
  link!: Link;

  @Field(type => String) alignHorizontal: Maybe<string>;
  @Field(type => String) alignVertical: Maybe<string>;
}
