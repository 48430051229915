import { A } from '@adornis/base/env-info.js';
import { registerMutation } from '@adornis/baseql/metadata/register.js';
import { getContactByEmailCOQL } from '@adornis/digitale-helden-shared/api/Contact/getContactByEmail.js';
import { ActionTokenData, TokenAction } from '@adornis/digitale-helden-shared/db/ActionTokenData.js';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact.js';
import { mailer } from '@adornis/digitale-helden-shared/server/communication.js';
import { Recipient } from '@adornis/mails/db/recipients.js';
import { Message } from '@adornis/mails/server/communication.js';
import { CampusRoute, PathToCampusRoute } from '../../../_routing/db/enums.js';
import { passwordResetTemplate } from '../../../db/mail-templates/password-reset.js';

export const sendForgotPasswordMail = registerMutation({
  type: () => String,
  operationName: 'sendForgotPasswordMail',
  resolve: (email: string, createAccount: boolean, hasBeenOrderedByAdmin: boolean = false) => {
    return async () => {
      const contact = await getContactByEmailCOQL(email)(Contact.allFields);

      if (!contact) throw new Error('Ein(e) Nutzer*in mit dieser E-Mail Adresse existiert nicht.');

      const token = new ActionTokenData({ email, action: TokenAction.CHANGE_PASSWORD, createAccount });
      const tokenId = await token.create();
      const accessLink = A.absoluteUrl(
        PathToCampusRoute(CampusRoute.ACTION),
        new URLSearchParams({ tokenId: tokenId }),
      );

      await mailer.sendMail(
        await Message.compose({
          subject: 'Passwort vergessen',
          html: passwordResetTemplate(contact, accessLink, hasBeenOrderedByAdmin),
        }),
        new Recipient([email]),
      );
    };
  },
  params: [
    { name: 'email', type: () => String },
    { name: 'createAccount', type: () => Boolean },
    { name: 'hasBeenOrderedByAdmin', type: () => Boolean },
  ],
});
