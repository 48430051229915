import { type ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element.js';
import { RXController } from '@adornis/chemistry/controllers/RXController.js';
import { type ChemistryControllerHost } from '@adornis/chemistry/controllers/chemistry-controller.js';
import type { WindowSizeController } from '@adornis/chemistry/controllers/window-size-controller.js';
import { css } from '@adornis/chemistry/directives/css.js';
import { XSnackbar } from '@adornis/chemistry/elements/components/x-snackbar.js';
import { DesignSystem } from '@adornis/chemistry/elements/theming/design.js';
import type { Renderable } from '@adornis/chemistry/renderable.js';
import { mobile, tablet } from '@adornis/digitale-helden-shared/db/enums.js';
import { type LASUser } from '@adornis/digitale-helden-shared/db/las-user.js';
import { currentUser } from '@adornis/users/client/currentUser.js';
import { html } from 'lit';
import { BehaviorSubject } from 'rxjs';

const topbarItems = new BehaviorSubject<Array<{ name: string; href?: string }>>([]);
export const TopbarController = (host: ChemistryControllerHost) => new RXController(host, topbarItems);

export const CurrentUserController = (host: ChemistryControllerHost) => new RXController(host, currentUser<LASUser>());
export const _currentUserLoading = (host: ChemistryControllerHost) => new RXController(host, currentUser().isLoading);

export function handleError(host: ChemistryLitElement, message: string, element: Element) {
  XSnackbar.show(message);
  if (!element) return;
  element.classList.add('shake');
  element.addEventListener('animationend', () => {
    element.classList.remove('shake');
  });
}

export const isTablet = (size: WindowSizeController) => {
  return (size.width ?? 0) <= tablet && (size.width ?? 0) > mobile;
};
export const isMobile = (size: WindowSizeController) => {
  return (size.width ?? 0) <= mobile;
};
export const divider = html`
  <d-flex
    ${css({ height: '1px', width: '100%', background: DesignSystem.currentTheme.getValue().colors.tone.neutralBase })}
  ></d-flex>
`;
export const dropdownElement = (content: string | Renderable, action: () => void, options?: { color?: string }) => html`
  <d-flex
    ${css({
      padding: `${DesignSystem.currentTheme.getValue().spacing.sm} !important`,
      paddingRight: typeof content === 'string' ? `${DesignSystem.currentTheme.getValue().spacing.xl} !important` : '',
      cursor: 'pointer',
    })}
    @click=${() => action()}
  >
    ${typeof content === 'string'
      ? html`
          <d-text black ${css({ color: options?.color ?? DesignSystem.currentTheme.getValue().colors.tone.black })}>
            ${content}
          </d-text>
        `
      : content}
  </d-flex>
`;
