import { A } from '@adornis/base/env-info.js';
import type { Maybe } from '@adornis/base/utilTypes.js';
import { ID, Int } from '@adornis/baseql/baseqlTypes.js';
import { Arg, Entity, Field, Mutation, Query } from '@adornis/baseql/decorators.js';
import { selectionSet, type BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { type ContactAcademyRole } from '@adornis/digitale-helden-shared/db/enums.js';
import { BiMap } from '@adornis/digitale-helden-shared/db/zoho/BiMap.js';
import { type ZohoRecord } from '@adornis/digitale-helden-shared/db/zoho/types.js';
import { ZohoEntity } from '@adornis/digitale-helden-shared/db/zoho/zoho-entity.js';
import { ZohoModule } from '@adornis/digitale-helden-shared/db/zoho/zoho.js';
import {
  makeZohoAPIRequest,
  makeZohoCOQLRequest,
  upsertZohoRecord,
} from '@adornis/digitale-helden-shared/server/zoho/api.js';
import { DateTime } from 'luxon';
import { OrderGroupContactRelationStatusAlreadyDoneError } from '../../errors/OrderGroupContactRelationStatusAlreadyDoneError.js';
import { GroupContactRole, OrderAttendenceStatus } from '../enums.js';

export enum GROUP_CONTACT_RELATION_ZOHO_FIELDS {
  ID = 'id',
  NAME = 'Name',
  GROUP = 'Gruppe',
  CONTACT = 'Kontakt',
  GROUP_FUNCTION = 'Gruppen_Funktion',
  ACADEMY_ROLE = 'Akademie_Rolle',
  OTHER_ACADEMY_ROLE = 'Sonstige_Akademie_Rolle',
  INACTIVE_SINCE = 'Inaktiv_seit',
  PARTICIPATION_STATUS = 'Teilnahme_Status',
}

export const GROUP_CONTACT_RELATION_BIMAP = new BiMap<string, GROUP_CONTACT_RELATION_ZOHO_FIELDS>([
  ['id', GROUP_CONTACT_RELATION_ZOHO_FIELDS.ID],
  ['groupId', GROUP_CONTACT_RELATION_ZOHO_FIELDS.GROUP],
  ['contactId', GROUP_CONTACT_RELATION_ZOHO_FIELDS.CONTACT],
  ['groupContactRole', GROUP_CONTACT_RELATION_ZOHO_FIELDS.GROUP_FUNCTION],
  ['academyRole', GROUP_CONTACT_RELATION_ZOHO_FIELDS.ACADEMY_ROLE],
  ['otherAcademyRole', GROUP_CONTACT_RELATION_ZOHO_FIELDS.OTHER_ACADEMY_ROLE],
  ['inactiveSince', GROUP_CONTACT_RELATION_ZOHO_FIELDS.INACTIVE_SINCE],
  ['name', GROUP_CONTACT_RELATION_ZOHO_FIELDS.NAME],
  ['status', GROUP_CONTACT_RELATION_ZOHO_FIELDS.PARTICIPATION_STATUS],
]);

export const GROUP_CONTACT_RELATION_FOREIGN_KEYS = [
  GROUP_CONTACT_RELATION_ZOHO_FIELDS.CONTACT,
  GROUP_CONTACT_RELATION_ZOHO_FIELDS.GROUP,
];

@Entity()
export class GroupContactRelation extends ZohoEntity {
  static override _class = 'GroupContactRelation';
  static override ZOHO_MODULE = ZohoModule.GROUP_CONTACT_RELATIONS;
  static override ZOHO_FIELDS = Array.from(GROUP_CONTACT_RELATION_BIMAP.values).join(',');

  static override resolveID(entity: any): string {
    return entity.id;
  }

  static override get allFields() {
    return selectionSet(() => this, 2, ['contact']);
  }

  @Field(type => String) id!: string;
  @Field(type => String) groupId!: string;
  @Field(type => String) contactId!: string;
  @Field(type => String) groupContactRole: Maybe<GroupContactRole>;
  @Field(type => String) academyRole?: ContactAcademyRole;
  @Field(type => String) otherAcademyRole?: string;
  @Field(type => Date) inactiveSince!: Date;
  /** this is a zoho mandatory field and has to be unique */
  @Field(type => String, { default: v => v ?? A.getGloballyUniqueID() }) name!: string;
  @Field(type => String) status!: OrderAttendenceStatus;

  get toFilteredJSON() {
    const fields = {};
    const keys = Array.from(GROUP_CONTACT_RELATION_BIMAP.keys);
    keys.forEach(key => {
      if (key && this.toJSON()[key]) {
        fields[key] = this.toJSON()[key];
      }
    });
    return fields;
  }

  override serializeZoho = (isNew: boolean = false) => {
    const fields = {};
    const keys = Array.from(GROUP_CONTACT_RELATION_BIMAP.keys);
    keys.forEach(key => {
      const keyZoho = GROUP_CONTACT_RELATION_BIMAP.get(key);
      if (keyZoho && (this[key] || typeof this[key] === 'boolean')) {
        if (GROUP_CONTACT_RELATION_FOREIGN_KEYS.includes(keyZoho)) {
          fields[keyZoho] = {
            id: this[key],
          };
        } else {
          if (this[key] instanceof Date) {
            fields[keyZoho] = DateTime.fromJSDate(this[key]).toFormat('yyyy-MM-dd');
          } else {
            fields[keyZoho] = this[key];
          }
        }
      }
    });

    const data: ZohoRecord<any> = {
      data: [fields],
      trigger: ['workflow'],
    };
    return JSON.stringify(data).replace(/"id":[ ]?"([0-9]+)"/g, '"id":$1');
  };

  static override deserializeZoho = (rawData: any) => {
    const fields = {};
    const keys = Array.from(GROUP_CONTACT_RELATION_BIMAP.reverseKeys);
    keys.forEach(key => {
      const keyLAS = GROUP_CONTACT_RELATION_BIMAP.reverseGet(key);
      if (keyLAS) {
        if (GROUP_CONTACT_RELATION_FOREIGN_KEYS.includes(key)) {
          fields[keyLAS] = rawData[key]?.id ?? null;
        } else {
          fields[keyLAS] = rawData[key] ?? null;
        }
      }
    });
    return new GroupContactRelation({
      ...fields,
    });
  };

  @Query(type => GroupContactRelation)
  static updateGroupContactRelation(
    @Arg('oldInstance', type => GroupContactRelation) oldInstance: GroupContactRelation,
    @Arg('newInstance', type => GroupContactRelation) newInstance: GroupContactRelation,
  ) {
    return async (gqlFields: BaseQLSelectionSet<GroupContactRelation>) => {
      if (oldInstance.groupContactRole !== newInstance.groupContactRole && newInstance.groupContactRole) {
        const groupContactRelations = await this.getGroupContactRelationsByGroupIDCOQL(newInstance.groupId)({
          id: 1,
          status: 1,
          groupContactRole: 1,
        });
        const countRelations = (groupContactRelations ?? []).filter(
          relation =>
            relation.status !== OrderAttendenceStatus.ABGEBROCHEN &&
            relation.groupContactRole === newInstance.groupContactRole,
        ).length;

        const { Group } = await import('../Group');
        if (newInstance.groupContactRole === GroupContactRole.GROUP_ADMIN && countRelations >= Group.MAX_ADMINS) {
          throw new Error('limit für admins bereits erreicht');
        }
        if (newInstance.groupContactRole === GroupContactRole.PARTICIPANT && countRelations >= Group.MAX_PARTICIPANTS) {
          throw new Error('limit für teilnehmer bereits erreicht');
        }
      }
      const updatedRelation = await this.upsertGroupContactRelation(newInstance, true)(gqlFields);
      return updatedRelation;
    };
  }

  @Query(type => Int)
  static countGroupContactRelationsByRole(
    @Arg('role', type => String) role: GroupContactRole,
    @Arg('groupID', type => String) groupID: string,
  ) {
    return async () => {
      const endpoint = `coql`;
      const countSelector = `COUNT(${GROUP_CONTACT_RELATION_ZOHO_FIELDS.ID})`;
      const query = `SELECT ${countSelector} FROM ${this.ZOHO_MODULE} WHERE ${GROUP_CONTACT_RELATION_ZOHO_FIELDS.GROUP_FUNCTION} = '${role}' AND ${GROUP_CONTACT_RELATION_ZOHO_FIELDS.GROUP} = '${groupID}' AND ${GROUP_CONTACT_RELATION_ZOHO_FIELDS.PARTICIPATION_STATUS} = ${OrderAttendenceStatus.ABGEBROCHEN}`;

      console.log(query);

      // AND ${GROUP_CONTACT_RELATION_ZOHO_FIELDS.PARTICIPATION_STATUS} != '${OrderAttendenceStatus.ABGEBROCHEN}'

      const result = await makeZohoAPIRequest({
        method: 'post',
        endpoint,
        data: { select_query: query },
        zohoModule: this.ZOHO_MODULE,
        isRawRequest: true,
      });
      const data = result?.data[0];
      if (!data) return 0;
      return data[countSelector] as number;
    };
  }

  @Query(type => String)
  static deleteGroupContactRelationsByIDs(@Arg('ids', type => [String]) ids: string[]) {
    return async () => {
      ids = ids.filter(id => !!id);
      if (ids.length === 0) return;
      const endpoint = `${this.ZOHO_MODULE}?ids=${ids.join(',')}`;
      await makeZohoAPIRequest({ method: 'delete', endpoint, zohoModule: this.ZOHO_MODULE });
    };
  }

  @Mutation(type => GroupContactRelation)
  public static upsertGroupContactRelation(
    @Arg('entity', type => GroupContactRelation) entity: GroupContactRelation,
    @Arg('ignoreExisting', type => Boolean) ignoreExisting: boolean = false,
  ) {
    return async (gqlFields: BaseQLSelectionSet<GroupContactRelation>) => {
      const existingGroupContactRelation = await this.getGroupContactRelationByGroupAndContact(
        entity.groupId,
        entity.contactId,
      )(GroupContactRelation.allFields);

      if (existingGroupContactRelation) {
        if (ignoreExisting) {
          if (existingGroupContactRelation.status === OrderAttendenceStatus.ABGESCHLOSSEN)
            throw new OrderGroupContactRelationStatusAlreadyDoneError();
          entity.id = existingGroupContactRelation.id;
        } else {
          throw new Error('Dieser Kontakt besitzt bereits eine Relation zu dieser Gruppe');
        }
      }

      const zohoID = await upsertZohoRecord(this.ZOHO_MODULE, entity);
      entity.zohoID = zohoID;
      return entity;
    };
  }

  @Query(type => [GroupContactRelation], { requestHandlerMeta: { queryCache: { cachePolicy: 'bypass' } } })
  public static getAllGroupContactRelations() {
    return async (gqlFields: BaseQLSelectionSet<GroupContactRelation>) => {
      const result = await makeZohoCOQLRequest<GroupContactRelation>({
        moduleName: GroupContactRelation.ZOHO_MODULE,
        moduleBiMap: GROUP_CONTACT_RELATION_BIMAP,
        filter: `id != ''`,
        gqlFields,
      });
      if (!result?.data) return [];
      return result.data.map(agt => GroupContactRelation.deserializeZoho(agt));
    };
  }

  @Query(type => [GroupContactRelation])
  static getGroupContactRelationsByGroupsAndContact(
    @Arg('contactId', type => ID) contactId: string,
    @Arg('groupIds', type => [ID]) groupIds: string[],
  ) {
    return async (gqlFields: BaseQLSelectionSet<GroupContactRelation>) => {
      if (!Array.isArray(groupIds) || groupIds.length === 0) return [];

      const result = await makeZohoCOQLRequest<GroupContactRelation>({
        moduleName: GroupContactRelation.ZOHO_MODULE,
        moduleBiMap: GROUP_CONTACT_RELATION_BIMAP,
        filter: `${GROUP_CONTACT_RELATION_ZOHO_FIELDS.GROUP} in ${groupIds.join(',')} AND ${
          GROUP_CONTACT_RELATION_ZOHO_FIELDS.CONTACT
        } = '${contactId}'`,
        gqlFields,
      });
      if (!result?.data) return [];

      const deserializedGroupContactRelations = result.data.map(rawData =>
        this.deserializeZoho(rawData),
      ) as GroupContactRelation[];
      return deserializedGroupContactRelations;
    };
  }

  @Query(type => [GroupContactRelation])
  static getGroupContactRelationsByGroupID(@Arg('groupID', type => ID) groupID: string) {
    return async (gqlFields: BaseQLSelectionSet<GroupContactRelation>) => {
      const result = await makeZohoCOQLRequest({
        filter: `(${GROUP_CONTACT_RELATION_ZOHO_FIELDS.GROUP} = '${groupID}')`,
        gqlFields,
        moduleBiMap: GROUP_CONTACT_RELATION_BIMAP,
        moduleName: this.ZOHO_MODULE,
      });
      if (!result?.data) return [];

      const deserializedGroupContactRelations = result.data.map(rawData =>
        this.deserializeZoho(rawData),
      ) as GroupContactRelation[];

      console.log(deserializedGroupContactRelations);

      return deserializedGroupContactRelations;
    };
  }

  @Query(type => [GroupContactRelation])
  static getGroupContactRelationsByGroupIDCOQL(@Arg('groupID', type => ID) groupID: string) {
    return async (gqlFields: BaseQLSelectionSet<GroupContactRelation>) => {
      if (!groupID) return [];

      const result = await makeZohoCOQLRequest({
        filter: `${GROUP_CONTACT_RELATION_ZOHO_FIELDS.GROUP} = '${groupID}' AND ${GROUP_CONTACT_RELATION_ZOHO_FIELDS.PARTICIPATION_STATUS} not like '${OrderAttendenceStatus.ABGEBROCHEN}'`,
        gqlFields,
        moduleBiMap: GROUP_CONTACT_RELATION_BIMAP,
        moduleName: this.ZOHO_MODULE,
      });
      if (!result?.data) return [];
      const deserialized = result.data.map(rawData => this.deserializeZoho(rawData)) as GroupContactRelation[];

      // hier müssen wir manuell nochmal leider filtern, weil zoho einen so komplexen filter nicht zulässt... wtf
      const filteredResult = deserialized.filter(relation => relation.status !== OrderAttendenceStatus.ABGESCHLOSSEN);

      return filteredResult;
    };
  }

  @Query(type => GroupContactRelation)
  static getGroupContactRelationByGroupAndContact(
    @Arg('groupID', type => ID) groupID: string,
    @Arg('contactID', type => ID) contactID: string,
  ) {
    return async (gqlFields: BaseQLSelectionSet<GroupContactRelation>) => {
      if (!groupID || !contactID) return null;

      const result = await makeZohoCOQLRequest({
        filter: `(Gruppe = '${groupID}') AND (Kontakt = '${contactID}')`,
        gqlFields,
        moduleBiMap: GROUP_CONTACT_RELATION_BIMAP,
        moduleName: this.ZOHO_MODULE,
      });
      if (!result?.data) return null;

      const deserializedGroupContactRelations = result.data.map(rawData =>
        this.deserializeZoho(rawData),
      ) as GroupContactRelation[];
      return deserializedGroupContactRelations[0];
    };
  }

  @Mutation(type => GroupContactRelation)
  static setGroupContactParticipationStatus(
    @Arg('groupContact', type => GroupContactRelation) groupContact: GroupContactRelation,
    @Arg('status', type => String) status: OrderAttendenceStatus,
  ) {
    return async (gqlFields: BaseQLSelectionSet<GroupContactRelation>) => {
      groupContact.status = status;
      return await this.updateGroupParticipant(groupContact)(GroupContactRelation.allFields);
    };
  }

  @Mutation(type => String)
  static removeGroupParticipantRecord(
    @Arg('groupContact', type => GroupContactRelation) groupContact: GroupContactRelation,
  ) {
    return async () => this.removeRecord(groupContact.id)();
  }

  @Mutation(type => GroupContactRelation)
  static updateGroupParticipant(@Arg('groupContact', type => GroupContactRelation) groupContact: GroupContactRelation) {
    return async (gqlFields: BaseQLSelectionSet<GroupContactRelation>) =>
      this.upsertGroupContactRelation(groupContact, true)(gqlFields);
  }
}
