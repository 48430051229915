import { registerMutation } from '@adornis/baseql/metadata/register.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { getContactByEmailCOQL } from '@adornis/digitale-helden-shared/api/Contact/getContactByEmail.js';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact.js';
import { Lead } from '@adornis/digitale-helden-shared/db/Lead.js';
import { UpsertContactOptions } from '@adornis/digitale-helden-shared/db/UpsertContactOptions.js';
import { getDifficultyIndex } from '@adornis/digitale-helden-shared/db/product/enums.js';
import { upsertZohoRecord } from '@adornis/digitale-helden-shared/server/zoho/api.js';
import { getLeadByEmail } from './getLeadByEmail.js';

export const upsertLead = registerMutation({
  type: () => Lead,
  operationName: 'upsertLead',
  resolve: (instance: Lead, options?: UpsertContactOptions) => {
    return async (gqlFields: BaseQLSelectionSet<Lead>) => {
      const existingContact = await getContactByEmailCOQL(instance.email)(Contact.allFields);
      if (existingContact) throw new Error('Es existiert bereits ein Kontakt mit dieser E-Mail-Adresse.');
      const existingLead = await getLeadByEmail(instance.email)(Lead.allFields);
      if (!existingLead) instance.signInDate = new Date();

      // options check methods
      const checkStatus = (oldStatus, newStatus): string => {
        return getDifficultyIndex(newStatus) < getDifficultyIndex(oldStatus) ? oldStatus : newStatus;
      };
      const checkAcademyRoles = (oldRoles: string[], newRoles: string[]): string[] => {
        const allAcademyRoles = new Set<string>([...oldRoles, ...newRoles]);
        return Array.from(allAcademyRoles.values());
      };
      const checkOtherAcademyRole = (oldOthers: string, newOthers: string): string => {
        const otherAcademyRoles = new Set<string>([...oldOthers.split(','), ...newOthers.split(',')]);
        return Array.from(otherAcademyRoles.values())
          .filter(value => !!value)
          .join(',');
      };
      const checkCommunicationCampaigns = (oldCampaigns: string[], newCampaigns: string[]) => {
        const campaigns = new Set<string>([...oldCampaigns, ...newCampaigns]);
        return Array.from(campaigns.values());
      };

      // check options and configure data as needed
      const optionsCheck = async () => {
        if (options && (options.checkStatus || options.checkAcademyRole || options.checkOtherAcademyRole)) {
          if (!options) return;

          if (!existingLead) return;

          if (options.checkStatus) {
            instance.status = checkStatus(existingLead.status, instance.status);
          }

          if (options.checkAcademyRole) {
            instance.academyRoles = checkAcademyRoles(existingLead.academyRoles ?? [], instance.academyRoles ?? []);
          }

          if (options.checkOtherAcademyRole) {
            instance.otherAcademyRole = checkOtherAcademyRole(
              existingLead.otherAcademyRole ?? '',
              instance.otherAcademyRole ?? '',
            );
          }

          if (options.checkCommunicationCampaigns) {
            instance.communicationCampaigns = checkCommunicationCampaigns(
              existingLead.communicationCampaigns ?? [],
              instance.communicationCampaigns ?? [],
            );
          }
        }
      };
      await optionsCheck();

      const id = await upsertZohoRecord(Lead.ZOHO_MODULE, instance);
      instance.id = id;
      return instance;
    };
  },
  params: [
    { name: 'instance', type: () => Lead },
    { name: 'options', type: () => UpsertContactOptions },
  ],
});
