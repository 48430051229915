import type { Maybe } from '@adornis/base/utilTypes.js';
import { Entity, Field } from '@adornis/baseql/decorators.js';
import { Base } from '@adornis/buildify/db/Base.js';
import { validate } from '@adornis/validation/decorators.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';

@Entity()
export class DubniumBaseLeadSignupFormular extends Base {
  static override _class = 'DubniumBaseLeadSignupFormular';

  @Field(type => Boolean) isWebinar?: Maybe<boolean>;
  @Field(type => Boolean) isNewsletter?: Maybe<boolean>;

  @validate(nonOptional())
  @Field(type => [String], { default: v => v ?? [] })
  communicationCampaigns?: Maybe<string[]>;
}
