import { A } from '@adornis/base/env-info.js';
import type { Maybe } from '@adornis/base/utilTypes.js';
import { Arg, Entity, Field, Mutation, Query } from '@adornis/baseql/decorators.js';
import { getCollection, getRawCollection } from '@adornis/baseql/server/collections.js';
import { context } from '@adornis/baseql/server/context.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { validate } from '@adornis/validation/decorators.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';
import { checkPermission } from '../../db/helpers.js';
import { CampusPage } from './CampusPage.js';
import { DubniumPagePublished } from './DubniumPagePublished.js';

@Entity()
export class DubniumPDP extends CampusPage {
  static override _class = 'DubniumPDP';
  static override _collectionName = 'product-detail-pages';

  @validate(nonOptional())
  @Field(type => String)
  productID: Maybe<string>;

  @Field(type => String, { default: v => v ?? A.getGloballyUniqueID() })
  override path: Maybe<string>;

  @Query(type => DubniumPDP)
  static getActivePDPByProduct(@Arg('productID', type => String) productID: string) {
    return async (gqlFields: BaseQLSelectionSet<DubniumPDP>) => {
      const collection = await getCollection<DubniumPDP>(DubniumPDP._class);
      const result = await collection.findOne<DubniumPDP>({ productID });
      if (!result) return null;
      const publishmentForPDP = await DubniumPagePublished.getPublishmentByPage(result._id)({ _id: 1 });
      if (!publishmentForPDP) return null;
      return result;
    };
  }

  @Query(type => String)
  static saveRawPDP(@Arg('instance', type => DubniumPDP) instance: DubniumPDP) {
    return async () => {
      await checkPermission({ context, permission: 'Designer.Edit' });

      const collection = await getRawCollection(DubniumPDP._collectionName);
      const result = await collection.replaceOne({ _id: instance._id }, instance.toJSON());
      return result.upsertedId;
    };
  }

  @Mutation(type => String)
  static publishPDP(@Arg('pageID', type => String) pageID: string) {
    return async () => {
      await checkPermission({ context, permission: 'Designer.Edit' });
      if (context.serverContext) return;
      const page = await DubniumPDP.getByID<DubniumPDP>(pageID)({ _id: 1 });
      if (!page) throw new Error('404: page not found');

      const existingPublishment = await DubniumPagePublished.getPublishmentByPage(pageID)({ _id: 1 });
      if (existingPublishment) throw new Error('es existiert bereits eine Veröffentlichung für diese Seite!');

      const publishment = new DubniumPagePublished({ pageID, publishedBy: context.userID });
      const createdID = await publishment.create();
      return createdID;
    };
  }

  @Query(type => DubniumPDP)
  static deletePDPByID(@Arg('id', type => String) id: string) {
    return async () => {
      await checkPermission({ context, permission: 'Designer.Edit' });

      const collection = await getCollection<DubniumPDP>(DubniumPDP._class);
      await collection.deleteOne({ _id: id });
    };
  }
}
