import { XSection } from '@adornis/chemistry/elements/components/x-section.js';
import PatternGray from 'client/assets/Pattern-Hexagon-Hearts-Gray.svg';
import PatternGreen from 'client/assets/Pattern-Hexagon-Hearts-Green.svg';
import { customElement } from 'lit/decorators.js';

/**
 * @element d-section
 */
@customElement('d-section')
export class DubniumSection extends XSection {
  // @property({ attribute: true, reflect: true }) type: 'primary' | 'secondary' = 'primary';
  // @property({ attribute: true, reflect: true }) image: 'primary' | 'gray' | '' = '';

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          background: 'transparent',

          // '&[withLine])::before': {
          //   content: '" "',
          //   height: '30px',
          //   clear: 'both',Fdele
          //   display: 'block',
          //   maxWidth: '6rem',
          //   margin: '10px 0 10px -25px',
          //   borderTop: `1px solid ${this.colors.primary}`,
          // },
        },
        ':host([image="primary"])': {
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${PatternGreen})`,
        },
        ':host([image="gray"])': {
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${PatternGray})`,
        },
        ':host > d-flex': {
          minHeight: '100%',
          flex: '1',
          margin: '0 auto !important',
        },
      },
    ];
  }
}
