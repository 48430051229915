import { constructValue } from '@adornis/baseql/entities/construct.js';
import { registerQuery } from '@adornis/baseql/metadata/register.js';
import { getRawCollection } from '@adornis/baseql/server/collections.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact.js';
import { getAccessToken } from '@adornis/digitale-helden-shared/db/zoho/GlobalSettings.js';
import { ZOHO_API_URL, requestQueue } from '@adornis/digitale-helden-shared/server/zoho/api.js';
import { DateTime } from 'luxon';
import { LASFile } from '../../../db/files/LASFile.js';

export const getContactProfileImage = registerQuery({
  type: () => LASFile,
  operationName: 'getContactProfileImage',
  resolve: (contactID: string) => {
    return async (gqlFields: BaseQLSelectionSet<LASFile>): Promise<LASFile | null> => {
      if (!contactID) return null;
      const col = await getRawCollection<LASFile>(LASFile._collectionName);
      const existingFileDoc = await col.findOne(
        { 'meta.fileName': { $regex: contactID } },
        { projection: { _id: 1, _class: 1, createdAt: 1 } },
      );

      if (existingFileDoc) {
        const existingFile = constructValue(existingFileDoc);
        if (DateTime.fromJSDate(existingFile.createdAt) > DateTime.now().minus({ minutes: 15 })) {
          return existingFile;
        }
        await existingFile.remove();
      }

      const url = `${ZOHO_API_URL}/${Contact.ZOHO_MODULE}/${contactID}/photo`;

      const token = await getAccessToken(requestQueue).catch(err => {
        throw new Error(`token couldn't be generated: ${err.message as string}`);
      });

      try {
        const requestOptions = (token: string) =>
          ({
            method: 'get',
            url,
            data: '',
            options: {
              headers: {
                Authorization: `Zoho-oauthtoken ${token}`,
              },
            },
          } as any);

        const res = await requestQueue.request(requestOptions(token));

        if (!res.body) return null;
        if (typeof res.body !== 'object') throw new Error('invalid result type');
        // 1. Extrahiere die Werte aus dem Objekt
        const byteArray = Object.values(res.body as Record<number, number>);

        // 2. Erstelle einen Buffer aus dem Array
        const buffer = Buffer.from(byteArray);

        const createdFile = await LASFile.createFromBuffer(buffer, `profile-image-${contactID}.png`);
        return createdFile as LASFile;
      } catch (e) {
        return null;
      }
    };
  },
  params: [{ name: 'contactID', type: () => String }],
});
