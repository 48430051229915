import type { Maybe } from '@adornis/base/utilTypes.js';
import { Entity, Field } from '@adornis/baseql/decorators.js';
import { BaseImage } from '@adornis/buildify/db/extensions/file/BaseImage.js';

@Entity()
export class DubniumBaseImage extends BaseImage {
  static override _class = 'DubniumBaseImage';

  @Field(type => String) alignHorizontal: Maybe<string>;
  @Field(type => String) alignVertical: Maybe<string>;
}
