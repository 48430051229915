import type { Maybe } from '@adornis/base/utilTypes.js';
import { Entity, Field } from '@adornis/baseql/decorators.js';
import { AdornisFilter } from '@adornis/filter/AdornisFilter.js';
import { DateTime } from 'luxon';

@Entity()
export class AccountingListDataFilter extends AdornisFilter {
  static override _class = 'AccountingListDataFilter';

  @Field(type => String) productID?: Maybe<string>;
  @Field(type => String) status?: Maybe<string>;
  @Field(type => String) stripeID?: Maybe<string>;
  @Field(type => String) invoiceNr?: Maybe<string>;
  @Field(type => DateTime) from?: Maybe<DateTime>;
  @Field(type => DateTime) till?: Maybe<DateTime>;
}
