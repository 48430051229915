import { registerMutation } from '@adornis/baseql/metadata/register.js';
import { getContactByIDCOQL } from '@adornis/digitale-helden-shared/api/Contact/getContactByIDCOQL.js';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact.js';
import { LASUser } from '@adornis/digitale-helden-shared/db/las-user.js';
import { Permissions } from '@adornis/digitale-helden-shared/db/permissions.js';
import { GLOBAL_CONTEXT } from '@adornis/users/db/a-roles.js';
import { assignRoleToUser } from '@adornis/users/server/roles.js';
import { upsertContact } from '../../contact/mutations/upsertContact.js';

export const registerUserWithZoho = registerMutation({
  type: () => String,
  operationName: 'registerUserWithZoho',
  resolve: (email: string, password: string, contactData: Contact, isAdmin?: boolean) => {
    return async () => {
      const id = await LASUser.requestRegisterUser(new LASUser({ username: email, email }), password, false)();
      await ensureUserOnZoho(id, contactData);
      if (isAdmin) await assignRoleToUser(id, Permissions.UserRoles.ADMIN, [GLOBAL_CONTEXT]);

      return id;
    };
  },
  params: [
    { name: 'email', type: () => String },
    { name: 'password', type: () => String },
    { name: 'contactData', type: () => Contact },
    { name: 'isAdmin', type: () => Boolean },
  ],
});

async function ensureUserOnZoho(userID: string, contact?: Contact) {
  const user = await LASUser.getByID<LASUser>(userID)({ zohoID: 1 });
  if (!user) throw new Error(`User ${userID} not found`);
  const existingContact = user.zohoID ? getContactByIDCOQL(user.zohoID)({ id: 1, email: 1 }) : null;
  if (existingContact) return;

  if (!contact) throw new Error('Need contact information when creating user on Zoho.');
  const contactID = (await upsertContact(contact)({ id: 1 })).id;
  if (!contactID) throw new Error('Expecting ID to be there after upsert but it isnt.');
  user.zohoID = contactID;
  await user.save();
}
