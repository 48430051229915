import { registerQuery } from '@adornis/baseql/metadata/register.js';
import { Lead } from '@adornis/digitale-helden-shared/db/Lead.js';
import { emptyValue } from '@adornis/digitale-helden-shared/db/enums.js';
import { makeZohoAPIRequest } from '@adornis/digitale-helden-shared/server/zoho/api.js';
import { Meeting } from '../../../db/Meeting.js';

export const deleteLeadById = registerQuery({
  type: () => String,
  operationName: 'deleteLeadById',
  resolve: (leadID: string, newContactID: string) => {
    return async () => {
      const meetings = await Meeting.getMeetingsForLeadID(leadID)(Meeting.allFields);

      for (const meeting of meetings) {
        // change ID's from leadID -> newContactID
        if (meeting.leadCompanyRelation === leadID) {
          meeting.contactRelation = newContactID;
          meeting.leadCompanyRelation = emptyValue;
        }
        for (const participant of meeting.participants ?? []) {
          if (participant.participant === leadID && participant.type === 'lead') {
            participant.participant = newContactID;
            participant.type = 'contact';
          }
        }

        // hier noch meeting abspeichern
        await Meeting.updateMeeting(meeting)();
      }

      const endpoint = `${Lead.ZOHO_MODULE}/${leadID}`;
      await makeZohoAPIRequest({ method: 'delete', endpoint, zohoModule: Lead.ZOHO_MODULE });
    };
  },
  params: [
    { name: 'leadID', type: () => String },
    { name: 'newContactID', type: () => String },
  ],
});
