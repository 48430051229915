import '@adornis/buildify/client/components/x-buildify-permission-selection.js';
import { TextExtension } from '@adornis/buildify/client/extensions/TextExtension.js';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types.js';
import { BaseH2 } from '@adornis/buildify/db/extensions/text/BaseH2.js';
import type { AdornisTiptapExtension } from '@adornis/tiptap/client/extensions/ExtensionBase.js';

export const BuildifyCampusH2Extension = (tiptapExtensions: AdornisTiptapExtension[][]): BuildifyExtension<BaseH2> => ({
  class: BaseH2,
  toolbar: { icon: 'h2', text: { default: 'H2' } },
  group: 'Schriften',
  render: TextExtension(tiptapExtensions).render,
  editor: TextExtension(tiptapExtensions).editor,
});
