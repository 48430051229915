import { registerQuery } from '@adornis/baseql/metadata/register.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { PRODUCT_BIMAP, PRODUCT_ZOHO_FIELDS, Product } from '@adornis/digitale-helden-shared/db/product/Product.js';
import { makeZohoCOQLRequest } from '@adornis/digitale-helden-shared/server/zoho/api.js';

//* Resolver
const getProductByIDResolver = (zohoID: string) => {
  return async (gqlFields: BaseQLSelectionSet<Product>) => {
    if (!zohoID) return null;
    const result = await makeZohoCOQLRequest({
      moduleName: Product.ZOHO_MODULE,
      moduleBiMap: PRODUCT_BIMAP,
      filter: `${PRODUCT_ZOHO_FIELDS.ID} = '${zohoID}'`,
      gqlFields,
    });
    if (!result?.data?.[0]) return null;
    const data = result.data[0];
    const deserialized = Product.deserializeZoho(data);
    return deserialized;
  };
};

//* Query
export const getProductByID = registerQuery({
  type: () => Product,
  operationName: 'getProductByID',
  resolve: getProductByIDResolver,
  params: [
    { type: () => String, name: 'zohoID' },
    { type: () => Boolean, name: 'activeCheck' },
  ],
});
