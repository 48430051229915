import { registerMutation } from '@adornis/baseql/metadata/register.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { upsertLead } from '@adornis/digitale-helden-shared/api/Lead/upsertLead.js';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact.js';
import { Lead } from '@adornis/digitale-helden-shared/db/Lead.js';
import { UpsertContactOptions } from '@adornis/digitale-helden-shared/db/UpsertContactOptions.js';
import { upsertContact } from '../../contact/mutations/upsertContact.js';

export const upsertLeadOrContact = registerMutation({
  type: () => String,
  operationName: 'upsertLeadOrContact',
  resolve: (entity: Lead, options?: UpsertContactOptions) => {
    return async (gqlFields: BaseQLSelectionSet<any>) => {
      try {
        await upsertLead(entity, options)(Lead.allFields);
      } catch (err) {
        const contact = new Contact({ ...entity.toJSON() });
        await upsertContact(contact, options)(Contact.allFields);
      }
    };
  },
  params: [
    { name: 'entity', type: () => Lead },
    { name: 'options', type: () => UpsertContactOptions },
  ],
});
