export enum ZohoModule {
  LEADS = 'Leads',
  CONTACTS = 'Contacts',
  GROUPS = 'Gruppen',
  COMPANIES = 'Accounts',
  ORDERS = 'Akademie_Bestellungen',
  FUNDING_STRUCTURES = 'Funding_Structures',
  PRODUCTS = 'Products',
  GROUP_CONTACT_RELATIONS = 'Group_Contact_Relations',
  CONTACT_COMPANY_RELATIONS = 'Contact_Company_Relations',
  CONTACT_PRODUCT_RELATIONS = 'Contact_Product_Relations',
  LIMITATION = 'Limitations',
  MEETINGS = 'Events',
  CERTIFICATE = 'Zertifikate',
}
