import { Beleg } from '@adornis/accounting/api/beleg.js';
import { constructValue } from '@adornis/baseql/entities/adornisEntity.js';
import { registerQuery } from '@adornis/baseql/metadata/register.js';
import { getRawCollection } from '@adornis/baseql/server/collections.js';
import { context } from '@adornis/baseql/server/context.js';
import { getContactByIDCOQL } from '@adornis/digitale-helden-shared/api/Contact/getContactByIDCOQL.js';
import { LASUser } from '@adornis/digitale-helden-shared/db/las-user.js';
import { CurrentUserInfo } from '@adornis/users/db/currentUserInfo.js';
import { DateTime } from 'luxon';
import { LASAccountingTransaction } from '../../../_accounting/db/LASAccountingTransaction.js';
import { LASPayment } from '../../../_accounting/db/Payments/LASPayment.js';
import {
  PaymentMethod,
  SignupNewMentoringForm,
} from '../../../_forms/_new-mentoring-form/db/SignupNewMentoringForm.js';
import { Order } from '../../../db/Order.js';
import { ContactCompanyRelation } from '../../../db/Relations/ContactCompanyRelation.js';
import { ContactCompanyPermission, ContactPosition } from '../../../db/enums.js';
import { sendOrderConfirmationMail } from '../mutations/sendOrderConfirmationMail.js';
import { upsertOrder } from '../mutations/upsertOrder.js';
import { createMentoringOrder } from './createMentoringOrder.js';

//* Resolver
const submitMentoringOrderResolver = (instance: SignupNewMentoringForm) => {
  return async () => {
    if (context.serverContext) throw new Error('submit mentoring form is only accessible from client');
    const user = await CurrentUserInfo.getMyself<LASUser>()({ _id: 1, zohoID: 1 });
    if (!user) throw new Error('no user on context was found');
    const contact = await getContactByIDCOQL(user.zohoID)({
      id: 1,
      salutation: 1,
      firstName: 1,
      lastName: 1,
      email: 1,
    });
    if (!contact) throw new Error('no contact was found for user on current context');
    if (!contact.salutation || !contact.firstName || !contact.lastName || !contact.email || !contact.id) {
      throw new Error('contact profile is incomplete. Please add missing fields in your settings.');
    }

    const existingOrderForContact = await Order.getActiveOrderByBuyer(contact.id, instance.productID)({ id: 1 });
    if (existingOrderForContact) {
      console.error('you already bought this product');
      return;
    }

    const order = await createMentoringOrder(instance)(Order.allFields);
    if (!order) throw new Error("order couldn't be generated");

    if (instance.payment && instance.payment.paymentMethod !== PaymentMethod.ON_ACCOUNT) {
      // PayPal, Klarna, Karte
      const rawPaymentCollection = await getRawCollection<LASPayment>(LASPayment._collectionName);
      let payment = await rawPaymentCollection.findOne<LASPayment>({ _id: instance.payment.intentInfo?.paymentID });
      payment = constructValue(payment);
      if (!payment) throw new Error('no payment was found for direct payment method');

      payment.formData = instance;
      payment.orderBuffer = order;
      await payment.save();
      return;
    }

    order.paymentDate = DateTime.now();
    // Alle anderen Möglichkeiten
    const upsertedOrder = await upsertOrder(order)({ id: 1, buyerContactId: 1, buyerCompanyId: 1 });

    // Firmen Permission setzen
    const contactCompanyRelation = new ContactCompanyRelation({
      contactId: upsertedOrder.buyerContactId,
      companyId: upsertedOrder.buyerCompanyId,
      permissions: [ContactCompanyPermission.ADMIN],
      positions: [ContactPosition.ANSPRECHPARTNERIN],
    });
    await ContactCompanyRelation.upsertContactCompanyRelation(contactCompanyRelation)({ id: 1 });

    // Wir holen uns alle Forderungen welche für diese Order erstellt wurden
    const rawForderungenCollection = await getRawCollection(LASAccountingTransaction._collectionName);
    const forderungen = await rawForderungenCollection
      .find<LASAccountingTransaction>({ paymentID: order.paymentID, _class: LASAccountingTransaction._class })
      .toArray();
    const constructedForderungen = forderungen.map(f => constructValue(f) as LASAccountingTransaction);

    // Wir sortieren die Forderungen nach Erstellungsdatum, sodass bei einer geteilten Rechnung
    const forderungenSorted = constructedForderungen.sort(
      (a, b) => a.buchungsDatum.toMillis() - b.buchungsDatum.toMillis(),
    );
    const forderung = forderungenSorted[0];
    if (!forderung || !forderung?.belegID) throw new Error('seems like no forderung was created');
    const beleg = await Beleg.getByID<Beleg>(forderung.belegID)({ _id: 1, belegNummer: 1 });
    if (!beleg || !beleg.belegNummer) throw new Error('beleg not given');

    await sendOrderConfirmationMail(upsertedOrder.id, beleg._id)();
  };
};

//* Query
export const submitMentoringOrder = registerQuery({
  type: () => String,
  operationName: 'submitMentoringOrder',
  resolve: submitMentoringOrderResolver,
  params: [{ type: () => SignupNewMentoringForm, name: 'instance' }],
});
