import { A } from '@adornis/base/env-info.js';
import { Arg, Entity, Field, Mutation, Query, Subscription } from '@adornis/baseql/decorators.js';
import { MongoEntity } from '@adornis/baseql/entities/mongoEntity.js';
import { getCollection, getCollectionHandle, getRawCollection } from '@adornis/baseql/server/collections.js';
import { context } from '@adornis/baseql/server/context.js';
import { selectionSet, type BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { deepCopyBaseArray } from '@adornis/buildify/client/globals/methods.js';
import { validate } from '@adornis/validation/decorators.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';
import { DateTime } from 'luxon';
import { map } from 'rxjs';
import { checkPermission } from '../../db/helpers.js';
import { getCampusBuildifyExtensions } from '../client/helpers.js';
import { CampusPage } from './CampusPage.js';
import { DubniumPDP } from './DubniumPDP.js';

@Entity()
export class DubniumPageDraft extends MongoEntity {
  static override _class = 'DubniumPageDraft';
  static override _collectionName = 'page-drafts';

  static override get allFields() {
    return selectionSet(() => this, 6);
  }

  @validate(nonOptional())
  @Field(type => String)
  pageID!: string;

  @validate(nonOptional())
  @Field(type => CampusPage)
  page!: CampusPage;

  @validate(nonOptional())
  @Field(type => String)
  draftName!: string;

  @validate(nonOptional())
  @Field(type => DateTime, { default: v => v ?? DateTime.now() })
  createdAt!: DateTime;

  @validate(nonOptional())
  @Field(type => String)
  createdBy!: string;

  @Query(type => String)
  static saveRawPageDraft(@Arg('instance', type => DubniumPageDraft) instance: DubniumPageDraft) {
    return async () => {
      await checkPermission({ context, permission: 'Designer.Edit' });

      const collection = await getRawCollection<DubniumPageDraft>(DubniumPageDraft._collectionName);
      const result = await collection.replaceOne({ _id: instance._id }, instance.toJSON());
      return result.upsertedId;
    };
  }

  @Mutation(type => String)
  static uploadDraft(@Arg('draftID', type => String) draftID: string) {
    return async () => {
      await checkPermission({ context, permission: 'Designer.Edit' });

      const rawDraftCollection = await getRawCollection<DubniumPageDraft>(DubniumPageDraft._collectionName);
      const draft = await rawDraftCollection.findOne<DubniumPageDraft>({ _id: draftID });
      if (!draft) throw new Error('404: draft not founde');
      const rawPageCollection = await getRawCollection<CampusPage>(CampusPage._collectionName);
      const page = await rawPageCollection.findOne<CampusPage>({ _id: draft.pageID });
      if (!page) throw new Error('404: page not found');

      page.content = draft.page.content;
      page.updatedAt = DateTime.now();
      page.version++;

      page.name = draft.page.name;
      page.path = draft.page.path;
      page.shortName = draft.page.shortName;
      page.documentType = draft.page.documentType;
      page.productIDs = draft.page.productIDs;
      page.parentPageID = draft.page.parentPageID;

      await rawPageCollection.replaceOne({ _id: page._id }, page);
      await rawDraftCollection.deleteOne({ _id: draftID });
    };
  }

  @Mutation(type => String)
  static uploadDraftOfPDP(@Arg('draftID', type => String) draftID: string) {
    return async () => {
      await checkPermission({ context, permission: 'Designer.Edit' });

      const draft = await DubniumPageDraft.getByID<DubniumPageDraft>(draftID)(DubniumPageDraft.allFields);
      if (!draft) throw new Error('404: draft not founde');
      const page = await DubniumPDP.getByID<DubniumPDP>(draft.pageID)(DubniumPDP.allFields);
      if (!page) throw new Error('404: page not found');

      page.content = draft.page.content;
      page.name = draft.page.name;
      page.productIDs = draft.page.productIDs;
      page.version++;

      await DubniumPDP.saveRawPDP(page)();
      await draft.remove();
    };
  }

  @Query(type => String)
  static createDraftByPage(@Arg('pageID', type => String) pageID: string, @Arg('name', type => String) name: string) {
    return async () => {
      if (context.serverContext) return;
      await checkPermission({ context, permission: 'Designer.Edit' });

      const page = await CampusPage.getByID<CampusPage>(pageID)(CampusPage.allFields);
      if (!page) throw new Error(`404: page for id '${pageID} couldn't be found'`);

      const deepCopiedContent = deepCopyBaseArray({
        content: page.content,
        extensions: await getCampusBuildifyExtensions(),
      });
      console.log('copied deep', deepCopiedContent);

      const draft = new DubniumPageDraft({
        pageID: page._id,
        page: new CampusPage({
          ...page.toJSON(),
          content: deepCopiedContent,
          _id: A.getGloballyUniqueID(),
        }),
        createdBy: context.userID,
        draftName: name,
      });

      return draft.create();
    };
  }

  @Query(type => [DubniumPageDraft])
  static getMyDraftsByPage(@Arg('pageID', type => String) pageID: string) {
    return async (gqlFields: BaseQLSelectionSet<DubniumPageDraft>) => {
      await checkPermission({ context, permission: 'Designer.View' });

      if (context.serverContext) return;
      const collection = await getCollection<DubniumPageDraft>(DubniumPageDraft._class);
      const result = await collection
        .find<DubniumPageDraft>({ pageID, createdBy: context.userID, overwrittenContent: null })
        .toArray();
      return result;
    };
  }

  @Query(type => [DubniumPageDraft])
  static getMyDrafts() {
    return async (gqlFields: BaseQLSelectionSet<DubniumPageDraft>) => {
      await checkPermission({ context, permission: 'Designer.View' });

      if (context.serverContext) return;
      const collection = await getCollection<DubniumPageDraft>(DubniumPageDraft._class);
      const result = await collection
        .find<DubniumPageDraft>({ createdBy: context.userID, overwrittenContent: null })
        .toArray();
      return result;
    };
  }

  @Subscription(type => [DubniumPageDraft])
  static subscribeMyDrafts() {
    return (gqlFields: BaseQLSelectionSet<DubniumPageDraft>) => {
      if (context.serverContext) return;
      return getCollectionHandle<DubniumPageDraft>(DubniumPageDraft._collectionName)
        .watchQuery({
          createdBy: context.userID,
        })
        .pipe(
          map(async data => {
            await checkPermission({ context, permission: 'Designer.View' });
            return data;
          }),
        );
    };
  }
}
