import { A } from '@adornis/base/env-info.js';
import type { Maybe } from '@adornis/base/utilTypes.js';
import { Entity, Field } from '@adornis/baseql/decorators.js';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity.js';
import { Base } from '@adornis/buildify/db/Base.js';
import { DubniumBaseIcon } from '@adornis/digitale-helden-shared/db/buildify/DubniumBaseIcon.js';
import { Link } from '@adornis/digitale-helden-shared/db/buildify/Link.js';
import { validate } from '@adornis/validation/decorators.js';
import { ValidationError } from '@adornis/validation/errors/ValidationError.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';

@Entity()
export class DubniumBaseLinkList extends Base {
  static override _class = 'DubniumBaseLinkList';

  @validate(nonOptional())
  @Field(type => [DubniumBaseLinkListItem], { default: v => v ?? [new DubniumBaseLinkListItem({})] })
  items!: DubniumBaseLinkListItem[];
}

@Entity()
export class DubniumBaseLinkListItem extends AdornisEntity {
  static override _class = 'DubniumBaseLinkListItem';

  @validate(nonOptional())
  @Field(type => String, { default: v => v ?? A.getGloballyUniqueID() })
  _id!: string;

  @Field(type => Boolean) isDigitalEmergency: Maybe<boolean>;

  @Field(type => Boolean)
  hideIcon: Maybe<boolean>;

  @Field(type => DubniumBaseIcon)
  icon: Maybe<DubniumBaseIcon>;

  @validate(nonOptional())
  @Field(type => String)
  title!: string;

  @Field(type => String)
  source: Maybe<string>;

  @Field(type => String) info!: string;

  @validate(options => {
    if (options.target.fileID) return;
    if (!options.value) throw new ValidationError('Link is needed', { key: options.key });
    if (!options.target.link.link) throw new ValidationError('link in link is needed', { key: options.key });
  })
  @Field(type => Link, { default: v => v ?? new Link({}) })
  link!: Link;

  @validate(options => {
    if (options.target.link.link) return;
    if (!options.value) DubniumBaseLinkListItem.setHrefFileError();
  })
  @Field(type => String)
  fileID: Maybe<string>;

  get firstIcon() {
    if (this.icon) return this.icon.icon;
    if (this.link.link) return 'link';
    if (this.fileID) return 'file-circle-info';
    return 'square-question';
  }

  get secondIcon() {
    if (this.link.link) return 'arrow-right-to-bracket';
    if (this.fileID) return 'folder-arrow-down';
    return 'square-question';
  }

  private static setHrefFileError() {
    throw new ValidationError('href or fileID has to be set');
  }
}
