export const leadSignupFormularTranslations = {
  fieldValidationMessage: {
    hasAcceptedPrivacy: ['Datenschutz akzeptieren', '#english'],
    productID: ['Produkt', '#english'],
    salutation: ['Anrede', '#english'],
    firstName: ['Vorname', '#english'],
    lastName: ['Nachname', '#english'],
    email: ['E-Mail', '#english'],
    emailRepeat: ['E-Mail wiederholen', '#english'],
    academyRole: ['Akademie-Rolle', '#english'],
    otherAcademyRole: ['Sonstige Akademie-Rolle', '#english'],
  },
};
