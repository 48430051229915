// Grouping related permissions into a namespace
export namespace Permissions {
  export enum UserRoles {
    SUPER_ADMIN = 'LAS Super-Admin',
    ADMIN = 'LAS Admin',
    INSTITUTION_ADMIN = 'Administrator:in (Institution)',
    GROUP_ADMIN = 'Gruppen-Administrator:in',
    CONTACT_PERSON = 'Ansprechpartner:in (Institution)',
    PARTICIPANT = 'Teilnehmer:in',
    USER = 'Nutzer',
    HELDEN_TEAM = 'Helden Team',
    SUPPORT = 'Support',
    ACCOUNTING = 'Accounting',
    DESIGNER = 'Designer',
    INVESTOR = 'Förderer',
  }

  export const defaultPermissions = ['Users.ChangePassword', 'Users.Impersonate', 'Users.ChangeRoles'];
  export const BuildifyForceViewPermission = 'Buildify.ForceView';
  export type DefaultPermission = typeof defaultPermissions;

  export enum InvestorPermission {
    VIEW = 'Investor.View',
  }

  export enum TeamPermission {
    VIEW = 'Team.View',
  }

  export enum SupportPermission {
    VIEW = 'Support.View',
    EDIT = 'Support.Edit',
  }

  export enum AccountingPermission {
    VIEW = 'Accounting.View',
    EDIT = 'Accounting.Edit',
  }

  export enum DesignerPermission {
    VIEW = 'Designer.View',
    EDIT = 'Designer.Edit',
  }

  export enum SuperAdminOnlyPermission {
    VIEW = 'Admin.View',
    EDIT = 'Admin.Edit',
    VIEW_USERLIST = 'Admin.ViewUserlist',
    CREATE_EDIT_USERS = 'Admin.CreateAndEditUsers',
    EDIT_PERMISSIONS = 'Admin.EditUserPermissions',
  }

  // Extracting constants for various permission groups
  export const INVESTOR_PERMISSIONS = Object.values(InvestorPermission);
  export const TEAM_PERMISSIONS = Object.values(TeamPermission);
  export const SUPPORT_PERMISSIONS = Object.values(SupportPermission);
  export const ACCOUNTING_PERMISSIONS = Object.values(AccountingPermission);
  export const DESIGNER_PERMISSIONS = Object.values(DesignerPermission);
  export const SUPER_ADMIN_ONLY_PERMISSIONS = Object.values(SuperAdminOnlyPermission);

  export const generalPermissions = [
    'User.Stammdaten.View',
    'User.Stammdaten.WriteViaForm',
    'User.Stammdaten.EditRecordViaInterface',
    'User.Stammdaten.DeleteRecord',
    'Institution.Stammdaten.View',
    'Institution.Stammdaten.WriteViaForm',
    'Institution.Stammdaten.EditRecordViaInterface',
    'Institution.Stammdaten.DeleteRecord',
    'PasswordReset.View',
    'PasswordReset.TriggerEmail',
    'Marketing.View',
    'Marketing.UpdateEmailStatus',
    'Marketing.SubscribeUnsubscribeMailinglist',
    'Akademie.ViewOrder',
    'Akademie.DeactivateAndReactivate',
    'Akademie.ViewContent',
    'Group.View',
    'Group.WriteRecord',
    'Group.DeleteRecord',
    'Group.EditPermission',
  ] as const;

  // Consolidating all permissions
  export const allPermissions = [
    ...generalPermissions,
    ...INVESTOR_PERMISSIONS,
    ...DESIGNER_PERMISSIONS,
    ...TEAM_PERMISSIONS,
    ...SUPPORT_PERMISSIONS,
    ...ACCOUNTING_PERMISSIONS,
    ...SUPER_ADMIN_ONLY_PERMISSIONS,
    ...defaultPermissions,
    BuildifyForceViewPermission,
  ] as const;

  export const basicPermissions = [
    'User.Stammdaten.View',
    'User.Stammdaten.WriteViaForm',
    'User.Stammdaten.EditRecordViaInterface',
    'User.Stammdaten.DeleteRecord',
    'PasswordReset.View',
    'PasswordReset.TriggerEmail',
    'Marketing.View',
    'Marketing.UpdateEmailStatus',
    'Marketing.SubscribeUnsubscribeMailinglist',
    'Akademie.ViewContent',
    'Akademie.ViewOrder',
    'Akademie.DeactivateAndReactivate',
  ] as const;

  export type Permission = (typeof allPermissions)[number] | DefaultPermission;
}

// Using extracted constants and types for default roles
export const defaultRoles: Array<{ name: string; permissions: Permissions.Permission[] }> = [
  { name: Permissions.UserRoles.USER, permissions: [...Permissions.basicPermissions, 'Users.ChangePassword'] },
  {
    name: Permissions.UserRoles.GROUP_ADMIN,
    permissions: ['Group.View', 'Group.EditPermission', 'Group.WriteRecord', 'Group.DeleteRecord'],
  },
  { name: Permissions.UserRoles.PARTICIPANT, permissions: [] },
  {
    name: Permissions.UserRoles.INSTITUTION_ADMIN,
    permissions: [
      'Group.View',
      'Group.EditPermission',
      'Group.WriteRecord',
      'Group.DeleteRecord',
      'Institution.Stammdaten.DeleteRecord',
      'Institution.Stammdaten.EditRecordViaInterface',
      'Institution.Stammdaten.WriteViaForm',
      'Institution.Stammdaten.View',
    ],
  },
  {
    name: Permissions.UserRoles.CONTACT_PERSON,
    permissions: [
      'Group.View',
      'Group.EditPermission',
      'Group.WriteRecord',
      'Group.DeleteRecord',
      'Institution.Stammdaten.DeleteRecord',
      'Institution.Stammdaten.EditRecordViaInterface',
      'Institution.Stammdaten.WriteViaForm',
      'Institution.Stammdaten.View',
    ],
  },
  {
    name: Permissions.UserRoles.INVESTOR,
    permissions: [...Permissions.INVESTOR_PERMISSIONS, Permissions.BuildifyForceViewPermission],
  },
  {
    name: Permissions.UserRoles.HELDEN_TEAM,
    permissions: [...Permissions.TEAM_PERMISSIONS, Permissions.BuildifyForceViewPermission],
  },
  {
    name: Permissions.UserRoles.SUPPORT,
    permissions: [...Permissions.SUPPORT_PERMISSIONS, ...Permissions.TEAM_PERMISSIONS],
  },
  {
    name: Permissions.UserRoles.ACCOUNTING,
    permissions: [...Permissions.ACCOUNTING_PERMISSIONS, ...Permissions.TEAM_PERMISSIONS],
  },
  {
    name: Permissions.UserRoles.DESIGNER,
    permissions: [
      ...Permissions.DESIGNER_PERMISSIONS,
      ...Permissions.TEAM_PERMISSIONS,
      Permissions.BuildifyForceViewPermission,
    ],
  },
  {
    name: Permissions.UserRoles.ADMIN,
    permissions: [...Permissions.allPermissions].filter(
      permission => ![...Permissions.SUPER_ADMIN_ONLY_PERMISSIONS].includes(permission),
    ),
  },
  { name: Permissions.UserRoles.SUPER_ADMIN, permissions: [...Permissions.allPermissions] },
];
