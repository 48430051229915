import type { Styles } from '@adornis/ass/style.js';
import { XH4 } from '@adornis/fonts/fonts';
import { customElement } from 'lit/decorators.js';

@customElement('d-h4')
export class DH4 extends XH4 {
  override styles() {
    return [
      ...super.styles(),
      {
        fontFamily: 'NettoOT-Black',
        fontSize: '16px',
        fontWeight: '400',
      },
      {
        '&[inline]': { display: 'inline-block' },
        '&[center]': { textAlign: 'center' },
        '&[white]': { color: '#fff' },
        '&[highlight]': { color: this.colors.accent },
        '&[small]': {
          fontSize: '16px',
          lineHeight: '24px',
        },
      },
      {
        '&[bold]': {
          fontFamily: 'NettoOT-Black',
        },
      },
    ] as Styles[];
  }
}
