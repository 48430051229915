import { registerMutation } from '@adornis/baseql/metadata/register.js';
import { context } from '@adornis/baseql/server/context.js';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact.js';
import { Permissions } from '@adornis/digitale-helden-shared/db/permissions.js';
import { upsertZohoRecord } from '@adornis/digitale-helden-shared/server/zoho/api.js';
import { checkPermission } from '../../../db/helpers.js';
import { ContactProductRelation } from '../../../db/Relations/ContactProductRelation.js';

export const updateContactProductRelationPrioOrder = registerMutation({
  type: () => Contact,
  operationName: 'updateContactProductRelationPrioOrder',
  resolve: (order: string[]) => {
    return async () => {
      await checkPermission({ context, permission: Permissions.DesignerPermission.EDIT });

      for (const [index, id] of order.entries()) {
        const relation = new ContactProductRelation({ id, sortPrio: index });
        await upsertZohoRecord(ContactProductRelation.ZOHO_MODULE, relation);
      }
    };
  },
  params: [{ name: 'order', type: () => [String] }],
});
