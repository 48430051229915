import { registerMutation } from '@adornis/baseql/metadata/register.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact.js';
import { ContactEmailStatus } from '@adornis/digitale-helden-shared/db/enums.js';
import { UpsertContactOptions } from '@adornis/digitale-helden-shared/db/UpsertContactOptions.js';
import { getLASUserByEmail } from '../../user/queries/getUserByEmail.js';
import { getContactEmailStatusByEmail } from '../queries/getContactEmailStatusByEmail.js';
import { sendContactDOIMail } from './sendContactDOIMail.js';
import { upsertContact } from './upsertContact.js';

export const upsertContactAndCheckDOI = registerMutation({
  type: () => Contact,
  operationName: 'upsertContactAndCheckDOI',
  resolve: (instance: Contact, createAccount: boolean, options?: UpsertContactOptions, redirectURL?: string) => {
    return async (gqlFields: BaseQLSelectionSet<Contact>) => {
      try {
        const emailStatus = await getContactEmailStatusByEmail(instance.email)();
        instance.emailStatus = emailStatus;
      } catch {}
      instance = await upsertContact(instance, options)(Contact.allFields);

      const lasAccount = await getLASUserByEmail(instance.email)({ _id: 1 });
      if (instance.emailStatus !== ContactEmailStatus.DOI || (!lasAccount && createAccount)) {
        await sendContactDOIMail(instance, createAccount, redirectURL)();

        if (
          ![ContactEmailStatus.SOI_BOOKINGS, ContactEmailStatus.SOI, ContactEmailStatus.DOI].includes(
            instance.emailStatus as ContactEmailStatus,
          )
        ) {
          instance.emailStatus = ContactEmailStatus.OPT_IN_PENDING;
          instance = await upsertContact(instance, options)(Contact.allFields);
        }
      }

      return instance;
    };
  },
  params: [
    { name: 'instance', type: () => Contact },
    { name: 'createAccount', type: () => Boolean },
    { name: 'options', type: () => UpsertContactOptions },
    { name: 'redirectURL', type: () => String },
  ],
});
