import type { Maybe } from '@adornis/base/utilTypes.js';
import { Int } from '@adornis/baseql/baseqlTypes.js';
import { registerQuery } from '@adornis/baseql/metadata/register.js';
import { getRawCollection } from '@adornis/baseql/server/collections.js';
import { context } from '@adornis/baseql/server/context.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { Permissions } from '@adornis/digitale-helden-shared/db/permissions.js';
import { DateTime } from 'luxon';
import { checkPermission } from '../../db/helpers.js';
import { FundingPermissionRedeemCode, RedeemCode } from './RedeemCode.js';

export const createFundingRedeemCode = registerQuery({
  type: () => RedeemCode,
  operationName: 'createFundingRedeemCode',
  params: [
    { name: 'durationInDays', type: () => Int },
    { name: 'note', type: () => String },
  ],
  resolve: (durationInDays: number, note: string) => {
    return async (gqlFields: BaseQLSelectionSet<RedeemCode>) => {
      await checkPermission({ context, permission: Permissions.SuperAdminOnlyPermission.EDIT });
      const redeemCode = new FundingPermissionRedeemCode({
        createdAt: DateTime.now(),
        expirationDate: DateTime.now().plus({ days: durationInDays }),
        note,
      });

      const rawCodeCollection = await getRawCollection<RedeemCode>(RedeemCode._collectionName);
      let existingCode: Maybe<RedeemCode>;
      do {
        redeemCode.code = generateRedeemCode(6);
        existingCode = await rawCodeCollection.findOne<RedeemCode>({ code: redeemCode.code });
      } while (existingCode);

      const createdID = await redeemCode.create();
      redeemCode._id = createdID;
      return redeemCode;
    };
  },
});

function generateRedeemCode(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
