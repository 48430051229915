import type { Maybe } from '@adornis/base/utilTypes.js';
import { Int } from '@adornis/baseql/baseqlTypes.js';
import { Arg, Entity, Field, Query } from '@adornis/baseql/decorators.js';
import { getCollection } from '@adornis/baseql/server/collections.js';
import { context } from '@adornis/baseql/server/context.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { AdornisFile } from '@adornis/file-utils/db/files.js';
import type { Order } from '../Order.js';
import { checkPermission } from '../helpers.js';
import { FileFilter } from './FileFilter.js';

@Entity()
export class LASFile extends AdornisFile {
  static override _class = 'LASFile';

  @Field(type => String)
  folderID!: Maybe<string>;

  @Query(type => [LASFile])
  static subscribeFilesPaginatedFiltered(
    @Arg('skip', type => Int) skip: number,
    @Arg('limit', type => Int) limit: number,
    @Arg('filter', type => FileFilter) filter: FileFilter,
  ) {
    return async (gqlFields: BaseQLSelectionSet<Order>) => {
      console.log({ skip, limit, filter });
      await checkPermission({ context, permission: 'Designer.View' });
      const collection = await getCollection<LASFile>(LASFile._class);
      const query = {};
      if ((filter.fileExtensions?.length ?? 0) > 0) {
        query['meta.extension'] = { $in: filter.fileExtensions };
      }
      if (filter.fileName) {
        query['meta.fileName'] = { $regex: `${filter.fileName}`, $options: 'i' };
      }
      const result = await collection.find<LASFile>(query, { skip, limit }).toArray();
      return result;
    };
  }
}
