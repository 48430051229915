import { XBuildifyToolbar } from '@adornis/buildify/client/x-buildify-toolbar.js';
import { customElement } from 'lit/decorators.js';

@customElement('d-buildify-toolbar')
export class DubniumBuildifyToolbar extends XBuildifyToolbar {
  protected override get configuration() {
    const superConfiguration = super.configuration;
    superConfiguration.preview.icon = this._consumedMode.value === 'edit' ? 'eyes' : 'gear';
    superConfiguration.switchMinimize.icon = this._isMinimized ? 'maximize' : 'minimize';
    superConfiguration.settings.icon = 'gear';
    superConfiguration.groups.folderOpenIcon = 'folder-open';

    return superConfiguration;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          background: '#fff',
          boxShadow: '4px 4px 40px rgba(0, 0, 0, 0.1)',
        },
      },
    ];
  }
}
