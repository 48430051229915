import type { Maybe } from '@adornis/base/utilTypes.js';
import { Arg, Entity, Field, Query, Subscription } from '@adornis/baseql/decorators.js';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity.js';
import { getRawCollection } from '@adornis/baseql/server/collections.js';
import { watchQuery } from '@adornis/baseql/server/watchQuery.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { switchMap } from 'rxjs';
import { DigitaleHeldenPage } from './DigitaleHeldenPage.js';

@Entity()
export class MarketingPage extends DigitaleHeldenPage {
  static override _class: string = 'MarketingPage';
  static override _collectionName: string = 'marketing-pages';

  @Field(type => Boolean) hideFromGoogleCrawler: Maybe<boolean>;
  @Field(type => SEOMeta) seoMeta: Maybe<SEOMeta>;

  @Query(type => MarketingPage)
  static getPageByPath<T extends MarketingPage = MarketingPage>(@Arg('path', type => String) path: string) {
    return async (gqlFields: BaseQLSelectionSet<T>): Promise<T | null> => {
      const rawCollection = await getRawCollection(MarketingPage._collectionName);
      const page = await rawCollection.findOne<T>({ path });
      return page;
    };
  }

  @Subscription(type => MarketingPage)
  static subscribePageByPath<T extends MarketingPage>(@Arg('path', type => String) path: string) {
    return (gqlFields: BaseQLSelectionSet<T>) => {
      return watchQuery(this._collectionName, { path }).pipe(switchMap(() => this.getPageByPath(path)(gqlFields)));
    };
  }

  @Query(type => [MarketingPage])
  static getMarketingToplevelPages() {
    return async (gqlFields: BaseQLSelectionSet<MarketingPage>): Promise<MarketingPage[]> => {
      const rawCollection = await getRawCollection<MarketingPage>(MarketingPage._collectionName);
      const pages = await rawCollection.find<MarketingPage>({ path: new RegExp(`^/((?!/).)*$`) }).toArray();
      return pages;
    };
  }

  @Query(type => [MarketingPage])
  static getMarketingSubpages(@Arg('path', type => String) path: string) {
    return async (gqlFields: BaseQLSelectionSet<MarketingPage>): Promise<MarketingPage[]> => {
      const rawCollection = await getRawCollection<MarketingPage>(MarketingPage._collectionName);
      const pages = await rawCollection.find<MarketingPage>({ path: new RegExp(`^${path}/([^/]+)$`) }).toArray();
      return pages;
    };
  }

  @Query(type => [MarketingPage])
  static getMarketingPagesBySearch(@Arg('search', type => String) search: Maybe<string>) {
    return async (gqlFields: BaseQLSelectionSet<MarketingPage>): Promise<MarketingPage[]> => {
      if (!search) return [];
      const rawCollection = await getRawCollection<MarketingPage>(MarketingPage._collectionName);
      const pages = await rawCollection
        .find<MarketingPage>({ $or: [{ path: new RegExp(search, 'i') }, { name: new RegExp(search, 'i') }] })
        .toArray();
      return pages;
    };
  }
}

@Entity()
export class SEOMeta extends AdornisEntity {
  static override _class: string = 'SEOMeta';

  @Field(type => String) title: Maybe<string>;
  @Field(type => String) description: Maybe<string>;
}

path: new RegExp(`^/((?!/).)*$`);
path: new RegExp('^/mentorenprogramm/([^/]+)$');
