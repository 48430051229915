import type { ValueEvent } from '@adornis/base/utilTypes.js';
import { css } from '@adornis/chemistry/directives/css.js';
import { FormField } from '@adornis/formfield/form-field.js';
import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { Link } from '../../db/buildify/Link.js';
import '../theme/d-button.js';

@customElement('d-link-editor')
export class DLinkEditor extends FormField<Link> {
  @property({ attribute: 'page-link', type: Boolean }) canLinkWithOtherPage: boolean = false;

  override render() {
    if (!this.value.value) {
      this.value.value = new Link({});
    }

    return html`
      <d-flex space="sm" ${css({ marginTop: this.spacing.sm })}>
        ${this._linkSelection()}
        <d-input
          placeholder="Link"
          .value=${this.value.value.link}
          @value-picked=${(e: ValueEvent<string>) => {
            if (!this.value.value) return;
            this.value.value.link = e.detail.value;
            this.dispatchEvent(new CustomEvent('value-picked', { detail: { value: this.value.value } }));
            this.requestUpdate();
          }}
        ></d-input>

        <d-checkbox
          .label=${'In neuem Fenster öffnen'}
          .value=${this.value.value.target === '_blank'}
          @value-picked=${(e: ValueEvent<string>) => {
            if (!this.value.value) return;
            if (e.detail.value) this.value.value.target = '_blank';
            else this.value.value.target = '_self';

            this.dispatchEvent(new CustomEvent('value-picked', { detail: { value: this.value.value } }));
            this.requestUpdate();
          }}
        ></d-checkbox>
      </d-flex>
    `;
  }

  protected _linkSelection() {
    if (!this.canLinkWithOtherPage) return nothing;
    return html` <d-button> Mit Seite verknüpfen </d-button> `;
  }
}
