import { css } from '@adornis/chemistry/directives/css.js';
import { XEmptyPlaceholder } from '@adornis/chemistry/elements/components/x-empty-placeholder.js';
import { html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import './d-icon.js';
import './fonts';

/**
 * @element d-empty-placeholder
 */
@customElement('d-empty-placeholder')
export class DubniumEmptyPlaceholder extends XEmptyPlaceholder {
  protected override icon() {
    return typeof this.img === 'string' ? html` <d-icon> ${this.img} </d-icon> ` : this.img;
  }

  override textPart() {
    return this.text
      ? typeof this.text === 'string'
        ? html` <d-text ${css({ color: this.colors.neutral })}> ${this.text} </d-text> `
        : this.text
      : nothing;
  }
}
