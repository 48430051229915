import type { Maybe } from '@adornis/base/utilTypes.js';
import { Float, Int } from '@adornis/baseql/baseqlTypes.js';
import { Entity, Field } from '@adornis/baseql/decorators.js';
import { DateTime } from 'luxon';
import { BiMap } from '../zoho/BiMap.js';
import { ZohoType } from '../zoho/enums.js';
import { serializeDateTimeZoho, serializeDateZoho, serializeDefault } from '../zoho/serialize-helper.js';
import { type ZohoRecord } from '../zoho/types.js';
import { ZohoEntity } from '../zoho/zoho-entity.js';
import { ZohoModule } from '../zoho/zoho.js';
import type {
  ProductDifficulty,
  ProductGroupType,
  ProductHKEFunding,
  ProductLearningSituation,
  ProductPaymentModality,
  ProductType,
} from './enums.js';

export enum PRODUCT_ZOHO_FIELDS {
  ID = 'id',
  NAME = 'Product_Name',
  DESCRIPTION = 'Description',
  DIDACTIC_LEARNING_SITUATION = 'Didaktische_Lernsituation',
  HKE_FUNDING = 'HKE_Funding',
  ABO = 'Abo',
  MAX_PARTICIPANTS = 'Max_Teilnehmer',
  CATEGORY = 'Kategorie',
  PAYMENT_MODALITY = 'Payment_Modality',
  PRICE = 'Unit_Price',
  DIFFICULTY = 'Produktklasse',
  LEARNING_SITUATION = 'Teilnehmende_Lernsituation',
  VALID_AT = 'Valid_At',
  VALID_TILL = 'Valid_Till',
  URL_LAS = 'URL_las',
  URL_LAS_ADMIN = 'URL_las_Administration',
  GROUP_TYPE = 'Gruppen_Art',
  SALES_START_DATE = 'Verkaufsstart',
  SALES_END_DATE = 'Verkaufsende',
  EVENT_DATE_TIME = 'Veranstaltungsstart',
  EVENT_DATE_TIME_END = 'Veranstaltungsende',
  HAS_NO_SALES_DATE = 'Keine_Laufzeit',
  TAXES = 'Tax',
  TAXABLE = 'Taxable',
  PRODUCT_CODE = 'Product_Code',
  INACTIVE = 'Inaktiv',
  URL_REGISTER_FORM = 'URL_Produktkatalog',
  IMAGE_S = 'Product_Image_S',
  IMAGE_L = 'Product_Image_ML',
  PDP_IMAGE_DESKTOP = 'PDP_Image_Desktop',
  PDP_IMAGE_MOBILE = 'PDP_Image_Mobile',
  PDP_IMAGE_BANNER = 'PDP_Image_Banner',
  PDP_IMAGE_BANNER_MOBILE = 'PDP_Image_Banner_Mobile',
  LAUFZEIT_IN_TAGEN = 'Laufzeit_in_Tagen',
  TIME_STRING = 'Time_String',
  ZIELGRUPPEN = 'Zielgruppen',
  ZIELGRUPPEN_BESCHREIBUNG = 'Zielgruppen_Beschreibung',
  PRIO_CATALOG = 'Prio_Catalog',
  DONT_VISUALIZE = 'Dont_Visualize',
  TEILNAHMEBEDINGUNGEN = 'Teilnahmebedingungen',
  VERSTECKTES_PRODUKT = 'Verstecktes_Produkt',
  WIDERRUFSRECHT = 'Widerrufsrecht',
  INFO_LIST = 'Info_Liste',
  PDP_DESCRIPTION = 'PDP_Beschreibung',
  PDP_THEMES = 'PDP_Themen',
  LAUFZEIT_BIS = 'Laufzeit_bis',
}

export const PRODUCT_BIMAP = new BiMap<string, PRODUCT_ZOHO_FIELDS>([
  ['id', PRODUCT_ZOHO_FIELDS.ID],
  ['name', PRODUCT_ZOHO_FIELDS.NAME],
  ['type', PRODUCT_ZOHO_FIELDS.CATEGORY],
  ['isAbo', PRODUCT_ZOHO_FIELDS.ABO],
  ['productClass', PRODUCT_ZOHO_FIELDS.DIFFICULTY],
  ['didacticLearningSituation', PRODUCT_ZOHO_FIELDS.DIDACTIC_LEARNING_SITUATION],
  ['HKEFunding', PRODUCT_ZOHO_FIELDS.HKE_FUNDING],
  ['groupType', PRODUCT_ZOHO_FIELDS.GROUP_TYPE],
  ['maxParticipants', PRODUCT_ZOHO_FIELDS.MAX_PARTICIPANTS],
  ['learningSituation', PRODUCT_ZOHO_FIELDS.LEARNING_SITUATION],
  ['paymentModality', PRODUCT_ZOHO_FIELDS.PAYMENT_MODALITY],
  ['salesStartDate', PRODUCT_ZOHO_FIELDS.SALES_START_DATE],
  ['salesEndDate', PRODUCT_ZOHO_FIELDS.SALES_END_DATE],
  ['eventDateTime', PRODUCT_ZOHO_FIELDS.EVENT_DATE_TIME],
  ['eventDateTimeEnd', PRODUCT_ZOHO_FIELDS.EVENT_DATE_TIME_END],
  ['hasNoSalesDate', PRODUCT_ZOHO_FIELDS.HAS_NO_SALES_DATE],
  ['price', PRODUCT_ZOHO_FIELDS.PRICE],
  ['taxes', PRODUCT_ZOHO_FIELDS.TAXES],
  ['requiresTaxes', PRODUCT_ZOHO_FIELDS.TAXABLE],
  ['productCode', PRODUCT_ZOHO_FIELDS.PRODUCT_CODE],
  ['description', PRODUCT_ZOHO_FIELDS.DESCRIPTION],
  ['urlLas', PRODUCT_ZOHO_FIELDS.URL_LAS],
  ['urlLasAdministration', PRODUCT_ZOHO_FIELDS.URL_LAS_ADMIN],
  ['isInactive', PRODUCT_ZOHO_FIELDS.INACTIVE],
  ['urlRegisterForm', PRODUCT_ZOHO_FIELDS.URL_REGISTER_FORM],
  ['imageSmallUrl', PRODUCT_ZOHO_FIELDS.IMAGE_S],
  ['imageLargeUrl', PRODUCT_ZOHO_FIELDS.IMAGE_L],
  ['imagePDPDesktop', PRODUCT_ZOHO_FIELDS.PDP_IMAGE_DESKTOP],
  ['imagePDPMobile', PRODUCT_ZOHO_FIELDS.PDP_IMAGE_MOBILE],
  ['imagePDPBanner', PRODUCT_ZOHO_FIELDS.PDP_IMAGE_BANNER],
  ['imagePDPBannerMobile', PRODUCT_ZOHO_FIELDS.PDP_IMAGE_BANNER_MOBILE],
  ['durationInDays', PRODUCT_ZOHO_FIELDS.LAUFZEIT_IN_TAGEN],
  ['timeString', PRODUCT_ZOHO_FIELDS.TIME_STRING],
  ['targetGroups', PRODUCT_ZOHO_FIELDS.ZIELGRUPPEN],
  ['targetGroupsDescription', PRODUCT_ZOHO_FIELDS.ZIELGRUPPEN_BESCHREIBUNG],
  ['prioCatalog', PRODUCT_ZOHO_FIELDS.PRIO_CATALOG],
  ['dontVisualize', PRODUCT_ZOHO_FIELDS.DONT_VISUALIZE],
  ['conditionsOfParticipation', PRODUCT_ZOHO_FIELDS.TEILNAHMEBEDINGUNGEN],
  ['isHiddenProduct', PRODUCT_ZOHO_FIELDS.VERSTECKTES_PRODUKT],
  ['widerrufsrecht', PRODUCT_ZOHO_FIELDS.WIDERRUFSRECHT],
  ['infoList', PRODUCT_ZOHO_FIELDS.INFO_LIST],
  ['pdpDescription', PRODUCT_ZOHO_FIELDS.PDP_DESCRIPTION],
  ['pdpThemes', PRODUCT_ZOHO_FIELDS.PDP_THEMES],
  ['laufzeitBis', PRODUCT_ZOHO_FIELDS.LAUFZEIT_BIS],
]);

export const PRODUCT_ZOHO_TYPE_DEFS = {
  [PRODUCT_BIMAP.reverseGet(PRODUCT_ZOHO_FIELDS.SALES_START_DATE)!]: ZohoType.DATE,
  [PRODUCT_BIMAP.reverseGet(PRODUCT_ZOHO_FIELDS.SALES_END_DATE)!]: ZohoType.DATE,
  [PRODUCT_BIMAP.reverseGet(PRODUCT_ZOHO_FIELDS.EVENT_DATE_TIME)!]: ZohoType.DATE_TIME,
  [PRODUCT_BIMAP.reverseGet(PRODUCT_ZOHO_FIELDS.EVENT_DATE_TIME_END)!]: ZohoType.DATE_TIME,
  [PRODUCT_BIMAP.reverseGet(PRODUCT_ZOHO_FIELDS.LAUFZEIT_BIS)!]: ZohoType.DATE_TIME,
};

@Entity()
export class Product extends ZohoEntity {
  static override _class = 'Product';
  static override ZOHO_MODULE = ZohoModule.PRODUCTS;
  static override ZOHO_FIELDS = Array.from(PRODUCT_BIMAP.values).join(',');

  static override resolveID(entity: any) {
    return entity.id;
  }

  @Field(type => String) id!: string;
  @Field(type => String) name!: string;
  @Field(type => String) type!: ProductType;
  @Field(type => Boolean) isAbo!: boolean;
  @Field(type => Boolean) isInactive!: boolean;
  @Field(type => String) productClass!: ProductDifficulty;
  @Field(type => String) didacticLearningSituation!: ProductLearningSituation;
  @Field(type => String) HKEFunding!: ProductHKEFunding;
  @Field(type => [String]) groupType!: ProductGroupType[];
  @Field(type => Int) maxParticipants!: number;
  @Field(type => String) learningSituation!: ProductLearningSituation;
  @Field(type => String) paymentModality: Maybe<ProductPaymentModality>;
  @Field(type => DateTime) salesStartDate!: DateTime;
  @Field(type => DateTime) salesEndDate!: DateTime;
  @Field(type => DateTime) eventDateTime!: DateTime;
  @Field(type => DateTime) eventDateTimeEnd!: DateTime;
  @Field(type => Boolean) hasNoSalesDate!: boolean;
  @Field(type => Float) price!: number;
  @Field(type => Float) taxes!: number;
  @Field(type => Boolean) requiresTaxes!: boolean;
  @Field(type => String) productCode!: string;
  @Field(type => String) description!: string;
  @Field(type => String) urlLas?: string;
  @Field(type => String) urlRegisterForm?: string;
  @Field(type => String) urlLasAdministration?: string;
  @Field(type => String) imageSmallUrl?: string;
  @Field(type => String) imageLargeUrl?: string;
  @Field(type => String) imagePDPDesktop?: string;
  @Field(type => String) imagePDPMobile?: string;
  @Field(type => String) imagePDPBanner?: string;
  @Field(type => String) imagePDPBannerMobile?: string;
  @Field(type => String) timeString?: string;
  @Field(type => [String]) targetGroups?: string[];
  @Field(type => String) targetGroupsDescription: Maybe<string>;
  @Field(type => Int) durationInDays?: number;
  @Field(type => Int) prioCatalog?: number;
  @Field(type => Boolean) dontVisualize?: boolean;
  @Field(type => String) conditionsOfParticipation?: Maybe<string>;
  @Field(type => Boolean) isHiddenProduct?: boolean;
  @Field(type => String) widerrufsrecht?: Maybe<string>;
  @Field(type => String) infoList?: Maybe<string>;
  @Field(type => String) pdpDescription?: Maybe<string>;
  @Field(type => String) pdpThemes?: Maybe<string>;
  @Field(type => DateTime) laufzeitBis?: Maybe<DateTime>;

  public isActive() {
    const isActive =
      this.isInactive !== true &&
      this.dontVisualize !== true &&
      (this.hasNoSalesDate || (this.salesStartDate <= DateTime.now() && this.salesEndDate >= DateTime.now()));
    return isActive;
  }

  get toFilteredJSON() {
    const fields = {};
    const keys = Array.from(PRODUCT_BIMAP.keys);
    keys.forEach(key => {
      if (key && this.toJSON()[key]) {
        fields[key] = this.toJSON()[key];
      }
    });
    return fields;
  }

  override serializeZoho = (isNew: boolean = false) => {
    const fields = {};
    const keys = Array.from(PRODUCT_BIMAP.keys);
    keys.forEach(key => {
      const keyZoho = PRODUCT_BIMAP.get(key);
      if (keyZoho && (this[key] || typeof this[key] === 'boolean')) {
        switch (PRODUCT_ZOHO_TYPE_DEFS[key]) {
          case ZohoType.DATE:
            fields[keyZoho] = serializeDateZoho(this[key]);
            break;
          case ZohoType.DATE_TIME:
            fields[keyZoho] = serializeDateTimeZoho(this[key]);
            break;
          default:
            fields[keyZoho] = serializeDefault(this[key]);
        }
      }
    });
    const data: ZohoRecord<any> = {
      data: [fields],
      trigger: ['workflow'],
    };
    return JSON.stringify(data).replace(/"id":[ ]?"([0-9]+)"/g, '"id":$1');
  };

  static override deserializeZoho = (rawData: any) => {
    const fields = {};
    const keys = Array.from(PRODUCT_BIMAP.reverseKeys);
    keys.forEach(key => {
      const keyLAS = PRODUCT_BIMAP.reverseGet(key);
      if (keyLAS) {
        if (keyLAS === 'taxes') {
          switch (rawData[key]?.[0]?.value) {
            case 'Ermäßigter Steuersatz - 7.0 %':
              fields[keyLAS] = 7;
              break;
            case 'USt - 19.0 %':
              fields[keyLAS] = 19;
              break;
          }
        } else {
          switch (PRODUCT_ZOHO_TYPE_DEFS[key]) {
            case ZohoType.DATE_TIME:
              fields[keyLAS] = DateTime.fromJSDate(rawData[key]) ?? null;
              break;
            default:
              fields[keyLAS] = rawData[key] ?? null;
          }
          fields[keyLAS] = rawData[key] ?? null;
        }
      }
    });

    return new Product({
      ...fields,
    });
  };
}
