import type { Maybe } from '@adornis/base/utilTypes';
import { Entity, Field } from '@adornis/baseql/decorators';
import { MongoEntity } from '@adornis/baseql/entities/mongoEntity';
import { validate } from '@adornis/validation/decorators';
import { nonOptional } from '@adornis/validation/functions/nonOptional';
import { DateTime } from 'luxon';

@Entity()
export abstract class RedeemCode extends MongoEntity {
  static override _class = 'RedeemCode';
  static override _collectionName = 'redeem-codes';

  @validate(nonOptional())
  @Field(() => String)
  code!: string;

  @validate(nonOptional())
  @Field(() => DateTime)
  createdAt!: DateTime;

  @validate(nonOptional())
  @Field(() => DateTime)
  expirationDate!: DateTime;

  @Field(() => DateTime)
  redeemedAt?: Maybe<DateTime>;

  @Field(() => String)
  redeemedBy?: Maybe<string>;

  @Field(() => String)
  note?: Maybe<string>;

  get isExpired() {
    return this.expirationDate < DateTime.now();
  }
}

@Entity()
export class FundingPermissionRedeemCode extends RedeemCode {
  static override _class = 'FundingPermissionRedeemCode';
}
