import { SpacingExtension } from '@adornis/buildify/client/extensions/SpacingExtension.js';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types.js';
import type { BaseSpacing } from '@adornis/buildify/db/extensions/BaseSpacing.js';
import '../../../d-button.js';
import '../../../d-input.js';
import '../../../fonts';

export const BuildifyCampusSpacingExtension: BuildifyExtension<BaseSpacing> = {
  class: SpacingExtension.class,
  toolbar: { icon: 'distribute-spacing-vertical', text: { default: 'Abstand' } },
  render(instance, { defaultValues, isSubelement, dropzoneBefore, dropzoneAfter }) {
    return SpacingExtension.render(instance, { defaultValues, isSubelement, dropzoneBefore, dropzoneAfter });
  },
  editor: SpacingExtension.editor,
  group: 'Basics',
};
