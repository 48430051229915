import { Kostenstellenzuweisung } from '@adornis/accounting/api/buchung.js';
import { CurrencyAmount } from '@adornis/accounting/api/currency-amount.js';
import { registerQuery } from '@adornis/baseql/metadata/register.js';
import { context } from '@adornis/baseql/server/context.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { getContactByIDCOQL } from '@adornis/digitale-helden-shared/api/Contact/getContactByIDCOQL.js';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact.js';
import { emptyValue } from '@adornis/digitale-helden-shared/db/enums.js';
import { LASUser } from '@adornis/digitale-helden-shared/db/las-user.js';
import { Product } from '@adornis/digitale-helden-shared/db/product/Product.js';
import { ProductPaymentModality } from '@adornis/digitale-helden-shared/db/product/enums.js';
import { getProductByID } from '@adornis/digitale-helden-shared/db/product/queries/getProductByID.js';
import { ChosenProduct } from '@adornis/payments/db/chosenProduct.js';
import type { Price } from '@adornis/payments/db/prices.js';
import { CurrentUserInfo } from '@adornis/users/db/currentUserInfo.js';
import { DateTime } from 'luxon';
import { DATEV_ACCOUNTS, LASAccountingTransaction } from '../../../_accounting/db/LASAccountingTransaction.js';
import { LASProduct } from '../../../_accounting/db/LASProduct.js';
import { LASReceipt, createInvoiceInformation } from '../../../_accounting/db/LASReceipts.js';
import type { LASPayment } from '../../../_accounting/db/Payments/LASPayment.js';
import { LASPaymentFactory } from '../../../_accounting/db/Payments/LASPaymentFactory.js';
import { LASPaymentFinancing } from '../../../_accounting/db/Payments/LASPaymentFinancing.js';
import { LASPaymentFunding } from '../../../_accounting/db/Payments/LASPaymentFunding.js';
import { LASPaymentRecipient } from '../../../_accounting/db/Payments/LASPaymentRecipient.js';
import { LASPaymentOnAccount } from '../../../_accounting/db/Payments/OnAccount/LASPaymentOnAccount.js';
import { LASPaymentOnAccountSplitted } from '../../../_accounting/db/Payments/OnAccount/LASPaymentOnAccountSplitted.js';
import {
  PaymentMethod,
  SignupNewMentoringForm,
} from '../../../_forms/_new-mentoring-form/db/SignupNewMentoringForm.js';
import { Company } from '../../../db/Company.js';
import { Funding } from '../../../db/Funding.js';
import { Group } from '../../../db/Group.js';
import { Order } from '../../../db/Order.js';
import { CompanyType, OrderAttendenceStatus, OrderStatus } from '../../../db/enums.js';

//* Resolver
const createMentoringOrderResolver = (instance: SignupNewMentoringForm) => {
  return async (gqlFields: BaseQLSelectionSet<Order>) => {
    if (context.serverContext) throw new Error('submit mentoring form is only accessible from client');

    // checks vorab
    if (instance.payment && instance.payment.alternativeAddress && instance.funding.validatedFundingID) {
      throw new Error("you can't have both currently");
    }

    const user = await CurrentUserInfo.getMyself<LASUser>()({ _id: 1, zohoID: 1 });
    if (!user) throw new Error('no user on context was found');
    const contact = await getContactByIDCOQL(user.zohoID)({
      id: 1,
      salutation: 1,
      firstName: 1,
      lastName: 1,
      email: 1,
      customerNumber: 1,
    });
    if (!contact) throw new Error('no contact was found for user on current context');
    if (!contact.salutation || !contact.firstName || !contact.lastName || !contact.email) {
      throw new Error('contact profile is incomplete. Please add missing fields in your settings.');
    }

    // produkt
    const product = await getProductByID(instance.productID)(Product.allFields);
    if (!product) throw new Error('product not found');
    const syncedProduct = await LASProduct.getLASProductByZohoID(product.id)(LASProduct.allFields);
    if (!syncedProduct) throw new Error('synced product was not found');
    const price = syncedProduct.prices.at(0);
    if (!price) throw new Error('synced product has no price');

    // schule
    const school = new Company({
      id: instance.school.id,
      name: instance.school.name,
      street: instance.school.street,
      zip: instance.school.zip,
      city: instance.school.city,
      state: instance.school.state,
      country: instance.school.country,
      schoolType: instance.school.schoolType,
      resetMail: instance.school.resetMail,
      department: instance.school.department,
      website: instance.school.website,
      type: CompanyType.HERO_SCHOOL,
    });

    // vars
    const orderDetails: Partial<Order> = {};

    // 1. Wenn keine Förderung gegeben ist, brauchen wird in jedem Fall eine Payment-Instanz
    await validatePaymentMethod({ instance, contact, user, price, orderDetails, syncedProduct });

    // 1. School Step
    let upsertedSchool = await Company.upsertCompany(school)({ id: 1 });
    const schoolResolved = await Company.getCompanyById(upsertedSchool.id!)({ id: 1, schoolSince: 1 });
    if (schoolResolved && !schoolResolved.schoolSince) {
      schoolResolved.schoolSince = new Date();
      await Company.upsertCompany(schoolResolved)({ id: 1 });
    }
    if (!upsertedSchool) throw new Error("school couldn't be updated");

    orderDetails.buyerContactId = contact.id;
    orderDetails.buyerCompanyId = upsertedSchool.id;

    // 2. Funding Step
    await validateFunding({ instance, school, orderDetails, contact, user, syncedProduct, price });

    // 3. Payment Step
    if (instance.payment) {
      // wenn ein intent angelegt wurde:
      if (instance.payment.intentInfo?.paymentID) orderDetails.paymentID = instance.payment.intentInfo.paymentID;

      // alternative Rechnungsadresse angabe
      if (instance.payment.paymentMethod === PaymentMethod.ON_ACCOUNT) {
        orderDetails.paymentModality = instance.payment.paymentModality;
      } else {
        orderDetails.paymentModality = ProductPaymentModality.TOTAL_BILL;
      }
    }

    // Sonstige Felder etc.

    // gruppe
    const group = new Group({ name: product.name });
    const insertedGroup = await Group.insertGroup(group)({ id: 1 });
    if (!insertedGroup) throw new Error("group couldn't be inserted");
    orderDetails.groupId = insertedGroup.id;

    // meta informationen
    orderDetails.status = OrderStatus.ACTIVE;
    orderDetails.attendanceStatus = OrderAttendenceStatus.ANGEMELDET;
    orderDetails.productId = product.id;
    orderDetails.productConfiguration = product;
    orderDetails.paymentDate = emptyValue;
    orderDetails.expiryDate = product.laufzeitBis?.toJSDate();

    // expiration
    if (product.durationInDays && product.durationInDays !== 0) {
      orderDetails.expiryDate = DateTime.now().plus({ days: product.durationInDays }).toJSDate();
    }

    return new Order({
      ...orderDetails,
    });
  };
};

//* Query
export const createMentoringOrder = registerQuery({
  type: () => Order,
  operationName: 'createMentoringOrder',
  resolve: createMentoringOrderResolver,
  params: [{ type: () => SignupNewMentoringForm, name: 'instance' }],
});
// -----------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------
// HELPERS
// -----------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------
const KOSTENSTELLE_NR = ' ';

const createTransactionAndBeleg = async ({
  contact,
  payment,
  user,
  betrag,
  isInfoOnly,
}: {
  contact: Contact;
  payment: LASPayment;
  user: LASUser;
  betrag?: CurrencyAmount;
  isInfoOnly?: boolean;
}) => {
  const transaction = await createTransaction({ contact, payment, user, betrag });
  await createBeleg(transaction, isInfoOnly);
};

const createTransaction = async ({
  contact,
  payment,
  user,
  betrag,
}: {
  contact: Contact;
  payment: LASPayment;
  user: LASUser;
  betrag?: CurrencyAmount;
}) => {
  const kostenstellen = [new Kostenstellenzuweisung({ kostenstelle: KOSTENSTELLE_NR, anteilig: 1 })];
  const debitorennummer = contact.getDebitorennummer();
  const tax = payment.tax ? Math.round((payment.tax / payment.amount) * 100) : 0;
  const erloesKonto = {
    0: DATEV_ACCOUNTS.ERLOES_0,
    7: DATEV_ACCOUNTS.ERLOES_7,
    // 19: DATEV_ACCOUNTS.ERLOES_19,
  }[tax];
  betrag = betrag
    ? betrag
    : new CurrencyAmount({
        betrag: payment.amount / 100, // convert cent to euro
      });

  if (!erloesKonto) throw new Error('erloeskonto not found');

  const transaction =
    await LASAccountingTransaction.erstelleGeschaeftsvorfallDebitorenForderung<LASAccountingTransaction>(
      debitorennummer,
      erloesKonto,
      {
        userID: user._id,
        kostenstellen,
        paymentID: payment._id,
        betrag,
        committed: true,
      },
      payment.geschaeftsvorfallID,
    );
  const transactionID = await transaction.create();
  transaction._id = transactionID;
  return transaction;
};

const createBeleg = async (transaction: LASAccountingTransaction, isInfoOnly: boolean = false) => {
  const invoiceInformation = isInfoOnly
    ? { belegNummer: 'Infoblatt' }
    : await createInvoiceInformation(KOSTENSTELLE_NR);

  await LASReceipt.createBelegForBuchung(
    LASAccountingTransaction._class,
    transaction._id,
    new LASReceipt({
      ...invoiceInformation,
    }),
  )({
    belegNummer: 1,
  });
};

const validateFunding = async ({
  instance,
  school,
  orderDetails,
  contact,
  user,
  syncedProduct,
  price,
}: {
  instance: SignupNewMentoringForm;
  school: Company;
  orderDetails: Record<string, any>;
  contact: Contact;
  user: LASUser;
  syncedProduct: LASProduct;
  price: Price;
}) => {
  if (!instance.funding || !instance.funding.validatedFundingID) return;
  // 2. Funding Step
  const availableFundings = await Funding.getAvaiableCompanyFundings(school)({
    id: 1,
    fundingPortionPercent: 1,
    name: 1,
    fundingBy: 1,
    isFinancing: 1,
  });
  if (instance.funding.fundingKey) {
    const keyFunding = await Funding.getFundingByCode(
      instance.funding.fundingKey,
      school,
    )({
      id: 1,
      fundingPortionPercent: 1,
      name: 1,
      isFinancing: 1,
    });
    if (keyFunding) {
      availableFundings.push(keyFunding);
    }
  }
  const funding = availableFundings.find(f => f.id === instance.funding.validatedFundingID);
  if (!funding) throw new Error('no available funding was found');
  if (funding.fundingPortionPercent !== 100) throw new Error('funding portion below 100 is not supported yet');

  orderDetails.foerderStructureId = instance.funding.validatedFundingID;

  if (funding.isFinancing) {
    // Finanzierung
    const fundingCompany = await Company.getCompanyById(funding.fundingBy)({
      id: 1,
      street: 1,
      zip: 1,
      city: 1,
      state: 1,
      country: 1,
      name: 1,
    });
    if (!fundingCompany || !fundingCompany.id) throw new Error('funding company not found');

    const recipient = new LASPaymentRecipient({
      salutation: 'empty',
      firstName: 'empty',
      lastName: 'empty',
      email: 'empty',
      street: fundingCompany.street,
      zip: fundingCompany.zip,
      city: fundingCompany.city,
      state: fundingCompany.state,
      country: fundingCompany.country,
      companyName: fundingCompany.name,
    });

    // hier wird nun eine neue Payment Instanz angelegt
    const payment = await LASPaymentFactory.createAndPersist({
      paymentType: PaymentMethod.ON_ACCOUNT,
      amount: price.unitAmount,
      products: [
        new ChosenProduct({
          amount: 1,
          product: syncedProduct,
          priceID: price._id,
        }),
      ],
      formData: instance,
      recipient,
      paymentClass: LASPaymentFinancing,
    });

    orderDetails.paymentID = payment._id;

    await createTransactionAndBeleg({ contact, payment, user });
  } else {
    // Förderung
    const recipient = new LASPaymentRecipient({
      salutation: contact.salutation,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      street: school.street,
      zip: school.zip,
      city: school.city,
      state: school.state,
      country: school.country,
      companyName: school.name,
    });

    // hier wird nun eine neue Payment Instanz angelegt
    const payment = await LASPaymentFactory.createAndPersist({
      paymentType: PaymentMethod.ON_ACCOUNT,
      amount: price.unitAmount,
      products: [
        new ChosenProduct({
          amount: 1,
          product: syncedProduct,
          priceID: price._id,
        }),
      ],
      formData: instance,
      recipient,
      paymentClass: LASPaymentFunding,
    });

    orderDetails.paymentID = payment._id;

    await createTransactionAndBeleg({ contact, payment, user, isInfoOnly: true });
  }
};

const validatePaymentMethod = async ({
  instance,
  contact,
  user,
  price,
  orderDetails,
  syncedProduct,
}: {
  instance: SignupNewMentoringForm;
  contact: Contact;
  user: LASUser;
  price: Price;
  orderDetails: Record<string, any>;
  syncedProduct: LASProduct;
}) => {
  switch (instance.payment?.paymentMethod) {
    case PaymentMethod.KLARNA:
    case PaymentMethod.CARD:
    case PaymentMethod.PAYPAL:
      break;
    case PaymentMethod.ON_ACCOUNT:
      const PaymentClass = {
        [ProductPaymentModality.TOTAL_BILL]: LASPaymentOnAccount,
        [ProductPaymentModality.SPLITTED_BILL]: LASPaymentOnAccountSplitted,
      }[instance.payment.paymentModality];

      const recipient = instance.payment.alternativeAddress
        ? new LASPaymentRecipient({
            salutation: instance.payment.alternativeAddress.salutation,
            firstName: instance.payment.alternativeAddress.firstName,
            lastName: instance.payment.alternativeAddress.lastName,
            email: instance.payment.alternativeAddress.email,
            street: instance.payment.alternativeAddress.street,
            zip: instance.payment.alternativeAddress.zip,
            city: instance.payment.alternativeAddress.city,
            state: instance.payment.alternativeAddress.state,
            country: instance.payment.alternativeAddress.country,
            companyName: instance.payment.alternativeAddress.companyName,
          })
        : new LASPaymentRecipient({
            salutation: contact.salutation,
            firstName: contact.firstName,
            lastName: contact.lastName,
            email: contact.email,
            street: instance.school.street,
            zip: instance.school.zip,
            city: instance.school.city,
            state: instance.school.state,
            country: instance.school.country,
            companyName: instance.school.name,
          });

      // hier wird nun eine neue Payment Instanz angelegt
      const payment = await LASPaymentFactory.createAndPersist({
        paymentType: PaymentMethod.ON_ACCOUNT,
        amount: price.unitAmount,
        products: [
          new ChosenProduct({
            amount: 1,
            product: syncedProduct,
            priceID: price._id,
          }),
        ],
        formData: instance,
        recipient,
        paymentClass: PaymentClass,
      });

      orderDetails.paymentID = payment._id;

      if (instance.payment.paymentModality === ProductPaymentModality.TOTAL_BILL) {
        await createTransactionAndBeleg({ contact, payment, user });
      } else {
        const gesamtBetrag = payment.amount / 100;
        const ersterTeilbetrag = gesamtBetrag / 2;

        const ersterBetragParsed = new CurrencyAmount({
          betrag: ersterTeilbetrag,
        });

        await createTransactionAndBeleg({ contact, payment, user, betrag: ersterBetragParsed });
      }
      break;
    default:
      if (!instance.funding.validatedFundingID) throw new Error('not available to have no payment and no funding');
      break;
  }
};
