import { A } from '@adornis/base/env-info.js';
import { registerMutation } from '@adornis/baseql/metadata/register.js';
import { ActionTokenData, TokenAction } from '@adornis/digitale-helden-shared/db/ActionTokenData.js';
import { Lead } from '@adornis/digitale-helden-shared/db/Lead.js';
import { mailer } from '@adornis/digitale-helden-shared/server/communication.js';
import { Recipient } from '@adornis/mails/db/recipients.js';
import { Message } from '@adornis/mails/server/communication.js';
import { doubleOptInTempate } from '../../mailing/double-opt-in.js';

export const sendLeadDOIMail = registerMutation({
  type: () => String,
  operationName: 'sendLeadDOIMail',
  resolve: (lead: Lead, createAccount: boolean) => {
    return async () => {
      const token = new ActionTokenData({ action: TokenAction.DOI, lead, createAccount });
      const tokenId = await token.create();
      const accessLink = A.absoluteUrl('/campus/action', new URLSearchParams({ tokenId }));

      await mailer.sendMail(
        await Message.compose({
          subject: 'E-Mail bestätigen.',
          html: doubleOptInTempate({
            accessLink,
            firstName: lead.firstName ?? '',
            lastName: lead.lastName ?? '',
            createAccount,
          }),
        }),
        new Recipient([lead.email]),
      );
    };
  },
  params: [
    { name: 'lead', type: () => Lead },
    { name: 'createAccount', type: () => Boolean },
  ],
});
