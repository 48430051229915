import { type Maybe } from '@adornis/base/utilTypes.js';
import { Entity, Field } from '@adornis/baseql/decorators.js';
import { ContactCategory, emptyValue } from '@adornis/digitale-helden-shared/db/enums.js';
import { BiMap } from '@adornis/digitale-helden-shared/db/zoho/BiMap.js';
import { ZohoType } from '@adornis/digitale-helden-shared/db/zoho/enums.js';
import { ZohoEntity } from '@adornis/digitale-helden-shared/db/zoho/zoho-entity.js';
import { ZohoModule } from '@adornis/digitale-helden-shared/db/zoho/zoho.js';
import { genericSerializeZoho } from '@adornis/digitale-helden-shared/server/zoho/interface-zoho-adornis.js';
import { emailRegexCheck } from '@adornis/users/db/validators.js';
import { validate } from '@adornis/validation/decorators.js';
import { ValidationError } from '@adornis/validation/errors/ValidationError.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';

export const generateRandomPassword = () => {
  return String.fromCharCode(...[...new Array(10)].map(() => Math.floor(Math.random() * 25) + 65)) + 'cS22!';
};

export enum CONTACT_ZOHO_FIELDS {
  ID = 'id',
  BIRTHDAY = 'Date_of_Birth',
  EMAIL = 'Email',
  SECOND_EMAIL = 'Secondary_Email',
  FIRST_NAME = 'First_Name',
  LAST_NAME = 'Last_Name',
  KATEGORIE = 'Kategorie',
  STATUS = 'Kontakt_Status',
  LEAD_SOURCE = 'Lead_Source',
  NEWSLETTER = 'Newsletter',
  SALUTATION = 'Salutation',
  TITEL = 'Titel',
  FAX = 'Fax',
  PHONE = 'Phone',
  MOBILE = 'Mobile',
  INSTAGRAM = 'Instagram',
  LINKEDIN = 'LinkedIn',
  WEBSITE = 'Website',
  TWITTER = 'Twitter',
  STREET = 'Street',
  ZIP_CODE = 'Zip_Code',
  CITY = 'City',
  STATE = 'State',
  COUNTRY = 'Country',
  WEBINARE = 'Webinare',
  AKADEMIE_ROLLE = 'Akademie_Rolle',
  SONSTIGE_AKADEMIE_ROLLE = 'Sonstige_Akademie_Rolle',
  AUSTRAGUNGSDATUM = 'Austragungsdatum',
  EINTRAGUNGSDATUM = 'Eintragungsdatum',
  BESTAETIGUNGSDATUM = 'Bestaetigungsdatum',
  INTERNE_BEREICHE = 'Interne_Bereiche',
  KOMMUNIKATIONS_KAMPAGNEN = 'Kommunikations_Kampagnen',
  EMAIL_STATUS = 'E_Mail_Status',
  KONTAKTFORMULAR = 'Kontaktformular',
  EMAIL_OPT_OUT = 'Email_Opt_Out',
  KLICKTIPP_ERSTEINTRAGUNG = 'KlickTipp_Ersteintragung',
  PROBONO = 'ProBono',
  HAS_ACADEMY_ACCOUNT = 'Akademie_Account',
  HB_EX = 'HB_ehemalig',
  KUNDENNUMMER = 'Kundennummer',
  RECORD_IMAGE = 'Record_Image',
}

export const CONTACT_BIMAP = new BiMap<string, string>([
  ['id', CONTACT_ZOHO_FIELDS.ID],
  ['birthday', CONTACT_ZOHO_FIELDS.BIRTHDAY],
  ['email', CONTACT_ZOHO_FIELDS.EMAIL],
  ['secondEmail', CONTACT_ZOHO_FIELDS.SECOND_EMAIL],
  ['firstName', CONTACT_ZOHO_FIELDS.FIRST_NAME],
  ['lastName', CONTACT_ZOHO_FIELDS.LAST_NAME],
  ['category', CONTACT_ZOHO_FIELDS.KATEGORIE],
  ['status', CONTACT_ZOHO_FIELDS.STATUS],
  ['leadSource', CONTACT_ZOHO_FIELDS.LEAD_SOURCE],
  ['isNewsletter', CONTACT_ZOHO_FIELDS.NEWSLETTER],
  ['isProBono', CONTACT_ZOHO_FIELDS.PROBONO],
  ['salutation', CONTACT_ZOHO_FIELDS.SALUTATION],
  ['title', CONTACT_ZOHO_FIELDS.TITEL],
  ['fax', CONTACT_ZOHO_FIELDS.FAX],
  ['phone', CONTACT_ZOHO_FIELDS.PHONE],
  ['mobile', CONTACT_ZOHO_FIELDS.MOBILE],
  ['instagram', CONTACT_ZOHO_FIELDS.INSTAGRAM],
  ['linkedIn', CONTACT_ZOHO_FIELDS.LINKEDIN],
  ['website', CONTACT_ZOHO_FIELDS.WEBSITE],
  ['twitter', CONTACT_ZOHO_FIELDS.TWITTER],
  ['street', CONTACT_ZOHO_FIELDS.STREET],
  ['zip', CONTACT_ZOHO_FIELDS.ZIP_CODE],
  ['city', CONTACT_ZOHO_FIELDS.CITY],
  ['state', CONTACT_ZOHO_FIELDS.STATE],
  ['country', CONTACT_ZOHO_FIELDS.COUNTRY],
  ['isWebinar', CONTACT_ZOHO_FIELDS.WEBINARE],
  ['academyRoles', CONTACT_ZOHO_FIELDS.AKADEMIE_ROLLE],
  ['otherAcademyRole', CONTACT_ZOHO_FIELDS.SONSTIGE_AKADEMIE_ROLLE],
  ['signOutDate', CONTACT_ZOHO_FIELDS.AUSTRAGUNGSDATUM],
  ['signInDate', CONTACT_ZOHO_FIELDS.EINTRAGUNGSDATUM],
  ['confirmDate', CONTACT_ZOHO_FIELDS.BESTAETIGUNGSDATUM],
  ['internalAreas', CONTACT_ZOHO_FIELDS.INTERNE_BEREICHE],
  ['communicationCampaigns', CONTACT_ZOHO_FIELDS.KOMMUNIKATIONS_KAMPAGNEN],
  ['emailStatus', CONTACT_ZOHO_FIELDS.EMAIL_STATUS],
  ['isContactform', CONTACT_ZOHO_FIELDS.KONTAKTFORMULAR],
  ['isEmailCancellation', CONTACT_ZOHO_FIELDS.EMAIL_OPT_OUT],
  ['klicktippSignIn', CONTACT_ZOHO_FIELDS.KLICKTIPP_ERSTEINTRAGUNG],
  ['hasAcademyAccount', CONTACT_ZOHO_FIELDS.HAS_ACADEMY_ACCOUNT],
  ['isHbEx', CONTACT_ZOHO_FIELDS.HB_EX],
  ['customerNumber', CONTACT_ZOHO_FIELDS.KUNDENNUMMER],
  // removed, cause it throws error on upsert
  // by remoing this field in bimap, it doesnt get serialized in zoho rirection
  // but we can still retrieve data from this field.
  //   ['profileImageID', CONTACT_ZOHO_FIELDS.RECORD_IMAGE],
]);

export declare type BHMaybe<T> = null | undefined | T | typeof emptyValue;

@Entity()
export class Contact extends ZohoEntity {
  static override _class = 'Contact';
  static override ZOHO_MODULE = ZohoModule.CONTACTS;
  static override ZOHO_FIELDS = Array.from(CONTACT_BIMAP.values).join(',');

  @Field(type => String) id?: string;
  @Field(type => String) title?: string;
  @Field(type => String) category: Maybe<ContactCategory>;
  @Field(type => Boolean) isProBono?: boolean;
  @Field(type => String) leadSource?: string;
  @Field(type => String) secondEmail?: string;
  @Field(type => Boolean) isNewsletter?: boolean;
  @Field(type => Boolean) isEmailCancellation?: boolean;
  @Field(type => Boolean) isHbEx?: boolean;
  @Field(type => Boolean) isContactform?: boolean;
  @Field(type => Boolean) isWebinar?: boolean;
  @Field(type => String) firstLeadSource?: string;
  @Field(type => String) customerNumber?: string;
  @Field(type => String) street?: string;
  @Field(type => String) zip?: string;
  @Field(type => String) city?: string;
  @Field(type => String) state: BHMaybe<string>;
  @Field(type => String) country?: string;
  @Field(type => Date) signOutDate?: Date;
  @Field(type => Date) signInDate?: Date;
  @Field(type => Date) confirmDate?: Date;
  @Field(type => Date) klicktippSignIn!: Date;
  @Field(type => [String]) internalAreas?: string[];
  @Field(type => [String]) communicationCampaigns?: string[];
  @Field(type => String) twitter?: string;
  @Field(type => String) instagram?: string;
  @Field(type => String) linkedIn?: string;
  @Field(type => String) skypeId?: string;
  @Field(type => String) website?: string;
  @Field(type => String) emailStatus?: string;
  @Field(type => String) description?: string;
  @Field(type => String) status?: string;
  @Field(type => [String]) academyRoles?: string[];
  @Field(type => String) otherAcademyRole?: string;
  @Field(type => String) phone?: string;
  @Field(type => String) mobile?: string;
  @Field(type => String) fax?: string;
  @Field(type => Date) birthday?: Date;
  @Field(type => Boolean) hasAcademyAccount?: boolean;
  @Field(type => String) profileImageID: Maybe<string>;

  @Field(type => String)
  salutation?: string;

  @validate(nonOptional())
  @Field(type => String)
  firstName?: string;

  @validate(nonOptional())
  @Field(type => String)
  lastName?: string;

  @validate(options => {
    if (!emailRegexCheck(options.value))
      throw new ValidationError('Bitte gib eine gültige E-Mail an.', {
        key: options.key,
        translationKey: 'validation_email',
      });
  })
  @Field(type => String)
  email!: string;

  getDebitorennummer() {
    const cn = (this.customerNumber ?? '').replace('KN-', '');
    if (cn.length !== 6) {
      // TODO: error handling? how to react in export?
      return 'NICHT_VORHANDEN';
    }
    return '1' + cn;
  }

  get toFilteredJSON() {
    const fields = {};
    const keys = Array.from(CONTACT_BIMAP.keys);
    keys.forEach(key => {
      if (key && this.toJSON()[key]) {
        fields[key] = this.toJSON()[key];
      }
    });
    return fields;
  }

  private get typeDefs() {
    return new Map<string, ZohoType>([
      [CONTACT_ZOHO_FIELDS.EINTRAGUNGSDATUM, ZohoType.DATE],
      [CONTACT_ZOHO_FIELDS.AUSTRAGUNGSDATUM, ZohoType.DATE],
      [CONTACT_ZOHO_FIELDS.BESTAETIGUNGSDATUM, ZohoType.DATE],
      [CONTACT_ZOHO_FIELDS.BIRTHDAY, ZohoType.DATE],
      [CONTACT_ZOHO_FIELDS.KLICKTIPP_ERSTEINTRAGUNG, ZohoType.DATE],
      [CONTACT_ZOHO_FIELDS.PHONE, ZohoType.PHONE],
      [CONTACT_ZOHO_FIELDS.MOBILE, ZohoType.PHONE],
    ]);
  }

  override serializeZoho = (isNew: boolean = false) => {
    const serialized = genericSerializeZoho({
      bimap: CONTACT_BIMAP,
      instance: this,
      typeDefs: this.typeDefs,
    });
    return serialized;
  };

  static override deserializeZoho = (rawData: any) => {
    const fields = {};
    const keys = Array.from(CONTACT_BIMAP.reverseKeys);
    keys.forEach(key => {
      const keyLAS = CONTACT_BIMAP.reverseGet(key);
      if (keyLAS) {
        fields[keyLAS] = rawData[key] ?? null;
      }
    });

    return new Contact({
      ...fields,
    });
  };
}
