import { Buchung } from '@adornis/accounting/api/buchung.js';
import { GeschaeftsvorfallBuchung } from '@adornis/accounting/api/geschaeftsvorfall-buchung.js';
import { Geschaeftsvorfall } from '@adornis/accounting/api/geschaeftsvorfall.js';
import { Zusammenhangskomponente } from '@adornis/accounting/api/zusammenhangskomponente.js';
import { A } from '@adornis/base/env-info.js';
import type { Maybe } from '@adornis/base/utilTypes.js';
import { Int } from '@adornis/baseql/baseqlTypes.js';
import { Arg, Entity, Field, Query, Subscription } from '@adornis/baseql/decorators.js';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity.js';
import { getRawCollection } from '@adornis/baseql/server/collections.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact.js';
import { preventExceedLimit } from '@adornis/digitale-helden-shared/server/zoho/api.js';
import { Payment } from '@adornis/payments/db/payments.js';
import { validate } from '@adornis/validation/decorators.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';
import { Company } from '@campus/db/Company';
import { Order } from '@campus/db/Order';
import { from } from 'rxjs';
import { getContactsByIDsCOQL } from '../../_api/contact/queries/getContactsByIDsCOQL.js';
import { AccountingListDataFilter } from './AccountingListDataFilter.js';
import { LASReceipt } from './LASReceipts.js';

@Entity()
export class LASAccountingListData extends AdornisEntity {
  static override _class = 'LASAccountingListData';

  static override resolveID(entity: LASAccountingListData): string {
    if (!entity._id) return (entity._id = A.getGloballyUniqueID());
    return entity._id;
  }

  @validate(nonOptional())
  @Field(type => String)
  _id!: string;

  @Field(type => [Buchung])
  buchungen!: Buchung[];

  @Field(type => [Zusammenhangskomponente])
  zusammenhaenge?: Maybe<Zusammenhangskomponente[]>;

  @Field(type => [Payment])
  payments?: Maybe<Payment[]>;

  @Field(type => Order)
  order?: Maybe<Order>;

  @Field(type => Company)
  company?: Maybe<Company>;

  @Field(type => Contact)
  contact?: Maybe<Contact>;

  @Subscription(type => [LASAccountingListData])
  static subscribeAccountingListData(
    @Arg('skip', type => Int) skip: number,
    @Arg('limit', type => Int) limit: number,
    @Arg('adornisFilter', type => AccountingListDataFilter) adornisFilter: AccountingListDataFilter,
  ) {
    return (gqlFields: BaseQLSelectionSet<LASAccountingListData>) => {
      return from(this.getAccountingListData(adornisFilter, skip, limit)(gqlFields));
    };
  }

  @Query(type => [LASAccountingListData])
  static getAccountingListData(
    @Arg('adornisFilter', type => AccountingListDataFilter) adornisFilter: AccountingListDataFilter,
    @Arg('skip', type => Int) skip?: number,
    @Arg('limit', type => Int) limit?: number,
    @Arg('geschaefsvorfallID', type => String) geschaefsvorfallID?: string,
  ) {
    return async (gqlFields: BaseQLSelectionSet<LASAccountingListData>) => {
      const geschaeftsvorfallCollection = await getRawCollection(Geschaeftsvorfall._collectionName);

      const result = (await geschaeftsvorfallCollection
        .aggregate([
          { $sort: { _id: -1 } },
          ...(geschaefsvorfallID
            ? [
                {
                  $match: {
                    _id: geschaefsvorfallID,
                  },
                },
              ]
            : []),
          {
            $set: {
              _class: LASAccountingListData._class,
              order: null,
              company: null,
              contact: null,
            },
          },
          {
            $project: {
              _id: 1,
              _class: 1,
              order: 1,
              company: 1,
              contact: 1,
            },
          },
          {
            $lookup: {
              from: GeschaeftsvorfallBuchung._collectionName,
              localField: '_id',
              foreignField: 'geschaeftsvorfallID',
              as: 'buchungen',
            },
          },
          {
            $lookup: {
              from: Zusammenhangskomponente._collectionName,
              localField: 'buchung._id',
              foreignField: 'buchungsIDs',
              as: 'zusammenhaenge',
            },
          },
          {
            $lookup: {
              from: Payment._collectionName,
              localField: 'buchungen.paymentID',
              foreignField: '_id',
              as: 'payments',
            },
          },
          {
            $lookup: {
              from: LASReceipt._collectionName,
              localField: 'buchungen.belegID',
              foreignField: '_id',
              as: 'belege',
            },
          },
          ...(adornisFilter.productID
            ? [
                {
                  $match: {
                    'payments.products.product.reference': adornisFilter.productID,
                  },
                },
              ]
            : []),
          ...(adornisFilter.status
            ? [
                {
                  $match: {
                    'payments.status': adornisFilter.status,
                  },
                },
              ]
            : []),
          ...(adornisFilter.stripeID
            ? [
                {
                  $match: {
                    'payments.processorInfo.stripePaymentIntent': adornisFilter.stripeID,
                  },
                },
              ]
            : []),
          ...(adornisFilter.invoiceNr
            ? [
                {
                  $match: {
                    'belege.belegNummer': adornisFilter.invoiceNr,
                  },
                },
              ]
            : []),
          {
            $match: {
              $and: [
                adornisFilter.from ? { 'buchungen.buchungsDatum': { $gte: adornisFilter.from } } : {},
                adornisFilter.till
                  ? {
                      'buchungen.buchungsDatum': { $lte: adornisFilter.till.set({ hour: 23, minute: 59, second: 59 }) },
                    }
                  : {},
              ],
            },
          },
          {
            $sort: {
              'buchungen.buchungsDatum': -1,
            },
          },
          ...(skip ? [{ $skip: skip }] : []),
          ...(limit ? [{ $limit: limit }] : []),
        ])
        .toArray()) as LASAccountingListData[];

      if (geschaefsvorfallID && result.length > 1) {
        return [];
      }

      const paymentIDs = result
        .map(r => r.payments ?? [])
        .reduce((res, payments) => (res = res.concat(payments)), [])
        .map(p => p._id);

      const orderGQLFields = (gqlFields.order ?? {}) as BaseQLSelectionSet<Order>;

      const orders = await preventExceedLimit({
        callback: async limitedIDs => {
          return await Order.getOrdersByPaymentIDsCOQL(limitedIDs)({
            ...orderGQLFields,
            id: 1,
            buyerContactId: 1,
            buyerCompanyId: 1,
            paymentID: 1,
          });
        },
        allIDs: paymentIDs,
      });

      const contactIDs = orders.map(order => order.buyerContactId).filter(e => !!e) as string[];
      const companyIDs = orders.map(order => order.buyerCompanyId).filter(e => !!e) as string[];

      const contactGQLFields = (gqlFields.contact ?? {}) as BaseQLSelectionSet<Contact>;

      const contacts = await preventExceedLimit({
        callback: limitedIDs => getContactsByIDsCOQL(limitedIDs)({ ...contactGQLFields, id: 1 }),
        allIDs: contactIDs,
      });

      const companyGQLFields = (gqlFields.company ?? {}) as BaseQLSelectionSet<Company>;

      const companies = await preventExceedLimit({
        callback: limitedIDs => Company.getCompaniesByIDs(limitedIDs)({ ...companyGQLFields, id: 1 }),
        allIDs: companyIDs,
      });

      for (const order of orders) {
        const contact = contacts?.find(contact => order.buyerContactId === contact.id);
        const company = companies?.find(company => order.buyerCompanyId === company.id);

        const data = result.find(d => d.payments?.some(p => p._id === order.paymentID));
        if (!data) continue;
        // ensure default values

        data.order = order;
        if (contact) data.contact = contact;
        if (company) data.company = company;
      }

      // console.log('result', result);

      return result;
    };
  }
}
