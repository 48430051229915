import { registerQuery } from '@adornis/baseql/metadata/register.js';
import { getLASUserByEmail } from './getUserByEmail.js';

export const isExistingUser = registerQuery({
  type: () => Boolean,
  operationName: 'isExistingUser',
  resolve: (email: string) => {
    return async () => {
      const user = await getLASUserByEmail(email)({ _id: 1 });
      return !!user;
    };
  },
  params: [{ name: 'email', type: () => String }],
});
