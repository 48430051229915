import type { Maybe } from '@adornis/base/utilTypes.js';
import '@adornis/buildify/client/components/x-buildify-border-radius-picker.js';
import type { BuildifyGlobalSettings } from '@adornis/buildify/db/GlobalSettings.js';
import { RXController } from '@adornis/chemistry/controllers/RXController.js';
import { css } from '@adornis/chemistry/directives/css.js';
import { html, nothing, type TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { DubniumDialog } from '../theme/d-dialog.js';
import '../theme/d-input.js';

export type TagMarkProperties = {
  ['background-color']?: string;
  border?: string;
  ['border-radius']?: string;
  icon?: string;
  padding?: string;
  ['font-size']?: string;
};

/**
 * @element tag-mark-edit-prompt
 */
@customElement('tag-mark-edit-prompt')
export class TagMarkEditPrompt extends DubniumDialog<Maybe<TagMarkProperties>> {
  static override get element_name(): string {
    return 'tag-mark-edit-prompt';
  }

  @property({ attribute: false }) tagMarkProperties = new RXController<Maybe<TagMarkProperties>>(this, {});
  @property({ attribute: false }) globalSettings: Maybe<BuildifyGlobalSettings>;

  override async cancel(returnVal?: any): Promise<void> {
    return this.close(undefined);
  }

  override content(): TemplateResult | typeof nothing {
    const properties = this.tagMarkProperties.value;
    if (!properties) return nothing;

    return html`
      <d-flex space="lg">
        <d-h3 bold ${css({ textAlign: 'center' })}> Tag bearbeiten </d-h3>

        <d-flex space="sm">
          <d-buildify-color-picker
            .globalSettingsClassName=${this.globalSettings?._class}
            clearable
            .value=${properties['background-color']}
            placeholder="Hintergrundfarbe"
            @value-picked=${e => (properties['background-color'] = e.detail.value)}
          ></d-buildify-color-picker>

          <d-buildify-border-radius-picker
            .globalSettingsClassName=${this.globalSettings?._class}
            select
            clearable
            placeholder="Border-Radius"
            .value=${properties['border-radius']}
            @value-picked=${e => (properties['border-radius'] = e.detail.value)}
          ></d-buildify-border-radius-picker>

          <d-buildify-border-picker
            .globalSettingsClassName=${this.globalSettings?._class}
            select
            clearable
            placeholder="Border"
            .value=${properties['border']}
            @value-picked=${e => (properties['border'] = e.detail.value)}
          ></d-buildify-border-picker>

          <d-input
            placeholder="Innenabstand"
            .value=${properties['padding']}
            @value-picked=${e => (properties['padding'] = e.detail.value)}
          ></d-input>

          <d-input
            placeholder="Schriftgröße"
            .value=${properties['font-size']}
            @value-picked=${e => (properties['font-size'] = e.detail.value)}
          ></d-input>

          <d-flex horizontal space="md" space-between>
            <d-button tone="neutral" mode="outline" @click=${() => this.close(undefined)}> Abbrechen </d-button>
            <d-button @click=${async () => this.close(this.tagMarkProperties.value)}> Speichern </d-button>
          </d-flex>
        </d-flex>
      </d-flex>
    `;
  }
}
