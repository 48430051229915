import { registerMutation } from '@adornis/baseql/metadata/register.js';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact.js';
import { RegistrationData } from '../../../db/RegistrationData.js';
import { upsertContactAndCheckDOI } from './upsertContactAndCheckDOI.js';

export const registerContact = registerMutation({
  type: () => String,
  operationName: 'registerContact',
  resolve: (instance: RegistrationData, redirectURL?: string) => {
    return async () => {
      const contact = new Contact({
        salutation: instance.salutation,
        firstName: instance.firstName,
        lastName: instance.lastName,
        email: instance.email,
        academyRoles: [instance.academyRole],
        otherAcademyRole: instance.otherAcademyRole,
        state: instance.state,
        country: instance.country,
      });
      await upsertContactAndCheckDOI(contact, true, undefined, redirectURL)({ id: 1 });
    };
  },
  params: [
    { name: 'instance', type: () => RegistrationData },
    { name: 'redirectURL', type: () => String },
  ],
});
