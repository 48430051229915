import type { Maybe } from '@adornis/base/utilTypes.js';
import { Entity, Field } from '@adornis/baseql/decorators.js';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity.js';
import { ContactAcademyRole } from '@adornis/digitale-helden-shared/db/enums.js';
import { emailRegexCheck } from '@adornis/users/db/validators.js';
import { validate } from '@adornis/validation/decorators.js';
import { ValidationError } from '@adornis/validation/errors/ValidationError.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';

export const emailValidationFunction = ({ key, value, target }) => {
  if (!emailRegexCheck(value))
    throw new ValidationError('Bitte gib eine gültige E-Mail an.', {
      key,
      target,
      translationKey: 'validation_email',
    });
};

export const emailRepeatValidationFunction = ({ key, value, target }) => {
  if (!value)
    throw new ValidationError('Dies ist ein Pflichtfeld.', {
      key: key,
      translationKey: 'validation_non_optional',
    });
  if (target.email !== value) {
    throw new ValidationError('Die E-Mail-Adressen müssen übereinstimmen.', {
      key: key,
      translationKey: 'email_repeat_error',
    });
  }
};

export const academyRoleValidationFunction = ({ key, value, target }) => {
  if (!target.isAcademyRoleRequired || value) return;
  throw new ValidationError('Dies ist ein Pflichtfeld', { key });
};

export const otherAcademyRoleValidationFunction = ({ key, value, target }) => {
  if (!target.isAcademyRoleRequired) return;
  if (target.academyRole !== ContactAcademyRole.SONSTIGES) return;
  if (value) return;
  throw new ValidationError('Geben Sie eine Akademie-Rolle an..', {
    key,
    translationKey: 'validation_non_optional',
  });
};

@Entity()
export class SignupContactData extends AdornisEntity {
  static override _class = 'SignupContactData';

  @validate(nonOptional())
  @Field(type => String)
  salutation!: string;

  @validate(nonOptional())
  @Field(type => String)
  firstName!: string;

  @validate(nonOptional())
  @Field(type => String)
  lastName!: string;

  @validate(emailValidationFunction)
  @Field(type => String)
  email!: string;

  @validate(emailRepeatValidationFunction)
  @Field(type => String)
  emailRepeat!: string;

  @validate(academyRoleValidationFunction)
  @Field(type => String)
  academyRole?: string;

  @validate(otherAcademyRoleValidationFunction)
  @Field(type => String)
  otherAcademyRole?: Maybe<string>;

  @Field(type => String)
  password?: Maybe<string>;

  // those fields check if the fields above are even needed
  // ------------------------------------------------------------------
  @validate(nonOptional({ allowFalsy: true }))
  @Field(type => Boolean, { default: v => v ?? false })
  isAcademyRoleRequired!: boolean;
}
