import type { Maybe } from '@adornis/base/utilTypes.js';
import type { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element.js';
import { ComponentSizeController } from '@adornis/chemistry/controllers/component-size-controller.js';
import { WindowSizeController } from '@adornis/chemistry/controllers/window-size-controller.js';
import { type Renderable } from '@adornis/chemistry/renderable.js';
import { getContactByEmailCOQL } from '@adornis/digitale-helden-shared/api/Contact/getContactByEmail.js';
import '@adornis/digitale-helden-shared/client/theme/d-button.js';
import '@adornis/digitale-helden-shared/client/theme/d-card.js';
import { html } from 'lit';
import { checkUserPassword } from '../../_api/user/queries/checkUserPassword.js';
import { getLASUserByEmail } from '../../_api/user/queries/getUserByEmail.js';
import { LASPromptCheckPassword } from '../../client/prompts/las-prompt-check-password.js';

export function _labelContent(content: Renderable, label: string) {
  return html`
    <d-flex space="xs">
      <d-text>${label}</d-text>
      ${content}
    </d-flex>
  `;
}

export function _contentBySize(options: { size: WindowSizeController; mobile: any; desktop: any }) {
  if (options.size.mobile && options.mobile !== undefined) {
    return options.mobile;
  }

  return options.desktop;
}

export function _defaultColumnsBySize(size: WindowSizeController) {
  return _contentBySize({ size, mobile: '1', desktop: '2' });
}

export function DefaultWindowSizeController(host: ChemistryLitElement) {
  return new WindowSizeController(host, { debounceTime: 250 });
}

export function DefaultComponentSizeController(host: ChemistryLitElement) {
  return new ComponentSizeController(host, { debounceTime: 250 });
}

export async function _hasPermissionForEmail(email: string, password: Maybe<string>) {
  return await new Promise<Maybe<string>>(async (resolve, reject) => {
    const existingContact = await getContactByEmailCOQL(email)({ id: 1 });
    if (!existingContact) return resolve(null);
    const existingUserForEmail = await getLASUserByEmail(email)({ _id: 1, password: 1, email: 1 });
    if (!existingUserForEmail) return resolve(null);

    if (password && (await checkUserPassword(existingUserForEmail._id, password)())) return resolve(password);
    password = await LASPromptCheckPassword.showPopup({
      props: { lasUser: existingUserForEmail },
      closeOnNavigate: false,
    });
    if (password) return resolve(password);
    reject(new Error('Falsches Passwort wurde eingegeben.'));
  });
}
