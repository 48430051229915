import { Entity, Field } from '@adornis/baseql/decorators.js';
import { BaseImageFlipcard } from '@adornis/buildify/db/extensions/BaseImageFlipcard.js';
import { validate } from '@adornis/validation/decorators.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';
import { DubniumBaseImage } from './DubniumBaseImage.js';

@Entity()
export class DubniumBaseFlipcard extends BaseImageFlipcard {
  static override _class = 'DubniumBaseFlipcard';

  @validate(nonOptional())
  @Field(type => DubniumBaseImage, { default: v => v ?? new DubniumBaseImage({}) })
  override file!: DubniumBaseImage;
}
