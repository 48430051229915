import { A } from '@adornis/base/env-info.js';
import { Entity, Field } from '@adornis/baseql/decorators.js';
import { AdornisEntity } from '@adornis/baseql/entities/adornisEntity.js';
import { BuildifyGlobalSettings } from '@adornis/buildify/db/GlobalSettings.js';
import { validate } from '@adornis/validation/decorators.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';
import { DubniumBaseTag } from './DubniumBaseTag.js';

@Entity()
export class DigitaleHeldenBuildifyGlobalSettings extends BuildifyGlobalSettings {
  static override _class = 'DigitaleHeldenBuildifyGlobalSettings';

  @Field(type => [BuildifyGlobalSettingTagsDefinition]) tags?: BuildifyGlobalSettingTagsDefinition[];
}

@Entity()
export class BuildifyGlobalSettingTagsDefinition extends AdornisEntity {
  static override _class = 'BuildifyGlobalSettingTagsDefinition';

  @validate(nonOptional())
  @Field(type => String, { default: v => v ?? A.getGloballyUniqueID() })
  _id!: string;

  @validate(nonOptional())
  @Field(type => DubniumBaseTag)
  tag!: DubniumBaseTag;

  @validate(nonOptional())
  @Field(type => String)
  name!: string;
}
