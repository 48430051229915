import { Size } from '@adornis/buildify/client/globals/enums.js';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types.js';
import { DubniumBaseGridContainerFour } from 'db/buildify/container/DubniumBaseGridContainerFour';
import { BuildifyCampusContainerGridExtension } from './BuildifyCampusContainerGridExtension.js';

export const BuildifyCampusContainerGridFourExtension: BuildifyExtension<DubniumBaseGridContainerFour> = {
  class: DubniumBaseGridContainerFour,
  toolbar: { icon: 'grid-4', text: { default: 'Grid 4' } },
  render(instance, { defaultValues, isSubelement, dropzoneBefore, dropzoneAfter }) {
    return BuildifyCampusContainerGridExtension.render(instance, {
      defaultValues: {
        ...(defaultValues ? defaultValues : {}),
        columns: { [Size.DESKTOP]: 4, [Size.TABLET]: 2, [Size.MOBILE]: 1 },
        rows: { [Size.DESKTOP]: 1, [Size.TABLET]: 2, [Size.MOBILE]: 4 },
      },
      isSubelement,
      dropzoneBefore,
      dropzoneAfter,
    });
  },
  editor: BuildifyCampusContainerGridExtension.editor,
  group: 'Layout',
};
