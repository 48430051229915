import { AccordeonExtension } from '@adornis/buildify/client/extensions/AccordeonExtension.js';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types.js';
import type { BaseAccordeon } from '@adornis/buildify/db/extensions/BaseAccordeon.js';
import '../../d-accordeon.js';

export const BuildifyCampusAccordeonExtension = (
  textExtension: BuildifyExtension,
): BuildifyExtension<BaseAccordeon> => ({
  ...AccordeonExtension(textExtension),
  toolbar: {
    icon: 'chevron-down',
    text: AccordeonExtension(textExtension).toolbar.text,
  },
});
