import { registerQuery } from '@adornis/baseql/metadata/register.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { CONTACT_BIMAP, CONTACT_ZOHO_FIELDS, Contact } from '@adornis/digitale-helden-shared/db/Contact.js';
import { makeZohoAPIRequest } from '@adornis/digitale-helden-shared/server/zoho/api.js';

export const getContactsByIDsCOQL = registerQuery({
  type: () => [Contact],
  operationName: 'getContactsByIDsCOQL',
  resolve: (contactIDs: string[]) => {
    return async (gqlFields: BaseQLSelectionSet<Contact>) => {
      contactIDs = contactIDs.filter(id => !!id);
      if (contactIDs.length === 0) return [];
      const endpoint = `coql`;

      const selectedFields = Contact.gqlFieldsZoho(gqlFields, CONTACT_BIMAP);

      const query = `SELECT ${selectedFields.join(',')} FROM ${Contact.ZOHO_MODULE} WHERE ${
        CONTACT_ZOHO_FIELDS.ID
      } in (${contactIDs.join(',')})`;

      const result = await makeZohoAPIRequest({
        method: 'post',
        endpoint,
        data: { select_query: query },
        zohoModule: Contact.ZOHO_MODULE,
        isRawRequest: true,
      });
      if (!result?.data) return null;

      const resultData = result.data;
      const deserialized = resultData.map(data => Contact.deserializeZoho(data)) as Contact[];
      return deserialized;
    };
  },
  params: [{ name: 'contactIDs', type: () => [String] }],
});
