import { emailTemplate } from '@adornis/digitale-helden-shared/mailing/e-mail-templates.js';
import { html } from 'lit';

export const inviteToGroupTemplate = () =>
  emailTemplate({
    title: html`
      <strong>Bestätigung</strong> · Sie wurden zum
      <strong><span style="color:#afca0b">Mentorenprogramm</span> hinzugefügt</strong>
    `,
    content: html`
      <p style="line-height: 18px; padding-top: 10px;">
        Herzlich willkommen im Campus der Digitalen Helden. Du wurdest im Rahmen des Mentorenprogramms einer Gruppe
        hinzugefügt. Melde dich bitte mit deinen Nutzer:innen-Daten in der Akademie an, um die Inhalte zu sehen.
      </p>
      <p style="line-height: 18px; padding-top: 10px;">
        Wir wünschen Ihnen <strong>viel Spaß</strong> mit der Digitale Helden-Akademie.
        <br />
        Herzliche Grüße<br />
        Dein Team Digitale Helden
      </p>
    `,
  });
