import type { ExtensionEditorFunctionType } from '@adornis/buildify/client/globals/types.js';
import 'client/formfields/d-link-editor';
import 'client/theme/d-checkbox';
import 'client/theme/d-input';
import { html } from 'lit';

export const customContainerEditor: ExtensionEditorFunctionType = ({ contentController, controllerBaseKeyPath }) => {
  return html`
    <d-flex space="sm">
      <d-checkbox
        ${contentController.field(...controllerBaseKeyPath, 'hasLineLeft')}
        .label=${'Hat Linie links'}
      ></d-checkbox>
      <d-buildify-spacing-picker
        ${contentController.field(...controllerBaseKeyPath, 'lineLeftWidth')}
        placeholder="Linie links Breite"
      ></d-buildify-spacing-picker>
      <d-buildify-color-picker
        ${contentController.field(...controllerBaseKeyPath, 'lineLeftColor')}
        placeholder="Linie links Farbe"
      ></d-buildify-color-picker>
      <d-link-editor ${contentController.field(...controllerBaseKeyPath, 'link')}></d-link-editor>
    </d-flex>
  `;
};
