import type { Maybe } from '@adornis/base/utilTypes.js';
import { Entity, Field } from '@adornis/baseql/decorators.js';
import { BaseContainerGrid } from '@adornis/buildify/db/extensions/BaseContainerGrid.js';
import { validate } from '@adornis/validation/decorators.js';
import { ValidationError } from '@adornis/validation/errors/ValidationError.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';
import { Link } from '../Link.js';

@Entity()
export class DubniumBaseContainerGrid extends BaseContainerGrid {
  static override _class = 'DubniumBaseContainerGrid';

  @Field(type => Boolean)
  hasLineLeft: Maybe<boolean>;

  @validate(options => {
    if (!options.target.hasLineLeft) return;
    if (!options.value) throw new ValidationError('validate', { key: options.key });
  })
  @Field(type => String)
  lineLeftWidth: Maybe<string>;

  @validate(options => {
    if (!options.target.hasLineLeft) return;
    if (!options.value) throw new ValidationError('validate', { key: options.key });
  })
  @Field(type => String)
  lineLeftColor: Maybe<string>;

  @validate(nonOptional())
  @Field(type => Link, { default: v => v ?? new Link({}) })
  link!: Link;
}
