import type { ValueEvent } from '@adornis/base/utilTypes.js';
import { ChemistryLitElement } from '@adornis/chemistry/chemistry-lit-element.js';
import { RXController } from '@adornis/chemistry/controllers/RXController.js';
import { ComponentSizeController } from '@adornis/chemistry/controllers/component-size-controller.js';
import { css } from '@adornis/chemistry/directives/css.js';
import { XSnackbar } from '@adornis/chemistry/elements/components/x-snackbar.js';
import { ContactAcademyRole, Salutation, emptyValue, mobile } from '@adornis/digitale-helden-shared/db/enums.js';
import { TranslationRootItem } from '@adornis/digitale-helden-shared/translation/enums.js';
import { FormController } from '@adornis/forms/x-form-controller.js';
import { TranslationController } from '@adornis/translation-core/client/translation-controller.js';
import { currentUser } from '@adornis/users/client/currentUser.js';
import { html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { combineLatest, of, switchMap } from 'rxjs';
import { getContactByIDCOQL } from '../../../api/Contact/getContactByIDCOQL.js';
import { upsertLeadOrContactAndCheckDOI } from '../../../api/Lead/upsertLeadOrContactAndCheckDOI.js';
import { DubniumDialog } from '../../../client/theme/d-dialog.js';
import { Contact } from '../../../db/Contact.js';
import { Lead } from '../../../db/Lead.js';
import { UpsertContactOptions } from '../../../db/UpsertContactOptions.js';
import type { LASUser } from '../../../db/las-user.js';
import { FormularLeadSignup } from '../db/FormularLeadSignup.js';

@customElement('las-form-lead-signup')
export class LASFormLeadSignup extends ChemistryLitElement {
  @property({ attribute: 'campaigns', type: String }) campaigns: string = '';
  @property({ attribute: 'webinar', type: Boolean }) isWebinar: boolean = false;
  @property({ attribute: 'newsletter', type: Boolean }) isNewsletter: boolean = false;
  @state() private readonly _currentUser = new RXController(this, currentUser<LASUser>());
  @state() private readonly _contact = new RXController(
    this,
    this._currentUser.observable.pipe(
      switchMap(user => {
        if (user) return getContactByIDCOQL(user.zohoID)(Contact.allFields);
        return of(null);
      }),
    ),
  );
  @state() private readonly _translation = new TranslationController<any>(this);
  @state() private readonly _formController = new FormController<FormularLeadSignup>(this, undefined, {
    success: async data => {
      const lead = new Lead({
        salutation: data.salutation,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        academyRoles: [data.academyRole],
        otherAcademyRole: data.otherAcademyRole,
        communicationCampaigns: this.campaigns.split(','),
        ...(this.isWebinar ? { isWebinar: true } : {}),
        ...(this.isNewsletter ? { isNewsletter: true } : {}),
      });
      await DubniumDialog.waitFor(
        upsertLeadOrContactAndCheckDOI(
          lead,
          false,
          new UpsertContactOptions({
            checkCommunicationCampaigns: true,
            checkAcademyRole: true,
            checkOtherAcademyRole: true,
          }),
        )(),
        'Wird abonniert...',
      ).then(res => {
        XSnackbar.show('Registrierung war erfolgreich!');
        this._setNewFormularInstance();
        this._statusMessage = {
          message: `Erfolgreich registriert.`,
          isError: false,
        };
      });
    },
    error: err => {
      const errorJSON = err.toJSON();
      this._statusMessage = {
        message: `Fülle alle Pflichtfelder aus.`,
        hoverInformations:
          errorJSON._class === 'MultipleValidationError'
            ? errorJSON.extensions.validationErrors?.map(error =>
                this._translation.translate(
                  TranslationRootItem.LEAD_SIGNUP_FORMULAR,
                  'fieldValidationMessage',
                  error.extensions.key,
                ),
              )
            : [
                this._translation.translate(
                  TranslationRootItem.LEAD_SIGNUP_FORMULAR,
                  'fieldValidationMessage',
                  errorJSON.extensions.key,
                ),
              ],
        isError: true,
      };
    },
  });
  @state() private readonly _size = new ComponentSizeController(this);
  @state() private _statusMessage: { message?: string; hoverInformations?: string[]; isError?: boolean } = {};
  override connectedCallback(): void {
    super.connectedCallback();
    combineLatest([this._currentUser.observable, this._contact.observable]).subscribe(user =>
      this._setNewFormularInstance(),
    );
  }
  override render() {
    if (this._currentUser.isLoading || !this._formController.document) {
      return html` <las-form-support-loading></las-form-support-loading> `;
    }
    const isMobile = (this._size.width ?? 0) <= mobile;
    return html`
      <d-flex space="md" ${css({ position: 'relative' })}>
        ${this.__contactInformation(isMobile)}
        <!-- privacy -->
        ${this.__privacy()}
        <d-flex horizontal crossaxis-end space-between>
          <d-text
            bold
            ${css({
              color: this._statusMessage.isError ? this.colors.tone.darkGreen : this.colors.accent,
              userSelect: 'none',
            })}
          >
            ${this._statusMessage.message}
            ${(this._statusMessage.hoverInformations ?? []).length > 0
              ? html`
                  <d-icon secondary pointer>
                    circle-info
                    <d-tooltip>
                      <d-flex space="xs" padding="xs">
                        ${this._statusMessage.hoverInformations!.map(info => html` <d-text> ${info} </d-text> `)}
                      </d-flex>
                    </d-tooltip>
                  </d-icon>
                `
              : nothing}
          </d-text>
          <d-button @click=${() => this._formController.validate()}> ${this._translation.translate('send')} </d-button>
        </d-flex>
      </d-flex>
    `;
  }
  private __privacy() {
    if (this._currentUser.value) return nothing;
    return html`
      <!-- privacy -->
      <d-flex horizontal crossaxis-center space="xs">
        <d-checkbox ${this._formController.field('hasAcceptedPrivacy')}></d-checkbox>
        <d-text> ${this._translation.translate('accepts_data_privacy')} </d-text>
      </d-flex>
    `;
  }
  private __contactInformation(isMobile: boolean) {
    if (this._currentUser.value) return nothing;
    return html`
      <d-grid space="md" columns=${isMobile ? '1' : '2'}>
        <!-- Anrede -->
        <d-dropdown-selection
          placeholder="Anrede"
          clearable
          .selectables=${Object.values(Salutation)}
          .renderString=${val => this._translation.translate(val)}
          ${this._formController.field('salutation')}
        >
        </d-dropdown-selection>
        ${isMobile ? nothing : html` <div></div> `}
        <!-- Vorname -->
        <d-input
          placeholder="Vorname"
          placeholder-mode="static-floating"
          ${this._formController.field('firstName')}
        ></d-input>
        <!-- Nachname -->
        <d-input placeholder="Nachname" ${this._formController.field('lastName')}></d-input>
        <!-- Email -->
        <d-input
          type="email"
          placeholder="E-Mail"
          ${this._formController.field('email')}
          @value-picked=${() => this.requestUpdate()}
        ></d-input>
        <!-- Email Wiederholung -->
        <d-input
          placeholder=${this._translation.translate('e_mail_repeat')}
          type="email"
          onpaste="return false"
          ondrop="return false"
          ${this._formController.field('emailRepeat')}
          @value-picked=${() => this.requestUpdate()}
        ></d-input>
        <!-- Akademie-Rolle -->
        <d-dropdown-selection
          .selectables=${Object.values(ContactAcademyRole)}
          ${this._formController.field('academyRole')}
          @value-picked=${(e: ValueEvent<string[]>) => {
            if (!this._formController.document) return;
            if (!e.detail.value.includes(ContactAcademyRole.SONSTIGES)) {
              this._formController.document.otherAcademyRole = null;
            }
            this.requestUpdate();
          }}
          placeholder=${`${this._translation.translate('academy_role')}*`}
        ></d-dropdown-selection>
        ${this._formController.document?.academyRole?.includes(ContactAcademyRole.SONSTIGES)
          ? html`
              <d-input
                ${this._formController.field('otherAcademyRole')}
                placeholder=${`${this._translation.translate('academy_role_other')}*`}
              ></d-input>
            `
          : nothing}
      </d-grid>
    `;
  }
  private _setNewFormularInstance() {
    const contact = this._contact.value;
    if (this._currentUser.isLoading || this._contact.isLoading) return;
    let instance = new FormularLeadSignup({});
    if (contact) {
      instance = new FormularLeadSignup({
        salutation: contact.salutation,
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        emailRepeat: contact.email,
        hasAcceptedPrivacy: true,
        academyRole: emptyValue,
      });
    }
    this._formController.document = instance;
  }
  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          display: 'block',
        },
      },
    ];
  }
}
