import { Arg, Entity, Field, Mutation, Query } from '@adornis/baseql/decorators.js';
import { MongoEntity } from '@adornis/baseql/entities/mongoEntity.js';
import { getCollection } from '@adornis/baseql/server/collections.js';
import { context } from '@adornis/baseql/server/context.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { validate } from '@adornis/validation/decorators.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';
import { DateTime } from 'luxon';
import { checkPermission } from '../../db/helpers.js';
import { CampusPage } from './CampusPage.js';

@Entity()
export class DubniumPagePublished extends MongoEntity {
  static override _class = 'DubniumPagePublished';
  static override _collectionName = 'page-publishments';

  @validate(nonOptional())
  @Field(type => String)
  pageID!: string;

  @validate(nonOptional())
  @Field(type => DateTime, { default: v => v ?? DateTime.now() })
  publishedAt!: DateTime;

  @validate(nonOptional())
  @Field(type => String)
  publishedBy!: string;

  @Mutation(type => String)
  static publishPage(
    @Arg('pageID', type => String) pageID: string,
    @Arg('appleOnChildren', type => Boolean) applyOnChildren: boolean = false,
    @Arg('isChild', type => Boolean) isChild: boolean = false,
  ) {
    return async () => {
      await checkPermission({ context, permission: 'Designer.Edit' });
      if (context.serverContext) return;
      const page = await CampusPage.getByID<CampusPage>(pageID)({ _id: 1, parentPageID: 1 });
      if (!page) throw new Error('404: page not found');

      // rekursiv im Baum nach oben veröffentlichen
      if (page.parentPageID && !isChild) {
        const parentPublishment = await this.getPublishmentByPage(page.parentPageID)({ _id: 1 });
        if (!parentPublishment) await this.publishPage(page.parentPageID, applyOnChildren)();
      }

      // rekursiv im Baum nach unten veröffentlichen wenn gefordert
      if (applyOnChildren) {
        const children = await CampusPage.getDirectChildrenOfPage(pageID)({ _id: 1 });
        const publishments = await this.getPublishmentsByPages(children.map(child => child._id))({ _id: 1, pageID: 1 });

        for (const child of children) {
          const publishment = publishments.find(p => p.pageID === child._id);
          if (publishment) continue;
          else await this.publishPage(child._id, applyOnChildren, true)();
        }
      }

      const existingPublishment = await this.getPublishmentByPage(pageID)({ _id: 1 });
      if (existingPublishment) throw new Error('es existiert bereits eine Veröffentlichung für diese Seite!');

      const publishment = new DubniumPagePublished({ pageID, publishedBy: context.userID });
      const createdID = await publishment.create();
      return createdID;
    };
  }

  @Query(type => String)
  static removePublishmentForPage(@Arg('pageID', type => String) pageID: string) {
    return async () => {
      await checkPermission({ context, permission: 'Designer.Edit' });
      if (context.serverContext) return;
      // rekursiv im Baum nach unten veröffentlichen
      const children = await CampusPage.getDirectChildrenOfPage(pageID)({ _id: 1 });
      const publishments = await this.getPublishmentsByPages(children.map(child => child._id))({ _id: 1, pageID: 1 });

      for (const child of children) {
        const publishment = publishments.find(p => p.pageID === child._id);
        if (!publishment) continue;
        else await this.removePublishmentForPage(child._id)();
      }

      const collection = await getCollection<DubniumPagePublished>(DubniumPagePublished._class);
      await collection.deleteOne({ pageID });
    };
  }

  @Query(type => [DubniumPagePublished])
  static getPublishmentsByPages(@Arg('pageIDs', type => [String]) pageIDs: string[]) {
    return async (gqlFields: BaseQLSelectionSet<DubniumPagePublished>) => {
      await checkPermission({ context, permission: 'Designer.View' });
      const collection = await getCollection<DubniumPagePublished>(DubniumPagePublished._class);
      const result = await collection.find<DubniumPagePublished>({ pageID: { $in: pageIDs } }).toArray();
      return result;
    };
  }

  @Query(type => DubniumPagePublished)
  static getPublishmentByPage(@Arg('pageID', type => String) pageID: string) {
    return async (gqlFields: BaseQLSelectionSet<DubniumPagePublished>) => {
      const collection = await getCollection<DubniumPagePublished>(DubniumPagePublished._class);
      const result = await collection.findOne<DubniumPagePublished>({ pageID });
      return result;
    };
  }
}
