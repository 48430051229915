import type { Styles } from '@adornis/ass/style.js';
import { XHorizontalRule } from '@adornis/chemistry/elements/components/x-hr.js';
import '@adornis/chemistry/elements/components/x-icon.js';
import { customElement } from 'lit/decorators.js';

/**
 * @element d-hr
 */
@customElement('d-hr')
export class DubniumHorizontalRule extends XHorizontalRule {
  override styles() {
    return [
      ...super.styles(),
      {
        background: this.colors.tone.neutralAlabster,
        height: '2px !important',
      },
    ] as Styles[];
  }
}
