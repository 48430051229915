import type { Styles } from '@adornis/ass/style.js';
import { css } from '@adornis/chemistry/directives/css.js';
import { XButton } from '@adornis/chemistry/elements/components/x-button.js';
import { lighten } from '@adornis/chemistry/elements/theming/color-shades.js';
import { inputToken } from '@adornis/chemistry/elements/theming/input-token.js';
import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './d-icon.js';

/**
 * @element d-button
 */
@customElement('d-button')
export class DButton extends XButton {
  @property({ attribute: 'small-padding', type: Boolean }) isSmallPadding = false;

  private get backgroundColor() {
    if (this.mode === 'outline') return '#fff';

    const tone = this.getAttribute('tone');
    if (tone) {
      return this.colors.tone[this.getAttribute('tone') ?? ''];
    }

    return this.colors.accent;
  }

  private get backgroundColorHover() {
    if (this.mode === 'outline') return '#fff';

    const tone = this.getAttribute('tone');
    if (tone) {
      if (tone === 'emergency') return '#9E1525';
      return this.colors.tone[this.getAttribute('tone') ?? '']?.shade(200);
    }

    return '#95AC0C';
  }

  private get borderColor() {
    if (this.mode !== 'outline') return this.backgroundColor;

    const tone = this.getAttribute('tone');
    if (tone) {
      return this.colors.tone[this.getAttribute('tone') ?? ''];
    }

    return this.colors.accent;
  }

  private get borderColorHover() {
    if (this.mode !== 'outline') return this.backgroundColorHover;

    const tone = this.getAttribute('tone');
    if (tone) {
      if (tone === 'emergency') return '#9E1525';
      return this.colors.tone[this.getAttribute('tone') ?? '']?.shade(200);
    }

    return '#95AC0C';
  }

  protected override visualStyles = () => {
    return {
      ':host': {
        borderRadius: this.sizes.borderRadiusSecondary,
        '&[disabled]': { opacity: '.5', pointerEvents: 'none' },
        '&[loading]': { pointerEvents: 'none', filter: 'grayscale(.5)', opacity: 0.5 },
      },
    };
  };

  protected override stylesModeActionAndOutline = () => ({
    borderWidth: '2px',
    borderStyle: 'solid',
    '#innerwrapper': {
      height: `calc(${this.baseFontSize}px + ${inputToken(this).paddingTop} + ${inputToken(this).paddingBottom})`,
    },
  });

  protected override stylesModeText = () => ({
    ':host': {
      background: 'transparent',
      transition: 'background-color 250ms',
      '&:hover': {
        backgroundColor: this.tone
          ? lighten(this.colors.tone[this.tone].string, 9)
          : lighten(this.colors.accent.string, 9),
      },
    },
  });

  protected override get ctaColor() {
    return this.colors.accent;
  }

  protected override loadingContent() {
    return html` <d-icon ${css({ animation: '2s rotate linear infinite' })}> rotate </d-icon> `;
  }

  override iconDisplay() {
    if (!this.icon) return nothing;
    if (this._isIconLink) {
      return html`
        <d-icon
          id="icon"
          ${css({
            color:
              // @ts-expect-error getTextTone is private
              this.colors.tone[this.getTextTone()],
          })}
          type="img"
        >
          ${this.icon}
        </d-icon>
      `;
    }
    return html`
      <d-icon
        id="icon"
        ${css({
          color:
            // @ts-expect-error getTextTone is private
            this.colors.tone[this.getTextTone()],
        })}
      >
        ${this.icon}
      </d-icon>
    `;
  }

  override styles() {
    return [
      ...super.styles(),
      {
        ':host': {
          width: 'min(max-content, 100%)',
          background: this.backgroundColor,
          borderColor: this.borderColor,
          '#innerwrapper': {
            padding: this.isSmallPadding ? `12px 20px` : `12px 30px`,
            overflow: 'hidden',
            width: '100%',
          },
          '&:hover': {
            background: this.backgroundColorHover,
            borderColor: this.borderColorHover,
          },
        },
        ':host:active, :host:focus': {
          border: 'none',
        },
      },
      {
        ':host d-link': {
          textDecoration: 'none',
          borderWidth: '2px',
        },
        ':host d-text': {
          fontSize: '1rem',
          color: this.mode === 'outline' ? this.borderColor : '#fff',
          fontFamily: 'NettoOT-Black',
          transition: 'color 250ms',
        },
      },
    ] as Styles[];
  }
}
