import { registerQuery } from '@adornis/baseql/metadata/register.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { PRODUCT_BIMAP, Product } from '@adornis/digitale-helden-shared/db/product/Product.js';
import { getAllZohoRecords } from '@adornis/digitale-helden-shared/server/zoho/api.js';

//* Resolver
const getAllProductsResolver = () => {
  return async (gqlFields: BaseQLSelectionSet<Product>) => {
    const zohoSelectionSet = Product.gqlFieldsZoho(gqlFields, PRODUCT_BIMAP).join(',');
    const rawProducts = await getAllZohoRecords(Product.ZOHO_MODULE, { fields: zohoSelectionSet });
    const products = rawProducts.data.map(product => Product.deserializeZoho(product));
    return products;
  };
};

//* Query
export const getAllProducts = registerQuery({
  type: () => [Product],
  operationName: 'getAllProducts',
  resolve: getAllProductsResolver,
  params: [],
});
